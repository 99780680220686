/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Вернуться",
      "Доступно в App Store",
      "Доступно в Google Play",
      "Карусель",
      "Слайд {{number}}",
      "Следующий",
      "Предыдущий",
      "Слайд",
      "Слайд {{number}} из {{total}}",
      "Не продавать и не передавать мою личную информацию",
      "Не продавать и не передавать мою личную информацию",
      "Отправляйте комплименты на Bumble, чтобы отметить понравившуюся часть профиля и привлечь внимание еще до взаимной симпатии. Попробуйте прямо сейчас.",
      "Compliments | Как отправить комплимент | Bumble",
      "Личная подборка совместимых профилей для вас, обновляемая ежедневно.",
      "Знакомьтесь | Найти совместимые профили | Bumble",
      "Знакомьтесь и общайтесь в своем темпе. В режиме Инкогнито вы сами решаете, кто может видеть ваш профиль на Bumble. Подробнее о том, как использовать режим Инкогнито на Bumble, можно прочитать в нашем руководстве.",
      "Режим Инкогнито | Скрыть свой профиль | Bumble",
      "Познакомились на мероприятии Bumble? Продолжите общаться в приложении и узнайте, к чему это может привести.",
      "Мгновенная симпатия | Продолжайте общаться в приложении | Bumble",
      "Начните беседу по-новому с помощью функции Opening Moves от Bumble. Привлеките внимание, задайте тон и быстрее переходите к интересному общению.",
      "Opening Moves на Bumble | Bumble",
      "Нужна передышка или время наедине с собой? Узнайте, как приостановить свой аккаунт на Bumble с помощью режима невидимки Bumble. Знакомьтесь тогда, когда вам это удобно.",
      "Режим невидимки | Приостановите свой аккаунт | Bumble",
      "Чей-то профиль на Bumble заставил ваше сердце биться чаще? SuperSwipe – отличный способ показать серьезность ваших намерений. Нажмите звёздочку в профиле симпатии или узнайте больше здесь.",
      "Bumble SuperSwipe | Что такое SuperSwipe? | Bumble",
      "Планируете командировку или отпуск? Режим Путешествий на Bumble поможет вам завести интересные знакомства на новом месте и не остаться без свиданий, куда бы вы ни отправились. Попробуйте сами!",
      "Режим Путешествий на Bumble | Свидания в любой поездке | Bumble",
      "Хотите узнать, сохранится ли «химия» по переписке при реальной встрече? Попробуйте видеочат или голосовой вызов на Bumble и развейте все сомнения.",
      "Видеочат | Голосовой вызов | Bumble",
      "Веб-версия Bumble – ваш пропуск в мир лучшего дейтинга!",
      "Bumble Web | Bumble",
      "Расторгнуть договор",
      "Домашняя страница Bumble",
      "Закрыть меню",
      "Открыть меню",
      "Выбор языка",
      "Сноска",
      "Специальные возможности на Bumble",
      "Apple и Apple logo – товарные знаки компании Apple Inc.",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Карьера",
      "События",
      "Google Play является товарным знаком компании Google LLC.",
      "Правила",
      "Инвесторы",
      "Настройки cookie",
      "Настройки cookie",
      "Закон о современном рабстве",
      "Положение о сборе персональных данных",
      "Конфиденциальность",
      "Сезонные дейтинг-советы",
      "Условия обслуживания",
      "Приложение",
      "Политика конфиденциальности Вашингтона в отношении клиентских данных об исправности систем",
      "О нас",
      "Амбассадоры",
      "Блог",
      "Общайтесь с теми, кто разделяет ваши интересы.",
      "Заводите профессиональные связи и стройте карьеру",
      "Для поиска отношений любого уровня серьезности",
      "Узнать больше",
      "Знакомства без границ в режиме Путешествий",
      "Функции",
      "Compliments",
      "Используйте Compliments, чтобы привлечь внимание вашей симпатии.",
      "Рядом",
      "Личная подборка совместимых профилей для вас, обновляемая ежедневно",
      "Режим Инкогнито",
      "Используйте режим Инкогнито, чтобы скрыть свой профиль от начальника, родственников или бывших.",
      "Мгновенная симпатия",
      "Начали общаться на мероприятии Bumble и не хотите останавливаться? Переходите в приложение и не сдерживайте себя!",
      "Opening Moves",
      "Выберите Opening Moves и ждите, пока ваша симпатия сама инициирует общение.",
      "Режим невидимки",
      "Хотите немного отдохнуть от дейтинга? Включите режим неведимки. Обещаем, мы вас дождёмся!",
      "SuperSwipe",
      "Понравился кто-то? Сообщите о своих чувствах с помощью SuperSwipe.",
      "Режим Путешествий",
      "Захватите что-нибудь вкусненькое в дьюти-фри, включите режим Путешествий и знакомьтесь в любой точке мира.",
      "Видеочат",
      "Созвонитесь с вашей симпатией по видео- или аудиосвязи, чтобы понять, готовы ли вы к реальной встрече.",
      "Веб-приложение",
      "Наслаждайтесь Bumble прямо из вашего браузера.",
      "Магазин",
      "Основное",
      "Вернуться к основному контенту",
      "Логотип Bumble",
      "Хотите познакомиться поближе? Используйте видеочат на Bumble",
      "Просто нажмите значок видео в вашем чате, чтобы начать видеозвонок. Разглашать персональные контактные данные при этом не требуется.",
      "Как работает видеочат на Bumble?",
      "Нет. Все звонки и чаты на Bumble сохраняют вашу персональную информацию в полной безопасности. Ваши симпатии не увидят ваш номер телефона или какие-либо другие контактные данные, пока вы им сами их не передадите.",
      "Увидит ли моя симпатия мой номер телефона?",
      "Конечно! Если вы пока не готовы пообщаться лицом к лицу, можете использовать аудиосвязь. Просто выберите значок звонка вместо значка видео.",
      "Можно ли созвониться по аудиосвязи вместо видеочата?",
      "Интересный собеседник по переписке – уже находка. Но если вы хотите узнать, будет ли беседа такой же непринужденной вживую, используйте видеочат или аудиозвонок.",
      "Видеочат на Bumble",
      "<a href={{video_chat_url}}>Видеочат и аудиозвонки</a> помогут вам лучше узнать друг друга и решить, хотите ли вы перенести ваши отношения за пределы приложения. А знаете, что самое приятное? Когда вы совершаете звонок через Bumble, вам не нужно обмениваться номерами телефонов – ваша личная информация останется скрытой до тех пор, пока вы сами не решите ею поделиться.",
      "Используйте видеочат, чтобы оценить уровень притяжения",
      "Проложите мостик между интернетом и реальной жизнью и решите для себя, не пора ли вам встретиться.",
      "Узнайте друг друга ближе (и быстрее). Видеочат или разговор по телефону могут рассказать о вашей симпатии много нового.",
      "Мы сохраним ваши контактные данные втайне, а когда ими делиться – решать только вам.",
      "Проверьте, готовы ли вы к реальной встрече",
      "Экспресс-проверка на совместимость",
      "Общайтесь, не раскрывая личные данные",
      "Для чего нужны видеочаты и звонки",
      "Готовы к новому этапу? Созвонитесь по видео- или аудиосвязи прямо из вашего чата в приложении Bumble. Чтобы начать видеозвонок, нажмите серый значок видеокамеры в правом верхнем углу. Если вас больше интересует аудиозвонок, нажмите три точки рядом со значком камеры.",
      "Как применять видеочат",
      "Готовы попробовать Compliments?",
      "Получатель вашего комплимента увидит его, когда вы появитесь среди профилей.",
      "Что происходит после отправки комплимента на Bumble?",
      "Если вам понравился чей-то комплимент, можете поставить профилю отправителя лайк. Комплимент появится в вашем чате, а дальше действуют правила <a href={{make_the_first_move_url}}>первого шага</a>.",
      "Как ответить на комплимент?",
      "Вы можете отправить {{num}} [[Compliments:num@3]] бесплатно. Когда они закончатся, можно докупить комплименты в приложении Bumble партиями до 30 штук. Один из них точно станет началом чего-то большего (а остальные можно приберечь для будущих симпатий). Не волнуйтесь, купленные комплименты не сгорят!",
      "Сколько Compliments я могу отправить?",
      "Доброе слово (или несколько) способно на многое. Если вы хотите написать что-то приятное, но ваши пальцы в нерешительности застыли над клавиатурой, посмотрите эти примеры для вдохновения:",
      "Больше примеров",
      "«Люди, которым нравится [имя исполнителя], обладают отменным вкусом.»",
      "«У тебя такая заразительная улыбка!»",
      "«Ты так смешно рассказываешь о себе. Отличное чувство юмора!»",
      "Как стать мастером комплимент​ов на Bumble",
      "Хотите порадовать свою симпатию? Используйте Compliments и дарите улыбки!",
      "Compliments на Bumble",
      "Используйте Compliments на Bumble, чтобы рассказать потенциальной симпатии, чем вас привлек их профиль (если результатом станет их смущенный румянец – тем лучше!). Compliments включены в подписку {{service_level_lexeme_name}}. Кроме того, они позволяют отправлять и получать сообщения еще до возникновения взаимной симпатии.",
      "Как работает функция Compliments",
      "Полученный вами комплимент появится в профиле отправителя. Вам также придет уведомление о том, что кого-то зацепил ваш профиль.",
      "Где искать ваши комплимент​ы",
      "Чтобы отправить комплимент, нажмите значок желтого сердца в нижней части понравившегося профиля. Место нажатия имеет значение – именно эта фотография или часть профиля получат ваш комплимент! При нажатии откроется текстовое поле для вашего сообщения (не больше 150 символов).",
      "Отправьте комплимент",
      "СКАЧАЙТЕ Bumble И ПОПРОБУЙТЕ «ЗНАКОМЬТЕСЬ»",
      "Каждый день в 9 утра в разделе «Знакомьтесь» появляются новые профили, с которыми у вас наверняка найдется что-то общее.",
      "Когда обновляются профили в «Знакомьтесь»?",
      "На странице «Знакомьтесь» потенциальные симпатии делятся на 3 основные категории: по общим интересам, по одинаковым целям знакомства и по совпадающим сообществам. Рекомендации профилей также отображаются на основе предыдущих симпатий.",
      "Как формируется подборка «Знакомьтесь» для меня?",
      "Личная подборка совместимых профилей для вас, обновляемая ежедневно",
      "{{qr_code}} «ЗНАКОМЬТЕСЬ» – ЭТО ОТЛИЧНЫЙ СПОСОБ НАЙТИ ТЕХ, КТО С ВАМИ НА ОДНОЙ ВОЛНЕ",
      "«Знакомьтесь» — это подборка профилей, которые, на наш взгляд, могут вам понравиться, составленная на основе схожих интересов, целей знакомства и сообществ.",
      "Попробовать",
      "КАК НАСЧЕТ ЗНАКОМСТВ НА ОСНОВЕ ОБЩИХ ИНТЕРЕСОВ?",
      "Мы обновляем подборку «Знакомьтесь» ежедневно, чтобы показать вам еще больше совместимых профилей. Так вы потратите меньше времени на поиск симпатии и больше —на общение с интересными вам людьми.",
      "НОВЫЕ РЕКОМЕНДАЦИИ ЕЖЕДНЕВНО",
      "Рекомендации в «Знакомьтесь» основаны на информации в вашем профиле и ваших предыдущих симпатиях, поэтому вы наверняка увидите людей, которые любят то же, что и вы, и хотят того же, что и вы — будь то веганские роллы с лобстером или серьезные отношения.",
      "СПЕЦИАЛЬНО{{__EOL__}}ДЛЯ ВАС",
      "Скачать Bumble",
      "Часто задаваемые вопросы",
      "Готовы к просмотру Bumble в режиме Инкогнито?",
      "После активации режима Инкогнито ваш профиль будут видеть только те пользователи Bumble, которым вы поставите лайк.",
      "Как работает режим Инкогнито?",
      "Когда режим Инкогнито выключен, ваш профиль виден другим пользователям Bumble, если вы соответствуете их предпочтениям, таким как возраст и местоположение. В режиме Инкогнито ваш профиль будет виден только тем, кому вы поставите лайк.",
      "Кто может видеть мой профиль на Bumble, когда я в режиме Инкогнито?",
      "Режим Инкогнито и <a href={{blocking_on_bumble_url}}>блокировка на Bumble</a> – это разные вещи. Когда у вас включен режим Инкогнито, те, кому вы поставили лайк (или те, с кем вы обменивались сообщениями в прошлом), будут видеть ваш профиль.",
      "Режим Инкогнито и блокировка профилей – это примерно одно и то же?",
      "В режиме Инкогнито на Bumble вы сами решаете, кто будет видеть ваш профиль.",
      "Режим Инкогнито на Bumble",
      "Если вы хотите отдохнуть от дейтинга или просматривать Bumble, не показывая свой профиль другим пользователям, включите режим Инкогнито. Мы скроем ваш профиль от всех – за исключением пользователей, которым вы сами поставите лайк. Чем делиться и от кого не прятаться – решать вам.",
      "Скройте свой профиль в режиме Инкогнито",
      "Вы решаете, кто может видеть ваши фото и личную информацию.",
      "Хотите включить личную информацию в свой профиль? Вы можете безопасно поделиться всем, чем пожелаете, со своими имеющимися симпатиями.",
      "Спокойно просматривайте профили, не боясь наткнуться на бывшего партнера, коллегу или тех, кто вас не интересует в романтическом плане.",
      "Вы у руля",
      "Выбирайте, чем поделиться",
      "Никаких неловких ситуаций",
      "Чем хорош режим Инкогнито",
      "Режим Инкогнито доступен для подписчиков {{service_level_lexeme_name}} и {{service_level_lexeme_name_two}}. После регистрации вы можете активировать его, перейдя на вкладку «Профиль», нажав настройки в правом верхнем углу и переключившись на режим Инкогнито.",
      "Активировать {{service_level_lexeme_name}}",
      "Как применять режим Инкогнито на Bumble",
      "СКАЧАЙТЕ Bumble, ЧТОБЫ ПОПРОБОВАТЬ МГНОВЕННУЮ СИМПАТИЮ",
      "Чтобы открыть QR-код, нажмите значок профиля в нижней части экрана приложения Bumble, а затем – значок QR-кода в верхнем правом углу экрана.",
      "Где я могу найти мой личный QR-код?",
      "Нет! Вы должны быть подключены к интернету – через мобильные данные или по Wi-Fi – и использовать действующий QR-код для создания мгновенной симпатии.",
      "Могу ли я сделать скриншот моего QR-кода, чтобы поделиться им?",
      "Нет! Если кто-то из вас просканирует код, мгновенная симпатия возникнет автоматически.",
      "Оба ли человека должны просканировать QR-код?",
      "Благодаря мгновенной симпатии вы сможете автоматически увидеть, что у вас общего, и оценить ваши шансы на совместимость буквально с первой секунды знакомства.",
      "УЗНАЙТЕ, ЧТО У ВАС ОБЩЕГО, ЕЩЕ БЫСТРЕЕ",
      "Начали общаться на мероприятии Bumble и не хотите останавливаться? Переходите в приложение и не сдерживайте себя!",
      "ПРЕВРАТИТЕ ПРИЯТНОЕ ЗНАКОМСТВО В {{qr_code}} МГНОВЕННУЮ СИМПАТИЮ",
      "Мгновенная симпатия позволяет облегчить переход от приятного знакомства к еще более приятному общению без необходимости обмениваться номерами или профилями в социальных сетях. Просто попросите просканировать ваш QR-код, чтобы найтись на Bumble.",
      "Попробовать",
      "ПОЗНАКОМИЛИСЬ С КЕМ-ТО НА ОФЛАЙН-МЕРОПРИЯТИИ Bumble?",
      "Вместо обмена профилями в социальных сетях или номерами телефонов используйте мгновенную симпатию, чтобы оценить «химию» между вами прямо в приложении. Так вы сможете лучше узнать друг друга на своих условиях и под защитой Bumble.",
      "БОЛЕЕ БЕЗОПАСНЫЕ ЗНАКОМСТВА",
      "Познакомились на мероприятии Bumble? Продолжите общаться в приложении и обсудите ваши общие интересы — будь то отчаянная кофемания или любовь к редким кактусам.",
      "ПРОДОЛЖАЙТ​Е ОБЩЕНИЕ",
      "Попробуйте Opening Moves на Bumble",
      "Opening Moves – это еще один способ начать общение с теми, кто вам понравился. Просто выберите Opening Move и ждите интересных ответов – особенно тех, после которых удержаться от переписки будет невозможно.",
      "Как использовать Opening Moves на Bumble",
      "В небинарных и однополых парах любой пользователь может выбирать Opening Move и отвечать на него.",
      "Могу ли я использовать Opening Moves на Bumble для знакомства с людьми того же пола?",
      "Когда кто-то ответит на ваш Opening Move, вы увидите их профиль в своих чатах и сможете либо отреагировать на их ответ, либо начать новую беседу. У девушек есть 24 часа на то, чтобы написать сообщение, прежде чем симпатия сгорит.",
      "Как я увижу, что кто-то ответил на мои Opening Moves?",
      "Opening Moves – это способ сделать ваш первый шаг минимально стрессовым, чтобы вы могли быстрее и легче перейти к интересному общению.",
      "Opening Moves на Bumble",
      "Прекрасный вопрос! Opening Moves – это новый, более простой способ начать разговор. Вы можете выбрать предложенный Opening Move, например – «Какую песню ты обязательно включишь, если тебя назначат диджеем на вечеринке?» – или написать свой. Когда вы выберите Opening Move, ваши симпатии смогут на него ответить, увидев ваш профиль на экране симпатий.",
      "Что такое Opening Move?",
      "Расслабленно знакомиться",
      "Рассказать о себе",
      "Начинать интересный разговор",
      "С Opening Moves легче…",
      "Вы можете выбрать Opening Move, который будут получать ваши новые симпатии. Перейдите в раздел Opening Moves в вашем профиле и выберите один из предложенных вопросов или поддайтесь вдохновению и напишите свой собственный. Вы можете менять Opening Moves когда пожелаете.",
      "Попробовать",
      "Как применять Opening Moves на Bumble",
      "<a href={{see_more_url}}>Еще</a>",
      "Хотите взять паузу? Попробуйте режим невидимки.",
      "Да, для этого вам надо перейти в настройки или на экран с вашими симпатиями и отключить режим невидимки.",
      "Можно ли выключить режим невидимки раньше?",
      "Перейдите в настройки, нажмите режим невидимки и выберите, как долго вы хотите отсутствовать. Когда отдохнете, возвращайтесь – мы будем вас ждать!",
      "Как использовать режим невидимки в своем профиле?",
      "Нет. В режиме невидимки возможность пролистывать профили и общаться будет приостановлена. Чтобы посмотреть, на сколько активирован режим невидимки, и при желании отключить его, перейдите на экран с новыми профилями.",
      "Можно ли продолжать листать профили и общаться в режиме невидимки?",
      "Те, с кем вы уже общались, увидят ваш статус отсутствия, если вы решите его установить. Если вы не поставите этот статус, им будут видны ваши предыщущие сообщения, как обычно.",
      "Что будут видеть мои симпатии, пока я нахожусь в режиме невидимки?",
      "Как взаимодействовать с Bumble – решать только вам. Вы можете покинуть нашу платформу в любой момент.",
      "Вы можете обновить свой статус и сообщить имеющимся симпатиям о своем отсутствии, чтобы они не подумали, что вы их <a href={{ghosted_article_url}}>игнорируете</a>. Они получат оповещение автоматически – вам не придется лично отправлять им сообщения.",
      "Отдохните от активного дейтинга без потери ваших чатов и контактов.",
      "Общайтесь (или молчите) по вашим правилам",
      "Держите ваши симпатии в курсе",
      "Отдохните без потери контактов",
      "Чем хорош режим невидимки",
      "Откройте приложение Bumble, перейдите на вкладку своего профиля и нажмите шестеренку настроек в правом верхнем углу. В настройках выберите кнопку «Режим невидимки» и задайте период вашего отсутствия. Вы можете скрыть свой профиль на 24 часа, 72 часа, на неделю или на неопределенный срок.{{__EOL__}}{{__EOL__}}Обратите внимание, что симпатии, с которыми у вас нет активных чатов, не будут приостановлены и могут сгореть, пока вы находитесь в режиме невидимки. Если вы установите статус, люди, с которыми вы общаетесь в данный момент, узнают с его помощью о вашем отсутствии.",
      "Как работает режим невидимки на Bumble",
      "Подустали от стремительного ритма жизни? Режим невидимки спешит на помощь! Приостановите свой аккаунт на Bumble и знакомьтесь по собственному графику.",
      "Режим невидимки на Bumble",
      "Режим невидимки – это удобный способ поставить свой аккаунт на паузу, когда хочется отдохнуть от активного дейтинга. Вы сможете продолжать общение с существующими симпатиями, но ваш профиль будет скрыт от других пользователей до тех пор, пока вы не захотите вернуться к просмотру профилей. При активации режима невидимки информация о вашем профиле и уже имеющиеся контакты сохраняются.",
      "Поставьте ваш профиль на Bumble на паузу",
      "Находясь в режиме невидимки, вы можете сообщить существующим симпатиям, что решили взять паузу. Обновите ваш статус, выбрав один из доступных вариантов: «Путешествую», «В работе», «На цифровом детоксе» или «Время для себя». Все для вашего комфорта!",
      "Добавьте причину отсутствия",
      "При активации режима невидимки ваш аккаунт на Bumble (или {{page_partner_name_bff}}) будет поставлен на паузу. Ваши симпатии не узнают, что вы включили режим невидимки, если вы им об этом не сообщите. Чтобы отключить этот режим раньше заданного срока, перейдите в настройки и нажмите «отключить режим невидимки». Да-да, все настолько просто.",
      "Устройте перерыв в общении",
      "Попробуйте SuperSwipe на Bumble",
      "Сколько пожелаете! Подписчики {{service_level_lexeme_name}} получают {{num}} [[superswipe:num@3]] в неделю, но вы можете пополнить их количество в любое время. Пользователи без платной подписки могут покупать партии SuperSwipes.",
      "Сколько SuperSwipes я могу отправить на Bumble?",
      "Когда вам поставят SuperSwipe, вы сразу же получите уведомление. Кроме того, в профиле отправителя над его именем появится специальный ярлык, сигнализирующий о том, что вы им понравились.",
      "Как узнать, что мне прислали SuperSwipe?",
      "Чувствуете, что это нечто большее, чем просто симпатия? Отправьте SuperSwipe, чтобы сообщить о серьезности своих намерений и быстрее перейти к интересному общению.",
      "SuperSwipe на Bumble",
      "Выделиться из толпы не всегда просто, но SuperSwipe поможет вам произвести потрясающее первое впечатление.{{__EOL__}}{{__EOL__}}Когда вы отправляете SuperSwipe, получателю приходит уведомление. Вы автоматически переноситесь на вершину списка вашей симпатии, а на вашем профиле появляется специальный ярлык, чтобы вас точно не пропустили. Если симпатия окажется взаимной, можно сразу переходить к общению.",
      "Что такое SuperSwipe?",
      "Привлекать внимание",
      "Больше общаться",
      "Больше знакомиться",
      "SuperSwipe поможет вам...",
      "Если у вас есть <a href={{bumble_boost_url}}>Bumble {{service_level_lexeme_name}}</a> или Bumble {{service_level_lexeme_name_one}}, вы получаете {{num}} [[superswipe:num@3]] в неделю бесплатно. Кроме того, все пользователи могут покупать партии до {{num_two}} [[superswipe:num_two@1]] из вкладки профиля. Если у вас есть {{service_level_lexeme_name_three}}, вы получаете {{num_three}} [[superswipe:num_three@3]] в неделю.{{__EOL__}}{{__EOL__}}Как использовать SuperSwipe? Все максимально просто – увидев понравившийся профиль, нажмите значок желтой звезды в его левом нижнем углу.",
      "Как применять SuperSwipe",
      "Apple и Apple logo – товарные знаки компании Apple Inc. Google Play является товарным знаком компании Google LLC.",
      "Знакомьтесь в поездках благодаря режиму Путешествий на Bumble",
      "Режим Путешествий доступен <a href={{bumble_premium_url}}>подписчикам {{service_level_lexeme_name}}</a>. После включения этого режима вам будут показываться новые профили из выбранного города.",
      "Как использовать режим Путешествий?",
      "В целом, да. У нас есть несколько ограничений, связанных с безопасностью, которые могут меняться со временем, но факт остается фактом – режим Путешествий доступен для большинства направлений.",
      "Можно ли использовать режим Путешествий в любой точке мира?",
      "В режиме Путешествий ваше местоположение будет показываться как центр выбранного вами города, чтобы местные пользователи могли найти ваш профиль. Кроме того, они будут видеть отметку о том, что вы находитесь в режиме Путешествий.",
      "Собираетесь в отпуск или планируете посетить новый город по работе? Включите режим Путешествий на Bumble, чтобы знакомиться в поездках.",
      "Режим Путешествий на Bumble",
      "Режим Путешествий – это прекрасный способ знакомиться в поездках. Вы можете установить любую точку мира как ваше местоположение, и ваш профиль будет показывать, что вы сейчас там. Это позволит вам находить новые знакомства везде, куда приведут вас ваши путешествия.",
      "Что такое режим Путешествий",
      "Влюбиться в новый город легче всего с местным гидом. Режим Путешествий поможет вам найти людей, которые сделают вашу поездку еще более незабываемой.",
      "Установите новое местоположение не позднее, чем за семь дней до отправления, чтобы заранее спланировать свидания с местными пользователями.",
      "Речь не только о курортных романах – знакомиться можно в поездке домой на праздники, в командировке или путешествии в новый город. У любви, как и у самолетов, есть крылья.",
      "Находите лучшие места для свиданий",
      "Приключения ждут",
      "Для любви нет границ",
      "Чем хорош режим Путешествий",
      "Режим Путешествий доступен подписчикам <a href={{bumble_premium_url}}>{{service_level_lexeme_name}} и {{service_level_lexeme_name_two}}</a> на Bumble. Нажмите значок настроек в правом верхнем углу своего профиля и прокрутите вниз до режима Путешествий. Выберите новое местоположение, чтобы просматривать местные профили. Ваши потенциальные симпатии увидят, что вы находитесь в режиме Путешествий.",
      "Как работает режим Путешествий?",
      "Попробовать",
      "Попробуйте веб-версию Bumble",
      "Безусловно. Веб-приложение Bumble работает на ПК, Маках и планшетах во всех основных веб-браузерах.",
      "Могу ли я использовать Bumble на своем ПК или Маке?",
      "Да. Вы можете отправлять сообщения или даже совершать видео- или аудиозвонки в веб-приложении Bumble.",
      "Могу ли я общаться со своими симпатиями в веб-версии приложения?",
      "Выберите «Войти» – или нажмите <a href={{sign_in_url}}>здесь</a>. Вы можете выполнить вход с помощью Apple ID, Facebook или номера телефона.",
      "Как выполнить вход в веб-версию Bumble?",
      "Хотите отдохнуть от телефона? Попробуйте веб-приложение Bumble – там есть все, за что вы любите Bumble, только на большом экране.",
      "Используйте веб-версию Bumble",
      "Для входа в веб-версию приложения Bumble просто перейдите на наш веб-сайт, нажмите «Войти», и выберите свой обычный способ входа с помощью Apple ID, учетных данных Facebook или номера телефона. Готово! Можете наслаждаться общением – ваши чаты и контакты ждут вас.",
      "Войти",
      "Войдите через браузер",
      "Редактируйте свой профиль и применяйте дополнительные фильтры.",
      "Подтвердите, что это вы, чтобы увеличить шансы на потрясающее знакомство.",
      "Выложите новые классные снимки или удалите старые.",
      "Обновляйте свой профиль",
      "Покажите другим, что вы – это действительно вы",
      "Добавляйте или удаляйте фото",
      "Что можно делать в веб-версии Bumble",
      "Ваши чаты и контакты будут слева, а потенциальные новые симпатии – справа.{{__EOL__}}{{__EOL__}}Используйте левую и правую стрелки или нажимайте значки в правой части экрана, чтобы просматривать профили и ставить лайки людей – точно так же, как на своем телефоне.",
      "Как использовать веб-версию Bumble?",
      "Мы ценим доброту, призываем вас быть собой и всегда заботимся, в первую очередь, об интересах женщин.",
      "Место, где легко сделать первый шаг",
      "Мы не можем жить без отношений. Bumble — это место, где можно найти любовь, друзей или новые карьерные возможности.",
      "Философия Bumble",
      "Мы выступаем за честность, доброту, равенство, здоровую самооценку и уважение на всех этапах отношений. Мы не просто принимаем, а горячо приветствуем любую ориентацию и любые намерения – как поиск серьёзных отношений, так и желание приятно провести время.",
      "Ценности Bumble",
      "На Bumble девушки правят бал, делая первый шаг или выбирая Opening Move, на который могут отреагировать их симпатии. Это наш ответ устаревшей расстановке сил и призыв к равенству с самого начала.",
      "Как функционирует Bumble",
      "О нас",
      "Скачать",
      "Скачайте приложение – так удобнее.",
      "С этими функциями знакомиться онлайн ещё приятнее",
      "12 февраля, 2024",
      "Как укрепить эмоциональную близость в отношениях",
      "25 января, 2024",
      "Инструкция к Bumble для тех, кто никогда раньше не пользовался дейтинг-приложением",
      "5 февраля, 2024",
      "Deception Detector™",
      "Лучшие новости из мира дейтинга",
      "Считаете, что размер имеет значение? Тогда добро пожаловать на наш веб-сайт – он такой же, как приложение, только больше.",
      "Веб-приложение",
      "Хотите немного отдохнуть от дейтинга? Включите <b>режим неведимки</b>. Обещаем, мы вас дождёмся!",
      "Перерыв? Не вопрос!",
      "<b>Видеозвонки</b> и <b>голосовые чаты</b> помогут вам максимально приблизиться к атмосфере реальной встречи, не выходя из дома.",
      "Скажите громко",
      "Показать больше",
      "Выберите <b>Opening Moves</b> и ждите, пока ваша симпатия сама инициирует общение.",
      "Меньше усилий, больше удовольствия",
      "Захватите что-нибудь вкусненькое в дьюти-фри, включите <b>режим Путешествий</b> и знакомьтесь в любой точке мира.",
      "Возьмите нас с собой в путешествие",
      "Понравился кто-то? Сообщите о своих чувствах с помощью <b>SuperSwipe</b>.",
      "Привлеките внимание",
      "Используйте <b>Compliments</b>, чтобы привлечь внимание вашей симпатии.",
      "Подарите улыбку",
      "Используйте <b>режим Инкогнито</b>, чтобы скрыть свой профиль от начальника, родственников или бывших.",
      "Скройте ваш профиль",
      "Дейтинг по вашим правилам",
      "Спустя несколько месяцев она нашла здесь и одну из подружек невесты",
      "Ханна познакомилась с будущим мужем на Bumble.",
      "Карин и Ю Лиан познакомились на Bumble – Ю Линг привлекли фотографии Карин из путешествий. И теперь они вместе построили собственный уютный домик!",
      "Карин и Ю Лиан",
      "Брайан и Кэси вместе работали за границей – а познакомились на Bumble",
      "Брайан и Кэси",
      "Это наши истории успеха, и вы легко можете стать одной из них",
      "Сделайте следующий шаг",
      "Узнать, что нового",
      "Вы достойны самого лучшего! Наши способы дейтинга подарят вам больше удовольствия и меньше стресса.",
      "Поднимите вашу планку на достойную высоту",
      "Подробнее о Bumble",
      "Мы единственное приложение, где интересы женщин всегда на первом месте. Ведь если позаботиться о них, станет лучше всем.",
      "Make the first move™",
      "Когда вам кто-то понравится, смело делайте первый шаг. Ныряйте в глубину, узнайте, что у вас общего и, самое главное, просто наслаждайтесь процессом.",
      "Влюбляйтесь на Bumble Date",
      "Находите полезные знакомства, которые приведут вас к новой работе, вдохновляющим наставникам или – почему бы и нет – к смене карьеры.",
      "Стройте карьеру на Bumble Bizz",
      "Будьте парой, как два сапога. Или притягивайтесь, как противоположности. Заводите новых друзей и находите общие занятия по душе – и дома, и в путешествиях.",
      "Находите новых друзей на Bumble For Friends",
      "Мы не только про дейтинг",
      "Bumble – это дейтинг-приложение, где девушки делают первый шаг. Оно навсегда изменило мир дейтинга, поиска друзей и нетворкинга. Загрузите Bumble и знакомьтесь с удовольствием.",
      "Bumble в {{social_network}}",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "Premium+",
      "Страница не найдена :(",
      "Обратная связь",
      "Войти",
      "Кажется, что-то пошло не так",
      "(откроется в новом окне)",
      "Вопросы/ответы",
      "Политика использования файлов cookie",
      "Premium",
      "Блог Bumble",
      "Карьера",
      "Чат",
      "Города",
      "Настройки cookie",
      "© 2006 – н.в. Bumble. Все права защищены.",
      "Уверенность на свиданиях – отчет",
      "Apple и логотип Apple являются товарными знаками компании Apple Inc.{{__EOL__}}Google Play является товарным знаком компании Google LLC.",
      "Скачать",
      "Facebook",
      "Подпишись на нас:",
      "Помощь",
      "Instagram",
      "Язык",
      "LinkedIn",
      "Bumble – часть Bumble Inc.",
      "Политика конфиденциальности",
      "Safety Centre",
      "Карта сайта",
      "Закон о современном рабстве",
      "Условия обслуживания",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "Compliments": {
            "few": [
                  "комплимента",
                  "комплиментов",
                  "комплиментам",
                  "комплимента",
                  "комплиментами",
                  "комплиментах"
            ],
            "many": [
                  "комплиментов",
                  "комплиментов",
                  "комплиментам",
                  "комплиментов",
                  "комплиментами",
                  "комплиментах"
            ],
            "plural": [
                  "комплимента",
                  "комплиментов",
                  "комплиментам",
                  "комплиментов",
                  "комплиментами",
                  "комплиментах"
            ],
            "singular": [
                  "комплимент",
                  "комплимента",
                  "комплименту",
                  "комплимент",
                  "комплиментом",
                  "комплименте"
            ],
            "two": [
                  "комплимента",
                  "комплиментов",
                  "комплиментам",
                  "комплимента",
                  "комплиментами",
                  "комплиментах"
            ]
      },
      "day": {
            "few": [
                  "дня",
                  "дней",
                  "дням",
                  "дня",
                  "днями",
                  "днях"
            ],
            "many": [
                  "дней",
                  "дней",
                  "дням",
                  "дней",
                  "днями",
                  "днях"
            ],
            "plural": [
                  "дня",
                  "дней",
                  "дням",
                  "дней",
                  "днями",
                  "днях"
            ],
            "singular": [
                  "день",
                  "дня",
                  "дню",
                  "день",
                  "днём",
                  "дне"
            ],
            "two": [
                  "дня",
                  "дней",
                  "дням",
                  "дня",
                  "днями",
                  "днях"
            ]
      },
      "hour": {
            "few": [
                  "часа",
                  "часов",
                  "часам",
                  "часа",
                  "часами",
                  "часах"
            ],
            "many": [
                  "часов",
                  "часов",
                  "часам",
                  "часов",
                  "часами",
                  "часах"
            ],
            "plural": [
                  "часа",
                  "часов",
                  "часам",
                  "часа",
                  "часами",
                  "часах"
            ],
            "singular": [
                  "час",
                  "часа",
                  "часу",
                  "час",
                  "часом",
                  "часе"
            ],
            "two": [
                  "часа",
                  "часов",
                  "часам",
                  "часа",
                  "часами",
                  "часах"
            ]
      },
      "minute": {
            "few": [
                  "минуты",
                  "минут",
                  "минутам",
                  "минуты",
                  "минутами",
                  "минутах"
            ],
            "many": [
                  "минут",
                  "минут",
                  "минутам",
                  "минут",
                  "минутами",
                  "минутах"
            ],
            "plural": [
                  "минуты",
                  "минут",
                  "минутам",
                  "минут",
                  "минутами",
                  "минутах"
            ],
            "singular": [
                  "минута",
                  "минуты",
                  "минуте",
                  "минуту",
                  "минутой",
                  "минуте"
            ],
            "two": [
                  "минуты",
                  "минут",
                  "минутам",
                  "минуты",
                  "минутами",
                  "минутах"
            ]
      },
      "month": {
            "few": [
                  "месяца",
                  "месяцев",
                  "месяца",
                  "месяца",
                  "месяцами",
                  "месяцах"
            ],
            "many": [
                  "месяцев",
                  "месяцев",
                  "месяцев",
                  "месяцев",
                  "месяцами",
                  "месяцах"
            ],
            "plural": [
                  "месяца",
                  "месяцев",
                  "месяца",
                  "месяца",
                  "месяцами",
                  "месяцах"
            ],
            "singular": [
                  "месяц",
                  "месяца",
                  "месяц",
                  "месяц",
                  "месяцем",
                  "месяце"
            ],
            "two": [
                  "месяца",
                  "месяцев",
                  "месяца",
                  "месяца",
                  "месяцами",
                  "месяцах"
            ]
      },
      "second": {
            "few": [
                  "секунды",
                  "секунд",
                  "секундам",
                  "секунды",
                  "секундами",
                  "секундах"
            ],
            "many": [
                  "секунд",
                  "секунд",
                  "секундам",
                  "секунд",
                  "секундами",
                  "секундах"
            ],
            "plural": [
                  "секунды",
                  "секунд",
                  "секундам",
                  "секунды",
                  "секундами",
                  "секундах"
            ],
            "singular": [
                  "секунда",
                  "секунды",
                  "секунде",
                  "секунду",
                  "секундой",
                  "секунде"
            ],
            "two": [
                  "секунды",
                  "секунд",
                  "секундам",
                  "секунды",
                  "секундами",
                  "секундах"
            ]
      },
      "superswipe": {
            "few": [
                  "SuperSwipes",
                  "SuperSwipes",
                  "SuperSwipes",
                  "SuperSwipes",
                  "SuperSwipes",
                  "SuperSwipes"
            ],
            "many": [
                  "SuperSwipes",
                  "SuperSwipes",
                  "SuperSwipes",
                  "SuperSwipes",
                  "SuperSwipes",
                  "SuperSwipes"
            ],
            "plural": [
                  "SuperSwipes",
                  "SuperSwipes",
                  "SuperSwipes",
                  "SuperSwipes",
                  "SuperSwipes",
                  "SuperSwipes"
            ],
            "singular": [
                  "SuperSwipe",
                  "SuperSwipe",
                  "SuperSwipe",
                  "SuperSwipe",
                  "SuperSwipe",
                  "SuperSwipe"
            ],
            "two": [
                  "SuperSwipes",
                  "SuperSwipes",
                  "SuperSwipes",
                  "SuperSwipes",
                  "SuperSwipes",
                  "SuperSwipes"
            ]
      },
      "year": {
            "few": [
                  "года",
                  "лет",
                  "годам",
                  "года",
                  "годами",
                  "годах"
            ],
            "many": [
                  "лет",
                  "лет",
                  "годам",
                  "лет",
                  "годами",
                  "годах"
            ],
            "plural": [
                  "года",
                  "лет",
                  "годам",
                  "года",
                  "годами",
                  "годах"
            ],
            "singular": [
                  "год",
                  "года",
                  "году",
                  "год",
                  "годом",
                  "годе"
            ],
            "two": [
                  "года",
                  "лет",
                  "годам",
                  "года",
                  "годами",
                  "годах"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
