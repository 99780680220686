/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "वापस जाएँ",
      "App Store से डाउनलोड करें",
      "Google Play से प्राप्त करें",
      "कैरोसेल",
      "स्लाइड {{number}}",
      "अगली स्लाइड",
      "पिछली स्लाइड",
      "स्लाइड",
      "स्लाइड {{total}} में से {{number}}",
      "मेरी व्यक्तिगत जानकारी को न बेचें और न ही साझा करें",
      "मेरी निजी जानकारी न बेचें या न शेयर करें",
      "Bumble पर तारीफ भेजें ताकि आप दिखा सकें कि आपको उनकी प्रोफ़ाइल पसंद है, और मैच से पहले उनका ध्यान अपनी ओर आकर्षित करें। अभी आज़माएँ",
      "तारीफें | तारीफ कैसे भेजें | Bumble",
      "एक व्यक्तिगत पेज जो आपके लिए सबसे अनुकूल लोगों को हाइलाइट करता है, हर रोज़ रिफ्रेश किया जाता है",
      "खोजें | अनुकूल लोगों को खोजें | Bumble",
      "अपनी गति से डेट करें और गुप्त मोड के साथ अपने Bumble प्रोफ़ाइल को कौन देखता है, इस पर नियंत्रण रखें। हमारी गाइड में Bumble पर गुप्त मोड का उपयोग करने के बारे में अधिक जानें",
      "गुप्त मोड | अपनी प्रोफ़ाइल छिपाएं | Bumble",
      "Bumble के असल जीवन के इवेंट में मिलने के बाद, ऐप में बातचीत जारी रखकर केमिस्ट्री बनाएं।",
      "तुरंत मैच | बातचीत को ऐप पर जारी रखें |\nBumble",
      "Bumble के Opening Moves फीचर के साथ बातचीत शुरू करने का एक नया तरीका आज़माएँ। उनका ध्यान अपनी ओर आकर्षित करें, माहौल बनाएँ और जल्दी से अच्छी बातचीत शुरू करें",
      "Bumble Opening Moves | Bumble",
      "क्या आपको ब्रेक या अपने लिए कुछ समय चाहिए? Bumble के स्नूज़ मोड से अपने Bumble अकाउंट को रोकने का तरीका जानें। यह आपको अपनी शर्तों पर डेट करने की सुविधा देता है",
      "स्नूज़ मोड | अपना खाता रोकें | Bumble",
      "क्या आपने अभी-अभी Bumble पर किसी ऐसे व्यक्ति को देखा है जिसे आप वाकई पसंद करते हैं? उन्हें यह दिखाने के लिए SuperSwipe का उपयोग करें कि आप गंभीर हैं। आरंभ करने के लिए उनकी प्रोफ़ाइल पर स्टार पर टैप करें, या यहाँ अधिक विवरण देखें",
      "Bumble SuperSwipe | SuperSwipe क्या है? | Bumble",
      "काम या मौज-मस्ती के लिए यात्रा कर रहे हैं? Bumble पर ट्रेवल मोड आपको किसी नई जगह पर मज़ेदार नए कनेक्शन बनाने और जहाँ भी आप जाएँ, डेट करने में मदद कर सकता है। ट्रेवल मोड के बारे में अभी यहाँ जानें",
      "Bumble का ट्रेवल मोड | जहां भी जाएं डेट करें | Bumble",
      "जानना है कि क्या मैसेजस में महसूस होने वाला जुड़ाव असल ज़िंदगी में भी महसूस होगा? अभी Bumble पर वीडियो चैट या वॉयस कॉल आज़माएँ और वाइब चेक करें",
      "वीडियो चैट | एक कॉल करें | Bumble",
      "Bumble वेब, सर्वोत्तम फीचर, के उत्साह का अनुभव करें।",
      "Bumble वेब | Bumble",
      "यहाँ कान्ट्रैक्ट समाप्त करें",
      "Bumble होमपेज",
      "मेनू बंद करें",
      "मेनू खोलें",
      "भाषा चुनें",
      "फूटर",
      "Bumble पर ऐक्सेसबिलिटी\n\n---",
      "Apple और Apple का लोगो Apple Inc के ट्रेडमार्क हैं।",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble डेट",
      "करियर",
      "इवेंट्स",
      "Google Play, Google LLC का ट्रेडमार्क है.",
      "दिशानिर्देश",
      "निवेशक",
      "कुकीज़ प्रबंधित करें",
      "कुकीज़ प्रबंधित करें",
      "Modern Slavery Act स्टेटमेंट",
      "संग्रह पर सूचना",
      "गोपनीयता नीति",
      "सीजनल डेटिंग गाइड्स",
      "नियम और शर्तें",
      "ऐप",
      "वाशिंगटन कंज्यूमर हैल्थ डाटा प्राइवेसी पॉलिसी",
      "हमारे बारे में",
      "एम्बेसडर",
      "The Buzz",
      "नये दोस्त बनाएं और अपनी कम्यूनिटी को खोजें",
      "पेशेवर रूप से नेटवर्क बनाएं और करियर में आगे बढ़ें",
      "कोई रिश्ता, कुछ अनौपचारिक, या बीच का कुछ भी खोजें",
      "और जानिये",
      "ट्रेवल मोड के ग्लोबल हो जाएं",
      "फीचर्स",
      "Compliments",
      "उन्हें कुछ Compliments दें ताकि आप भीड़ से अलग दिखें।",
      "खोज",
      "एक व्यक्तिगत पेज जो आपके लिए सबसे अनुकूल लोगों को हाइलाइट करता है, हर रोज़ रिफ्रेश किया जाता है",
      "गुप्त मोड",
      "अपने बॉस, रिश्तेदारों या पूर्व साथियों से अपनी प्रोफ़ाइल छिपाने के लिए गुप्त मोड का उपयोग करें।",
      "तुरंत मैच",
      "क्या आपने Bumble के असल जीवन के इवेंट में बातचीत शुरू की है? इसे ऐप पर जारी रखें।",
      "Opening Moves",
      "चैट शुरू करने के लिए अपने मैच को आमंत्रित करने के लिए Opening Moves चुनें, ताकि आपको ऐसा न करना पड़े।",
      "स्नूज़ मोड",
      "स्नूज़ मोड के साथ डेटिंग को होल्ड पर रखें। जब आप फिर से डेट करने के लिए तैयार हों, आपको पता ही है कि हम कहाँ हैं।",
      "SuperSwipe",
      "प्रोफ़ाइल वाइब जांच में पास हुई? SuperSwipe से उन्हें बताएं।",
      "ट्रैवल मोड",
      "ट्रैवल मोड के साथ दुनिया में कहीं भी अपना ड्यूटी-फ्री स्नैक और मैच ढूंढें।",
      "वीडियो चैट",
      "क्या आप किसी से व्यक्तिगत तौर पर मिलने के लिए तैयार है यह देखने के लिए वीडियो चैट और ऑडियो कॉल का उपयोग करें।",
      "वेब ऐप",
      "सीधे अपने ब्राउज़र पर Bumble अनुभव प्राप्त करें।",
      "शॉप",
      "मुख्य",
      "मुख्य सामग्री पर जाएं",
      "Bumble का लोगो",
      "उन्हें जानना चाहते हैं? Bumble पर वीडियो चैट का उपयोग करें",
      "वीडियो कॉल शुरू करने के लिए बस अपने चैट में वीडियो आइकन पर टैप करें, और वह भी कोई व्यक्तिगत संपर्क जानकारी साझा किए बिना।",
      "Bumble पर वीडियो चैट कैसे काम करती है?",
      "नहीं। Bumble पर सभी कॉल और चैट आपकी व्यक्तिगत जानकारी को सुरक्षित रखते हैं। जब तक आप उन्हें नहीं बताएंगे, उन्हें आपका नंबर या कोई अन्य संपर्क विवरण नहीं मिलेगा।",
      "क्या मेरा मैच मेरा फ़ोन नंबर देखेगा?",
      "बिल्कुल! अगर आप आमने-सामने बात करने के लिए तैयार नहीं हैं, तो वॉयस कॉल आपको ऑडियो के ज़रिए कनेक्ट करने की सुविधा देता है। इसके बजाय बस कॉल आइकन चुनें।",
      "क्या मैं ऑडियो कॉल को आज़मा सकता हूँ?",
      "हमें \"मैसेज प्राप्त हुआ* सूचना पसंद है। लेकिन हम जानते हैं कि यह बताना मुश्किल है कि आपकी चैट व्यक्तिगत रूप से उतनी अच्छी होगी या नहीं। यहीं पर वीडियो और ऑडियो कॉल काम आते हैं।",
      "Bumble पर वीडियो चैट",
      "<a href={{video_chat_url}}>वीडियो चैट और ऑडियो कॉल</a> आपको एक-दूसरे को जानने और यह तय करने में मदद कर सकते हैं कि क्या आप बात को ऐप से परे ले जाना चाहते हैं। सबसे अच्छी बात? जब आप Bumble के ज़रिए कॉल करते हैं, तो आपको फ़ोन नंबर एक्सचेंज करने की ज़रूरत नहीं होती है - इसलिए आपकी निजी जानकारी तब तक सुरक्षित रहती है जब तक आप शेयर करने के लिए तैयार नहीं होते।",
      "वीडियो चैट से अपनी केमिस्ट्री देखें",
      "ऑनलाइन और ऑफलाइन डेटिंग के बीच के अंतर को पाटें, और तय करें कि क्या अब समय आ गया है कि हम आगे बढ़ें और मिलें।",
      "उन्हें बेहतर तरीके से (और तेजी से) जानें। आमने-सामने की बातचीत या फोन पर बातचीत किसी के बारे में बहुत कुछ बता सकती है।",
      "कंट्रोल आपके हाथ में है। हम आपकी संपर्क जानकारी निजी रखेंगे, ताकि आप चुन सकें कि उन्हें कब शेयर करना है।",
      "देखें कि क्या आप व्यक्तिगत रूप से मिलने के लिए तैयार हैं",
      "त्वरित वाइब जांच आज़माएं",
      "निजी जानकारी दिए बिना कनेक्ट करें",
      "आपको वीडियो चैट और कॉल का उपयोग क्यों करना चाहिए",
      "बातचीत शुरू करने के लिए तैयार हैं? आप Bumble ऐप में अपनी चैट से ही चुन सकते हैं कि ऑडियो कॉल शुरू करना है या वीडियो कॉल। वीडियो कॉल शुरू करने के लिए, ऊपरी दाएँ कोने में ग्रे वीडियो कैमरा आइकन पर टैप करें। आपको आइकन के बगल में तीन बिंदु भी दिखाई देंगे, इस पर क्लिक करें और आपको ऑडियो कॉल करने का विकल्प भी दिखाई देगा।",
      "वीडियो चैट कैसे करें",
      "Compliments को आज़माने के लिए तैयार हैं?",
      "जब आप कोई Compliment भेजते हैं, तो उसे प्राप्त करने वाला व्यक्ति उसे तब देख सकेगा जब आप उनके 'आपके लिए' सेक्शन में दिखाई देंगे।",
      "जब आप Bumble पर किसी को Compliment भेजते हैं तो क्या होता है?",
      "अगर उनके Compliment ने आपका ध्यान खींचा है, तो आगे बढ़ें और उनके साथ मैच करें। आपको अपनी चैट में Compliment दिखाई देगा, और हमेशा की तरह <a href={{make_the_first_move_url}}>पहला कदम बढ़ाने</a> के नियम लागू होंगे।",
      "मैं किसी Compliment का जवाब कैसे दूँ?",
      "आप {{num}} [[Compliments:num]] निःशुल्क भेज सकते हैं। जब आप सब खत्म कर लें, तो आप Bumble ऐप में 30 तक के पैक में और खरीद सकते हैं। इनमें से एक का उपयोग उस Compliment को भेजने के लिए किया जाएगा (बाकी को तब के लिए बचाकर रखें जब आपको कोई और प्रोफ़ाइल पसंद आए)। चिंता न करें, वे समाप्त नहीं होंगे!",
      "मैं कितने Compliments भेज सकता हूँ?",
      "एक अच्छा शब्द (या दो) बहुत काम आ सकता है। अगर आप कोई Compliment भेजना चाहते हैं, लेकिन आपके अंगूठे कीबोर्ड पर ही अटके हुए हैं, तो प्रेरणा के लिए ये आज़माएँ:",
      "अधिक उदाहरण देखें",
      "“जो कोई भी [अरिजित] को पसंद करता है, उसकी पसंद अच्छी है।”",
      "“आपकी मुस्कान संक्रामक है!”",
      "“आपकी बायो ने मुझे हंसाया। बहुत बढ़िया।”",
      "Bumble पर बेहतर तारीफें कैसे भेजें",
      "क्या आप उनका दिन बनाना चाहते हैं? लगता है आप Compliments का इस्तेमाल करने के लिए तैयार हैं।",
      "Bumble पर Compliments",
      "Bumble पर Compliments का इस्तेमाल करके संभावित मैच को बताएं कि आपको उनकी प्रोफ़ाइल पसंद है (और शायद उन्हें शर्माने के लिए मजबूर भी कर दें)। आपको अपनी {{service_level_lexeme_name}} मेंबरशिप के हिस्से के रूप में Compliments मिलते हैं। यह मैच होने से पहले ही मैसेज भेजने और प्राप्त करने का एक तरीका भी है।",
      "Compliments फीचर कैसे काम करता है",
      "जब आपको कोई Compliment मिलता है, तो वह आपके \"आपके लिए\" भाग में भेजने वाले की प्रोफ़ाइल पर दिखाई देगी। आपको एक सूचना भी मिलेगी जिसमें बताया जाएगा कि किसी को आपकी प्रोफ़ाइल का कोई हिस्सा पसंद आया है।",
      "अपनी तारीफें देखें",
      "आप किसी व्यक्ति की प्रोफ़ाइल के नीचे पीले दिल के आइकन पर टैप करके Compliment भेज सकते हैं। आप कहाँ टैप करते हैं यह महत्वपूर्ण है, क्योंकि आप उस विशिष्ट फ़ोटो या उनकी प्रोफ़ाइल के हिस्से की तारीफ़ कर रहे होंगे! जब आप क्लिक करेंगे, तो आपके लिए अपना मैसेज लिखने के लिए एक टेक्स्ट बॉक्स खुलेगा (बस सुनिश्चित करें कि इसमें केवल 150 अक्षर हों)।",
      "तारीफ भेजें",
      "खोज को अभी आज़माने के लिए Bumble को डाउनलोड करें",
      "हर दिन सुबह 9 बजे से, आपको खोज में नए लोग दिखाई देंगे। आप उनसे उन बातों के आधार पर जुड़ सकते हैं जो आपमें एक जैसी हैं।",
      "खोज पेज कब अपडेट होता है?",
      "आपका खोज पेज संभावित मैचों को 3 मुख्य श्रेणियों में वर्गीकृत करता है: समान रुचियां, समान डेटिंग लक्ष्य और समान समुदाय। पिछले मैच के आधार पर प्रोफ़ाइल के सुझाव भी दिखाए जाते हैं।",
      "मेरा खोज पेज कैसे तैयार किया गया है?",
      "एक व्यक्तिगत पेज जो आपके सबसे अनुकूल लोगों को हाइलाइट करता है, हर रोज़ रिफ्रेश किया जाता है",
      "{{qr_code}} खोज के साथ अपनी वाइब से मेल खाने वाले लोगों को खोजें",
      "खोज आपको उन लोगों का चयन दिखाता है जो हमें लगता है कि आपको पसंद आएंगे, जो आपकी समान रुचियों, डेटिंग लक्ष्यों और समुदायों पर आधारित है।",
      "खोज को आज़माएं",
      "क्या आप आपसी सहमति बनाने के लिए कोई नया रास्ता खोज रहे हैं?",
      "खोज पेज को हर दिन अपडेट किया जाता है, ताकि आपको और भी अधिक लोग दिखें जिनके साथ आप अनुकूल हैं - ताकि आप मैच की तलाश में कम समय बिताएं, और उन लोगों के साथ अधिक समय बिता सकें जिनके साथ आपकी वाइब बैठती है।",
      "सिफ़ारिशें हर रोज़ रिफ्रेश की जाती हैं",
      "खोज की सिफारिशें आपकी प्रोफ़ाइल और आपके साथ पहले से मैच हुए लोगों पर आधारित होती हैं, इसलिए आपको ऐसे लोग दिखाई देंगे जिन्हें आपकी पसंद की चीज़ें पसंद हैं और जिन्हें वो चाहिए जो आप चाहते हैं - चाहे वह शाकाहारी पनीर रोल हो या लंबी समय तक चलने वाले संबंध।",
      "आपके अनुसार{{__EOL__}}निजीकृत",
      "Bumble को डाउनलोड करें",
      "अक्सर पूछे जाने वाले सवाल",
      "गोपनीय रूप से Bumble को ब्राउज़ करने के लिए तैयार हैं?",
      "जब आप गुप्त मोड सक्रिय करते हैं, तो आप अन्य Bumble सदस्यों को तब तक दिखाई नहीं देंगे, जब तक कि आपको उनकी प्रोफ़ाइल पसंद न आए।",
      "गुप्त मोड कैसे काम करता है?",
      "गुप्त मोड के बिना, आपकी प्रोफ़ाइल Bumble पर अन्य डेटर्स को दिखाई देती है, जब आप उनकी पसंद, जैसे कि उम्र और स्थान के अनुसार होते हैं। जब आप गुप्त मोड सक्रिय करते हैं, तो लोग आपको तभी देख पाएँगे जब आप उन्हें पसंद करेंगे।",
      "मुझे Bumble के गुप्त मोड पर कौन देख सकता है?",
      "गुप्त मोड का उपयोग करना <a href={{blocking_on_bumble_url}}>Bumble पर किसी को ब्लॉक करने</a> जैसा नहीं है। इस सुविधा के साथ, यदि आप किसी को पसंद करते हैं (या यदि आपने उन्हें पहले मैसेज किया है), तो वे आपकी प्रोफ़ाइल देख पाएंगे।",
      "क्या गुप्त मोड किसी को ब्लॉक करने जैसा है?",
      "Bumble के गुप्त मोड से कंट्रोल करें कि आपकी प्रोफ़ाइल कौन देख सकता है।",
      "Bumble पर गुप्त मोड",
      "अगर आप डेटिंग से एक कदम पीछे हटना चाहते हैं, या Bumble को ब्राउज़ करना चाहते हैं पर यह नहीं चाहते कि अन्य डेटर्स आपकी प्रोफ़ाइल को देखें, तो आप गुप्त मोड पर स्विच कर सकते हैं। इस सुविधा को चालू करने का मतलब है कि हम आपको सभी से छिपा देंगे - जब तक कि आप उन्हें पहले पसंद न करें। यह आपको यह नियंत्रित करने की अनुमति देता है कि आप क्या शेयर करते हैं, और आपको कौन देखता है।",
      "गुप्त मोड के साथ अपने प्रोफ़ाइल छिपाएं",
      "आप तय करते हैं कि आपकी फ़ोटोज़ और व्यक्तिगत जानकारी कौन देख सकता है।",
      "क्या आप अपनी प्रोफ़ाइल में कुछ निजी जानकारी शामिल करना चाहते हैं? आप अपनी मौजूदा प्रोफ़ाइल के साथ अपनी मनचाही जानकारी साझा कर सकते हैं।",
      "किसी पूर्व साथी, अपने सहकर्मी या किसी अन्य व्यक्ति से, जो आपके डेटिंग राडार पर नहीं है, मिलने की चिंता किए बिना, स्वतंत्र रूप से ब्राउज करें।",
      "पूरा कंट्रोल हाथ में लें",
      "चुनें कि आप क्या शेयर करते हैं",
      "असहज आमने-सामने से बचें",
      "आपको गुप्त मोड क्यों पसंद आएगा",
      "गुप्त मोड {{service_level_lexeme_name}} और {{service_level_lexeme_name_two}} के मेंबर्स के लिए उपलब्ध है। साइन अप करने के बाद, आप अपने प्रोफ़ाइल टैब पर जाकर, ऊपरी दाएँ कोने में सेटिंग पर टैप करके और गुप्त मोड पर स्विच करके इसे चालू कर सकते हैं।",
      "{{service_level_lexeme_name}} पर अपग्रेड करें",
      "Bumble पर गुप्त मोड का उपयोग कैसे करें",
      "तुरंत मैच को आज ही आज़माने के लिए Bumble को डाउनलोड करें",
      "Bumble ऐप में, अपनी स्क्रीन के नीचे प्रोफ़ाइल आइकन पर जाएँ। वहाँ से, आपको स्क्रीन के ऊपर दाईं ओर QR कोड आइकन मिलेगा। इसे एक्सेस करने के लिए उस पर टैप करें।",
      "मैं अपना पर्सनल QR कोड कहाँ पा सकता/सकती हूँ?",
      "नहीं! आपको मोबाइल डेटा या Wi-Fi के जरिए इंटरनेट से कनेक्ट होना होगा, और तुरंत मैच के काम करने के लिए वर्तमान, लाइव QR कोड का उपयोग करना होगा।",
      "क्या मैं साझा करने के लिए अपने QR कोड का स्क्रीनशॉट ले सकता हूँ?",
      "नहीं! अगर कोई व्यक्ति कोड स्कैन करता है, तो आपका मैच अपने आप हो जाएगा।",
      "क्या दोनों लोगों को QR कोड स्कैन करने की जरूरत है?",
      "तुरंत मैच के साथ, आप स्वचालित रूप से देख सकते हैं कि आप में और आपके नए मैच में क्या समानता है - जिससे आपको शुरुआत से ही अपनी अनुकूलता की झलक मिल जाती है।",
      "सामान्य आधार खोजें, तेजी से",
      "क्या आपने Bumble के असल जीवन के इवेंट में बातचीत शुरू की है? इसे ऐप पर जारी रखें।",
      "अपने मीट-क्यूट को {{qr_code}} तुरंत मैच में बदलें",
      "तुरंत मैच आपके प्यारे साथी को “चलो बात करते रहें” के स्तर तक ले जाना आसान बनाता है, बिना नंबर या सोशल प्रोफाइल बताये या जाने। बस उन्हें Bumble पर मैच करने के लिए अपना QR कोड स्कैन करने दें।",
      "तुरंत मैच को आज़माएं",
      "क्या Bumble के असल जीवन के ईवेंट में किसी से मुलाकात हुई?",
      "सोशल प्रोफाइल या फ़ोन नंबर लेने-देने के बजाय, तुरंत मैच आपको पहले ऐप में माहौल समझने देता है। इस तरह, आप Bumble के सुरक्षा सुविधाओं की बदौलत अपनी शर्तों पर एक-दूसरे को बेहतर तरीके से जान सकते हैं।",
      "कनेक्ट करने का सुरक्षित तरीका",
      "Bumble के असल जीवन के इवेंट में मिलने के बाद, ऐप में बातचीत जारी रखते हुए और साझा रुचियों पर जुड़कर केमिस्ट्री बनाएं - चाहे वह परफेक्ट चाय के लिए प्यार हो या बॉलीवुड के लिए।",
      "बातचीत जारी रखें",
      "Bumble पर Opening Moves को आज़माएं",
      "Opening Moves उन लोगों से चैट करने का एक और तरीका है जिन्हें आप पसंद करते हैं। एक बार जब आप भेजने के लिए कोई मैसेज चुन लेते हैं, तो आप आराम कर सकते हैं, प्रतिक्रियाओं का इंतज़ार कर सकते हैं, और उन लोगों को जवाब दे सकते हैं जो वास्तव में सबसे अलग हैं।",
      "Bumble Opening Moves का उपयोग कैसे करे",
      "नॉन-बाइनरी और समान लिंग कनेक्शन के लिए, कोई भी व्यक्ति Opening Move निर्धारित कर सकता है और उसका जवाब दे सकता है।",
      "क्या मैं समान लिंग के लोगों के साथ डेट करने के लिए Bumble Opening Moves का उपयोग कर सकता हूँ?",
      "जब कोई व्यक्ति आपके Opening Move का जवाब देता है, तो आपको अपनी चैट में उसका प्रोफ़ाइल पॉप अप दिखाई देगा। मैच समाप्त होने से पहले महिलाओं के पास जवाब देने के लिए 24 घंटे होते हैं। वहां से, आप या तो उनके जवाब का जवाब दे सकते हैं या एक नई बातचीत शुरू कर सकते हैं।",
      "मैं कैसे देख सकता हूँ कि किसी ने मेरे Opening Moves पर प्रतिक्रिया दी है?",
      "Opening Moves पहला कदम बढ़ाने के समय प्रेशर को कम करने, और अच्छी बातचीत तक पहुंचने का एक आसान, तेज तरीका हैं।",
      "Bumble पर Opening Moves",
      "हमें खुशी है कि आपने पूछा। Opening Moves बातचीत शुरू करने का एक नया, आसान तरीका है। आप सुझाई गई Opening Move को चुन सकते हैं, जैसे \"अगर आप किसी पार्टी में गाने के लिए माइक मिले तो आपका पसंदीदा गाना कौन सा होगा?\", या अपनी खुद की मूव लिखें। अगर आपके पास एक सेट है, तो जब आपके मैच आपको अपनी मैच स्क्रीन पर देखेंगे तब वो आपके सवालों का जवाब दे सकते हैं।",
      "Opening Move क्या होती है?",
      "दबाव दूर करें",
      "शेयर करें कौन सी चीज़ आपको, आप बनाती है",
      "बेहतर बातचीत शुरू करें",
      "Opening Moves मदद कर सकती हैं…",
      "आप अन्य डेटर्स के साथ मैच होने पर भेजे जाने वाली Opening Move को सेट कर सकते हैं। बस अपनी प्रोफ़ाइल में Opening Move पर जाएँ, पहले से लिखे गए सवालों में से कोई एक चुनें, या रचनात्मक बनें और अपने खुद के लिखें। आप जब चाहें अपनी Opening Move बदल सकते हैं।",
      "Opening Moves आज़माएं",
      "Bumble की Opening Moves का उपयोग कैसे करें",
      "<a href={{see_more_url}}>और देखें</a>",
      "एक ब्रेक लेना चाहते हैं? स्नूज़ मोड को आज़माएं",
      "हां, आप अपनी सेटिंग या कतार में जाकर जब चाहें स्नूज़ को बंद कर सकते हैं।",
      "क्या मैं स्नूज़ मोड को समय से पहले निष्क्रिय कर सकता हूँ?",
      "सेटिंग्स में जाएँ, स्नूज़ पर टैप करें और चुनें कि आप कितने समय के लिए दूर रहना चाहते हैं। जब भी आप तैयार महसूस करें, वापस आएँ।",
      "मैं अपनी प्रोफ़ाइल पर स्नूज़ मोड का उपयोग कैसे करूँ?",
      "नहीं—स्नूज़ मोड में रहते हुए, सभी स्वाइप-और-चैट गतिविधि रोक दी जाती है। जब आप नए लोगों से मिलने जाएंगे, तो आपको एक मैसेज दिखाई देगा जिसमें बताया जाएगा कि स्नूज़ मोड कितने समय तक चलेगा, साथ ही इसे निष्क्रिय करने का विकल्प भी दिया जाएगा।",
      "क्या मैं स्नूज़ मोड में भी स्वाइप और चैट कर सकता हूँ?",
      "जिन लोगों को आपने पहले ही मैसेज भेजा है, उन्हें आपका 'दूर' स्टेटस दिखाई देगा (अगर आप इसे सेट करना चाहते हैं)। अन्यथा, उन्हें आपके पिछले मैसेज अपने इनबॉक्स में सामान्य रूप से दिखाई देंगे।",
      "मेरे स्नूज़ मोड में होने पर वर्तमान मैच क्या देखेंगे?",
      "आपके पास अपनी Bumble गतिविधि पर पूर्ण नियंत्रण है, और आप जब चाहें प्लेटफ़ॉर्म से दूर जा सकते हैं।",
      "आप अपने स्टेटस अपडेट का उपयोग करके मौजूदा कनेक्शन को अपडेट रख सकते हैं, ताकि ऐसा न लगे कि आप <a href={{ghosted_article_url}}>भाग गए</a> हैं। साथ ही, यह सब स्वचालित है - इसलिए आपको कोई मैसेज भेजने की आवश्यकता नहीं है।",
      "अपने संपर्कों या चैट को खोए बिना सक्रिय रूप से डेटिंग से ब्रेक लें।",
      "अपनी शर्तों पर डेट करें (या डिस्कनेक्ट करें)",
      "अपने कनेक्शन को अपडेट रखें",
      "अपनी गतिविधि रोकें, लेकिन अपने कनेक्शन नहीं",
      "हमें स्नूज़ मोड क्यों पसंद है",
      "Bumble ऐप खोलें, अपने प्रोफ़ाइल टैब पर जाएँ और ऊपरी दाएँ कोने में सेटिंग कोग पर टैप करें। अपनी सेटिंग में, स्नूज़ पर टैप करें और चुनें कि आप कितने समय के लिए दूर रहेंगे। आप अपनी प्रोफ़ाइल को 24 घंटे, 72 घंटे, एक हफ़्ते या अनिश्चित काल के लिए छिपा सकते हैं।{{__EOL__}}{{__EOL__}}याद रखें, जब आप स्नूज़ मोड में होंगे, तो वो मैच समाप्त हो जाएँगे जिनसे आपकी चैट नहीं चल रही है। अगर आप कोई स्टेटस सेट करने का फ़ैसला करते हैं, तो आप जिन लोगों से चैट कर रहे हैं, वे देख पाएँगे कि आप दूर हैं।",
      "Bumble पर स्नूज़ मोड कैसे काम करता है",
      "जब जीवन व्यस्त हो जाए, तो स्नूज़ मोड आपकी मदद के लिए मौजूद है। अपना Bumble अकाउंट को विराम दें और अपनी शर्तों पर डेट करें।",
      "Bumble पर स्नूज़ मोड",
      "जब आप डेटिंग से ब्रेक लेना चाहते हों, स्नूज़ मोड आपके अकाउंट को विराम देने का एक आसान तरीका है। आप अभी भी अपने मौजूदा कनेक्शन के साथ चैट कर सकते हैं, यह सिर्फ़ आपकी प्रोफ़ाइल को दूसरे डेटर्स से तब तक छिपाता है जब तक आप फिर से स्वाइप करना शुरू करने के लिए तैयार नहीं हो जाते। स्नूज़ मोड को सक्रिय करके आप अपनी प्रोफ़ाइल जानकारी नहीं खोएँगे या मौजूदा कनेक्शन नहीं हटाएँगे।",
      "अपने Bumble प्रोफ़ाइल को स्नूज़ करें",
      "जब आप स्नूज़ मोड में होते हैं, तो आप मौजूदा कनेक्शन को स्टेटस अपडेट के ज़रिए यह बताना चुन सकते हैं कि आप आराम कर रहे हैं। 'मैं यात्रा कर रहा हूँ', 'मैं काम पर ध्यान केंद्रित कर रहा हूँ', 'मैं डिजिटल डिटॉक्स पर हूँ' या 'मैं खुद को प्राथमिकता दे रहा हूँ' में से चुनें। आपकी जो भी भावना हो, हम आपके साथ हैं।",
      "एक 'दूर' स्टेटस जोड़ें",
      "स्नूज़ मोड सक्रिय करने से आपके Bumble (या {{page_partner_name_bff}}) अकाउंट पर विराम लग जाता है। जब तक आप उन्हें नहीं बताते, तब तक आपके मैच को पता नहीं चलेगा कि आप स्नूज़ मोड पर हैं। अगर आप अपने स्नूज़ मोड से शुरू में चुनी गई समय-सीमा से पहले इसे बंद करने का फ़ैसला करते हैं, तो बस सेटिंग्स पर वापस जाएँ और “स्नूज़ मोड निष्क्रिय करें” पर टैप करें। यह इतना आसान है।",
      "अपनी चैट रोकें",
      "Bumble पर SuperSwipe को आज़माएं",
      "जितने चाहें उतने! {{service_level_lexeme_name}} यूजर्स को हर हफ़्ते {{num}} [[superswipe:num]] मिलते हैं, लेकिन आप किसी भी समय अपना बैलेंस टॉप अप कर सकते हैं। मुफ़्त सदस्य SuperSwipes के पैक खरीद सकते हैं।",
      "मैं Bumble पर कितने SuperSwipes भेज सकता हूँ?",
      "जब कोई व्यक्ति आपकी प्रोफ़ाइल पर SuperSwipe का उपयोग करेगा, तो आपको तुरंत इसकी सूचना मिल जाएगी। आपको उस व्यक्ति की प्रोफ़ाइल पर उसके नाम के ऊपर एक लेबल भी दिखाई देगा, जिससे आपको पता चलेगा कि वह आपको पसंद करता है।",
      "मुझे कैसे पता चलेगा कि मुझे किसी से SuperSwipe मिला है?",
      "क्या आप किसी के बारे में गंभीर हैं? चुप्पी तोड़ने का एक शानदार तरीका है। बस उन्हें एक SuperSwipe भेजें ताकि उन्हें पता चले कि आपको उनमें दिलचस्पी है और जल्दी से अच्छी बातचीत शुरू करें।",
      "Bumble पर SuperSwipe",
      "भीड़ से अलग दिखना हमेशा आसान नहीं होता, लेकिन SuperSwipe आपको एक बेहतरीन पहली छाप छोड़ने में मदद कर सकता है।{{__EOL__}}{{__EOL__}}जब आप SuperSwipe का इस्तेमाल करते हैं, तो आपके द्वारा पसंद किए गए व्यक्ति को एक सूचना भेजी जाएगी। फिर आप अपनी प्रोफ़ाइल पर एक लेबल के साथ उनके सामने आ जाएँगे। अगर वो भी आपको पसंद करते हैं, तो चैटिंग शुरू करने का समय आ गया है।",
      "SuperSwipe क्या है?",
      "बोल्ड बनें और भीड़ से अलग दिखें",
      "और चैट्स शुरू करें",
      "अधिक कनेक्शन बनाएं",
      "SuperSwipe यह करने में आपकी मदद कर सकते हैं…",
      "अगर आपके पास <a href={{bumble_boost_url}}>Bumble {{service_level_lexeme_name}} या Bumble {{service_level_lexeme_name_one}}</a> है, तो आपको हर हफ़्ते {{num}} मुफ़्त [[superswipe:num]] मिलते हैं। साथ ही, सभी मेंबर प्रोफ़ाइल टैब से {{num_two}} [[superswipe:num_two]] तक के पैक खरीद सकते हैं। अगर आपके पास {{service_level_lexeme_name_three}} है, तो आपको हर हफ़्ते {{num_three}} [[superswipe:num_three]] मिलेंगे।{{__EOL__}}{{__EOL__}}SuperSwipe का इस्तेमाल करना आसान है—जब आपको कोई ऐसा व्यक्ति दिखे जो आपको पसंद हो, तो बस उनकी प्रोफ़ाइल के नीचे पीले रंग के स्टार आइकन पर टैप करें।",
      "SuperSwipe का उपयोग कैसे करें",
      "Apple और Apple का लोगो Apple Inc के ट्रेडमार्क हैं.{{__EOL__}}Google Play, Google LLC का ट्रेडमार्क है.",
      "Bumble पर ट्रैवल मोड के साथ कहीं भी डेट करें",
      "<a href={{bumble_premium_url}}>{{service_level_lexeme_name}} मेंबर्स</a> ट्रैवल मोड का उपयोग कर सकते हैं। इसे चालू करें और आपकी कतार आपको आपके चुने हुए शहर में नए चेहरे दिखाएगी।",
      "मैं ट्रैवल मोड का उपयोग कैसे करूं?",
      "बहुत हद तक - हमारे पास कुछ सुरक्षा प्रतिबंध हैं जो समय के साथ बदल सकते हैं, लेकिन ट्रैवल मोड अधिकांश गंतव्यों के लिए उपलब्ध है।",
      "क्या मैं ट्रैवल मोड का उपयोग कहीं भी कर सकता हूँ?",
      "ट्रैवल मोड आपके चुने हुए शहर के केंद्र के रूप में आपका स्थान दिखाएगा, ताकि क्षेत्र में नए कनेक्शन आपको ढूंढ सकें। उन्हें आपकी प्रोफ़ाइल पर एक नोट दिखाई देगा कि आप ट्रैवल मोड में हैं, इसलिए उन्हें पता चल जाएगा कि आप यात्रा कर रहे हैं, या यात्रा करने की योजना बना रहे हैं।",
      "छुट्टियों के लिए अपना बैग पैक कर रहे हैं या काम के लिए किसी नए शहर में जा रहे हैं? डेटिंग को अपने साथ ले जाने के लिए Bumble के ट्रैवल मोड को चालू करें।",
      "Bumble पर ट्रैवल मोड",
      "ट्रैवल मोड आपके दूर रहने के दौरान कनेक्शन बनाने का आपका सबसे बढ़िया साधन है। दुनिया में कहीं भी अपनी लोकेशन सेट करें, और आपकी प्रोफ़ाइल आपके द्वारा चुने गए शहर में दिखाई देगी। इसका मतलब है कि आप जहाँ भी अपने रोमांच के साथ डेटिंग कर सकते हैं।",
      "ट्रैवल मोड क्या है?",
      "स्थानीय लोगों से बेहतर किसी जगह के बारे में कोई नहीं जानता। ट्रैवल मोड आपको उन लोगों से जुड़ने में मदद कर सकता है जो आपकी यात्रा को और भी खास बना देंगे।",
      "अपनी यात्रा से सात दिन पहले ही शहर में अपनी लोकेशन सेट करें, ताकि आप पहले से ही डेट की योजना बना सकें और अपने लिए उपयुक्त व्यक्ति से मिल सकें।",
      "समुद्र के किनारे घूमने से आगे की सोचें—आप छुट्टियों में घर जा सकते हैं, काम के सिलसिले में यात्रा कर सकते हैं या किसी नए शहर में घूम सकते हैं। आप जहाँ भी जाएँ, रोमांस पाएँ।",
      "सर्वोत्तम स्थानीय डेटिंग स्पॉट खोजें",
      "एडवेंचर में भाग लेना",
      "स्थायी कनेक्शन बनाएँ",
      "ट्रैवल मोड को प्यार करने का कारण",
      "ट्रैवल मोड <a href={{bumble_premium_url}}>{{service_level_lexeme_name}} और {{service_level_lexeme_name_two}}</a> के मेंबर्स के लिए Bumble पर उपलब्ध है। अपनी प्रोफ़ाइल के शीर्ष दाईं ओर सेटिंग कोग पर टैप करें और तब तक स्क्रॉल करें जब तक आपको ट्रैवल मोड दिखाई न दे। उस क्षेत्र में लोगों से मिलना शुरू करने के लिए अपना नया स्थान चुनें। आपकी प्रोफ़ाइल संभावित डेट को बताएगी कि आप ट्रैवल मोड में हैं।",
      "ट्रैवल मोड कैसे काम करता है?",
      "इसे अभी आज़माएं",
      "वेब पर Bumble को आज़माएं",
      "बिल्कुल। Bumble वेब ऐप सभी प्रमुख वेब ब्राउज़र पर PCs, Macs, और टैबलेट पर काम करती है।",
      "क्या मैं अपने PC या Mac पर Bumble का उपयोग कर सकता हूँ?",
      "हाँ, आप कर सकते हैं। आप Bumble वेब ऐप पर संदेश भेज सकते हैं,यहाँ तक कि वीडियो या ऑडियो कॉल भी कर सकते हैं।",
      "क्या वेब ऐप पर मेरी मैचों के साथ चैट हो सकती है?",
      "लॉग इन करने के लिए, 'साइन इन करें' को चुनें—या <a href={{sign_in_url}}>यहाँ</a> क्लिक करें। आप अपने Apple ID, Facebook या फ़ोन नंबर का उपयोग करके लॉग इन कर सकते हैं।",
      "वेब पर Bumble पर लॉग इन कैसे करना है?",
      "अपनी फ़ोन की स्क्रीन से ब्रेक चाहते हैं? तो आप Bumble वेब ऐप को आज़माएं—बड़ी स्क्रीन पर, वही Bumble अनुभव पाएं जिसे आप पसंद करते हैं।",
      "वेब पर Bumble का उपयोग करें",
      "वेब पर Bumble में साइन इन करना आसान है। बस हमारी वेबसाइट पर जाएँ, 'साइन इन' पर क्लिक करें, और अपने Apple ID, Facebook विवरण या फ़ोन नंबर के साथ अपना सामान्य लॉग-इन का तरीका चुनें। वाह! आप अंदर आ गए हैं, और आपकी चैट और कनेक्शन वहीं हैं जहाँ आपने उन्हें छोड़ा था।",
      "लॉग इन करें",
      "ब्राउजर से लॉगिन करें",
      "अपनी प्रोफ़ाइल को संपादित करें या उसमें परिवर्तन करें, और एडवांस्ड फ़िल्टर लागू करें।",
      "एक अच्छा मैच बनाने की संभावना बढ़ाने के लिए पुष्टि करें कि यह आप ही हैं।",
      "सुंदर नई फ़ोटोज़ शेयर करें या पुरानी हटा दें।",
      "अपना प्रोफ़ाइल अपडेट करें",
      "लोगों को बताएं कि आप वास्तव में आप ही हैं",
      "फ़ोटो जोड़ें या हटाएं",
      "Bumble वेब पर आप क्या कर सकते हैं",
      "आपको अपनी चैट और कनेक्शनों की सूची अपनी बाईं ओर मिलेगी, और संभावित नए मैच अपनी दाईं ओर मिलेंगे।{{__EOL__}}{{__EOL__}}अपनी बाईं और दाईं तीर कुंजियों का उपयोग करें, या प्रोफ़ाइल देखने के लिए अपनी स्क्रीन के दाईं ओर दिए गए प्रतीकों पर क्लिक करें, और लोगों को पसंद करें जैसे आप अपने फ़ोन पर करते हैं।",
      "मैं वेब पर Bumble को कैसे उपयोग करूं?",
      "यहां, दयालु होना सेक्सी है, आप परफेक्ट हैं, और महिलाएं हमेशा पहले आती हैं। हमेशा।",
      "'पहला कदम बढ़ाएं' का घर",
      "अच्छे संबंध एक सकारात्मक, उपयोगी ज़िंदगी के लिए महत्वपूर्ण हैं। Bumble को आपको कनेक्शन बनाने पर सशक्त महसूस करने में मदद करने के लिए बनाया गया है, चाहे आप डेटिंग कर रहे हों, दोस्तों को ढूँढ रहे हों, या अपना प्रोफेशनल नेटवर्क बढ़ा रहे हों।",
      "Bumble मायने क्यों रखता है",
      "हम किसी भी रिश्ते के सभी चरणों के दौरान सच्चाई, नेकी, समानता, आत्मविश्वास और सम्मान का समर्थन करते हैं। हम सिर्फ स्वागत नहीं करते, हम हर ओरीएन्टेशन का जश्न मनाते हैं—चाहे आप यहां प्यार ढूंढने आए हों या बस मनोरंजन ढूंढने आए हों।",
      "Bumble किसे महत्व देता है",
      "Bumble पर, महिलाएं पहले कदम बढ़ाकर या मैचों द्वारा जवाब देने के लिए Opening Move सेट करके माहौल तैयार करती हैं। हमारा मकसद पुराने जमाने की रूढ़िवादी सोच को बदलना और शुरू से ही समानता को प्रोत्साहित करना है।",
      "Bumble कैसे काम करता है",
      "हमारे बारे में",
      "अभी डाउनलोड करें",
      "यह ऐप पर बेहतर है।",
      "ऑनलाइन डेटिंग को वास्तव में आनंददायक बनाने के लिए डिज़ाइन किए गए फीचर",
      "फरवरी 12, 2024",
      "अपने रिश्ते में इमोशनल लगाव कैसे बढ़ाएं",
      "जनवरी 25, 2024",
      "जब आपने पहले कभी डेटिंग ऐप का उपयोग नहीं किया है तो Bumble का उपयोग कैसे करें",
      "फरवरी 5, 2024",
      "Deception Detector™",
      "सभी डेटिंग समाचार जो छापने योग्य हैं",
      "अगर आकार मायने रखता है, तो हमारी वेबसाइट पर जाएँ। यह हमारे ऐप जैसा ही है, लेकिन बड़ा है।",
      "वेब ऐप",
      "<b>स्नूज़ मोड</b> के साथ डेटिंग को होल्ड पर रखें। जब आप फिर से डेट करने के लिए तैयार हों, आपको पता ही है कि हम कहाँ हैं।",
      "एक ब्रेक लें",
      "अपना घर छोड़े बिना असल ज़िंदगी में करीब पहुंचने के लिए <b>वीडियो कॉल</b> और <b>ऑडियो चैट</b> का उपयोग करें।",
      "आवाज़ का जादू",
      "और देखें",
      "चैट शुरू करने के लिए अपने मैच को आमंत्रित करने के लिए <b>Opening Moves</b> चुनें, ताकि आपको ऐसा न करना पड़े।",
      "काम कम, डेट ज्यादा",
      "<b>ट्रैवल मोड</b> के साथ दुनिया में कहीं भी ड्यूटी-फ्री स्नैक और मैच ढूंढें।",
      "हमें ले चलें",
      "प्रोफ़ाइल वाइब जांच में पास हुई? <b>SuperSwipe</b> से उन्हें बताएं।",
      "उनका ध्यान आकर्षित करें",
      "उनकी कुछ <b>तारीफ</b> करें ताकि आप भीड़ से अलग दिखें।",
      "उन्हें शर्माने के लिए मजबूर करें",
      "अपने बॉस, रिश्तेदारों या पूर्व साथियों से अपनी प्रोफ़ाइल छिपाने के लिए <b>गुप्त मोड</b> का उपयोग करें।",
      "गुप्त हो जाएं",
      "जिस तरह से आप चाहें, उसी तरह डेट करें",
      "कुछ महीनों बाद, उसकी मुलाकात वहां उसकी ब्राइडमेडस से भी हुई",
      "हन्ना अपने वर्तमान पति से Bumble पर मिलीं।",
      "एक साथ अपना छोटा सा घर बनाने से पहले करीन और यू-लिआंग की मुलाकात Bumble पर हुई थी, जहां कैरेन की ट्रेवल की फ़ोटोज़ ने यू-लियांग को आकर्षित किया था।",
      "करीन और यू-लिआंग",
      "ब्रायन और केसी विदेश में एक साथ तैनात थे—लेकिन उनकी मुलाकात Bumble पर हुई",
      "ब्रायन और केसी",
      "यदि यह उनके लिए काम करता है, तो यह आपके लिए भी काम कर सकता है",
      "अगला कदम बढ़ाएं",
      "देखें कि नया क्या है",
      "आप बेहतर के हकदार हैं, इसलिए हमने आपके लिए अधिक डेट करने और तनाव कम करने के बेहतरीन तरीके डिज़ाइन किए हैं।",
      "अपने बार को वापस वहीं लाएँ जहाँ इसे होना चाहिए",
      "Bumble के बारे में",
      "हम एकमात्र ऐप हैं जो महिलाओं के अनुभवों को पहले स्थान पर रखकर डेटिंग को बेहतर बनाते हैं। क्योंकि जब चीजें महिलाओं के लिए बेहतर होती हैं, तो वे सभी के लिए बेहतर होती हैं।",
      "Make the first move™",
      "किसी ऐसे व्यक्ति को ढूंढें जिसे आप वास्तव में डेट करना चाहते हैं, फिर आगे बढ़ें और पहला कदम उठाएं। असल कनेक्शन बनाएं, वो करें जो आप दोनों को पसंद है और सबसे महत्वपूर्ण बात यह है कि मजे लें।",
      "Bumble Date पर किसी से मिलें",
      "ऐसे दोस्तों को ढूंढें जो कार्य क्षेत्र में आपकी मदद कर सकें। अपनी अगली नौकरी, गुरू या यहां तक कि एक नया करियर ढूंढने के लिए अन्य बिज़नस प्रोफेशनल्स से कनेक्ट करें।",
      "Bumble Bizz के साथ कैरियर को आगे बढ़ाएं",
      "अपने जैसे या अपने से विपरीत किसी व्यक्ति से मिलें। नए दोस्त बनाएं और साथ मिलकर करने के लिए नई चीजें खोजें - चाहे आप घर पर हों या बाहर।",
      "Bumble For Friends पर नये दोस्त बनाएं",
      "हम केवल डेटिंग के लिए नहीं हैं",
      "Bumble एक डेटिंग ऐप है जहां महिलाएं पहले कदम बढ़ाती हैं। यह वह ऐप है जिसने लोगों के डेट करने, सार्थक रिश्ते बनाने और नेटवर्क बनाने के तरीके को बदल दिया है। अब डाउनलोड करें।",
      "{{social_network}} पर Bumble",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "Premium+",
      "हमें खेद है, पर आप जिस पेज को खोज रहे हैं हम उसे खोज नहीं सके हैं।",
      "हमसे संपर्क करें",
      "साइन इन करें",
      "अरे रे! कुछ गड़बड़ हो गई है",
      "(opens in new window)",
      "अक्सर पूछे जाने वाले सवाल",
      "Cookie Policy",
      "Premium",
      "Bumble - The Blog",
      "नौकरी",
      "चैट",
      "शहर",
      "कुकी मैनेज करें",
      "Copyright © 2006 – present. Bumble. सभी अधिकार सुरक्षित हैं.",
      "डेटिंग कॉन्फ़िडेंस रिपोर्ट",
      "Apple और Apple का लोगो Apple Inc के ट्रेडमार्क हैं.{{__EOL__}}Google Play, Google LLC का ट्रेडमार्क है.",
      "डाउनलोड करें",
      "Facebook",
      "हमें फ़ॉलो करें",
      "सहायता केंद्र",
      "Instagram",
      "भाषा",
      "LinkedIn",
      "Bumble, Bumble Inc का हिस्सा है.",
      "निजता नीति",
      "सुरक्षा केंद्र",
      "साइटमैप",
      "मॉडर्न स्लेवरी ऐक्ट स्टेटमेंट",
      "नियम और शर्तें",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "Compliments": {
            "plural": [
                  "तारीफें",
                  "तारीफें"
            ],
            "singular": [
                  "तारीफ",
                  "तारीफ"
            ]
      },
      "day": {
            "plural": [
                  "दिनों",
                  "दिनों"
            ],
            "singular": [
                  "दिन",
                  "दिन"
            ]
      },
      "hour": {
            "plural": [
                  "घंटे",
                  "घंटे"
            ],
            "singular": [
                  "घंटे",
                  "घंटे"
            ]
      },
      "minute": {
            "plural": [
                  "मिनट",
                  "मिनट"
            ],
            "singular": [
                  "मिनट",
                  "मिनट"
            ]
      },
      "month": {
            "plural": [
                  "महीने",
                  "महीनों"
            ],
            "singular": [
                  "महीना",
                  "महीना"
            ]
      },
      "second": {
            "plural": [
                  "सेकंड",
                  "सेकंड"
            ],
            "singular": [
                  "सेकंड",
                  "सेकंड"
            ]
      },
      "superswipe": {
            "plural": [
                  "SuperSwipes",
                  "SuperSwipes"
            ],
            "singular": [
                  "SuperSwipe",
                  "SuperSwipe"
            ]
      },
      "year": {
            "plural": [
                  "साल",
                  "सालों"
            ],
            "singular": [
                  "साल",
                  "साल"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
