/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Go back",
      "Download on the App Store",
      "Get it on Google Play",
      "Carousel",
      "Slide {{number}}",
      "Next slide",
      "Previous slide",
      "Slide",
      "Slide {{number}} of {{total}}",
      "Do not sell or share my personal information",
      "Do not sell or share my personal information",
      "Send Compliments on Bumble to show you like their profile, and get their attention before you match. Try it now",
      "Compliments | How to Send a Compliment | Bumble",
      "A personalized page that highlights your most compatible people, refreshed daily.",
      "Discover | Find Compatible People | Bumble",
      "Date at your own pace and stay in control of who sees your Bumble profile with Incognito Mode. Learn more about using Incognito Mode on Bumble in our guide",
      "Incognito Mode | Hide Your Profile | Bumble",
      "After meeting at a Bumble IRL event, build on the chemistry by continuing the conversation in the app.",
      "Instant Match | Keep The Conversation Going On The App | Bumble",
      "Try a new way to start the conversation with Bumble's Opening Moves feature. Catch their eye, set the tone, and get to the good chats, faster",
      "Bumble Opening Moves | Bumble",
      "Need a little break or some time for yourself? Find out how to pause your Bumble account with Bumble’s Snooze Mode. It lets you date on your terms",
      "Snooze Mode | Pause Your Account | Bumble",
      "Just seen someone you really like on Bumble? Use a SuperSwipe to show them you’re serious. Tap the star on their profile to get started, or see more details here",
      "Bumble SuperSwipe | What’s a SuperSwipe? | Bumble",
      "Traveling for work or play? Travel Mode on Bumble can help you make fun new connections in a new place and date wherever you go. Discover Travel Mode here now",
      "Bumble’s Travel Mode | Date Wherever You Go | Bumble",
      "Want to know if the connection you’re feeling over messages will mean IRL chemistry? Try a video chat or voice call on Bumble now and get a good vibe check",
      "Video Chat | Make a Call | Bumble",
      "Experience the excitement of Bumble Web, the ultimate feature.",
      "Bumble Web | Bumble",
      "Terminate contracts here",
      "Bumble homepage",
      "Close menu",
      "Open menu",
      "Language selection",
      "Footer",
      "Accessibility at Bumble",
      "Apple and the Apple logo are trademarks of Apple Inc.",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Careers",
      "Events",
      "Google Play is a trademark of Google LLC.",
      "Guidelines",
      "Investors",
      "Manage Cookies",
      "Manage Cookies",
      "Modern Slavery Act Statement",
      "Notice At Collection",
      "Privacy Policy",
      "Seasonal Dating Guides",
      "Terms & Conditions",
      "The app",
      "Washington Consumer Health Data Privacy Policy",
      "About",
      "Ambassadors",
      "The Buzz",
      "Make new friends and find your community",
      "Network professionally and make career moves",
      "Find a relationship, something casual, or anything in-between",
      "Find out more",
      "Go global with Travel Mode",
      "Features",
      "Compliments",
      "Throw some Compliments their way so you stand out from the crowd.",
      "Discover",
      "A personalized page that highlights your most compatible people, refreshed daily.",
      "Incognito Mode",
      "Use Incognito Mode to hide your profile from your boss, relatives, or exes.",
      "Instant Match",
      "Started the conversation at a Bumble IRL event? Keep it going on the app.",
      "Opening Moves",
      "Choose Opening Moves to invite your match to start the chat, so you don’t have to.",
      "Snooze mode",
      "Put dating on hold with Snooze Mode. When you’re ready to date again, you know where we are.",
      "SuperSwipe",
      "Profile passed the vibe check? Let them know with a SuperSwipe.",
      "Travel mode",
      "Find your duty-free snack and match anywhere in the world with Travel Mode.",
      "Video chat",
      "Use video chat and audio calls to see if you’re ready to meet in person.",
      "Web app",
      "Get the Bumble experience straight from your browser.",
      "The Shop",
      "Main",
      "Skip to main content",
      "Bumble logo",
      "Want to get to know them? Use video chat on Bumble",
      "Simply tap the video icon in your chat to start a video call, all without sharing any personal contact information.",
      "How does video chat on Bumble work?",
      "No. All calls and chats on Bumble keep your personal info safe and secure. They won’t get your number or any other contact details until you tell them.",
      "Will my match see my phone number?",
      "Absolutely! If you're not quite ready for a face-to-face, voice calls lets you connect through audio. Just select the call icon instead.",
      "Can I just try an audio call?",
      "We love a good *message received* notification. But we know it’s hard to tell whether or not your chats are going to be as good in person. That’s where video and audio calls come in.",
      "Video Chat on Bumble",
      "<a href={{video_chat_url}}>Video chats and audio calls</a> can help you get to know each other and decide if you want to take things off the app. The best part? When you make a call via Bumble, you don’t have to exchange phone numbers—so your personal information stays safe until you’re ready to share.",
      "Check your chemistry with video chat",
      "Bridge the gap between online and offline dating, and decide if it's time to take the leap and meet up.",
      "Get to know them better (and faster). A face-to-face convo or chat on the phone can reveal a lot about someone.",
      "You’re in control. We’ll keep your contact details private, so you can choose when to share them.",
      "See if you’re ready to meet in person",
      "Try a quick vibe check",
      "Connect without giving out personal info",
      "Why you should use video chats and calls",
      "Ready to start a conversation? You can choose whether to start an audio call or a video call right from your chat in the Bumble app. To start a video call, tap the gray video camera icon in the top right corner. You’ll also see three dots next to the icon, click on this and you’ll see the option to audio call as well.",
      "How to video chat",
      "Ready to try Compliments?",
      "When you send a Compliment, the person receiving it will see it when you pop up in their queue.",
      "What happens when you Compliment someone on Bumble?",
      "If their Compliment caught your eye, then go ahead and match with them. You’ll see the Compliment in your chat, and the usual <a href={{make_the_first_move_url}}>Make The First Move</a> rules apply.",
      "How do I respond to a Compliment?",
      "You can send {{num}} [[Compliments:num]] for free. When you’re all out, you can buy more in the Bumble app in packs of up to 30. One of these will be used to send that Compliment (save the rest for when you see another profile you like). Don’t worry, they won’t expire!",
      "How many Compliments can I send?",
      "A kind word (or two) can go a long way. If you want to send a Compliment but find your thumbs hovering over the keyboard, try these for inspiration:",
      "See more examples",
      "“Anyone who appreciates [artist name], has good taste.”",
      "“Your smile’s contagious!”",
      "“Your bio made me laugh. Well played.”",
      "How to send better compliments on Bumble",
      "Want to make their day? Sounds like you’re ready to use Compliments.",
      "Compliments on Bumble",
      "Use Compliments on Bumble to let a potential match know you like their profile (and maybe even make them blush). You get Compliments as part of your {{service_level_lexeme_name}} membership. It’s also a way to send and receive messages before you’ve even matched.",
      "How the Compliments feature works",
      "When you get a Compliment, it will appear on the sender’s profile. You’ll also get a notification telling you that someone likes part of your profile.",
      "See your compliments",
      "You can send a Compliment by tapping on the yellow heart icon at the bottom of someone’s profile. Where you tap is important, because you’ll be complimenting that specific photo or part of their profile! When you click, a text box will open for you to write your message (it can be up to 150 characters).",
      "Send a compliment",
      "DOWNLOAD Bumble TO TRY DISCOVER NOW",
      "Every day from 9 a.m., you’ll see new people for you in Discover. You can connect with them over the things you have in common.",
      "When does Discover update?",
      "Your Discover page sorts potential matches into 3 main categories: similar interests, same dating goals, and communities in common. Profile recommendations are also shown, based on previous matches.",
      "How is my Discover page curated?",
      "A personalized page that highlights your most compatible people, refreshed daily",
      "FIND PEOPLE WHO MATCH YOUR VIBE WITH {{qr_code}} DISCOVER",
      "Discover shows you a selection of people we think you'll like, based on your similar interests, dating goals, and communities.",
      "Try Discover",
      "LOOKING FOR A FRESH WAY TO FIND COMMON GROUND?",
      "The Discover page is updated every day, to show you even more people you’d be compatible with—so you can spend less time searching for a match, and more time getting deep with people you know you’ll vibe with.",
      "RECOMMENDATIONS REFRESHED DAILY",
      "Discover’s recommendations are based on your profile and who you’ve matched with before, so you’ll see people who like what you like and want what you want—whether that’s vegan lobster rolls or a long-term relationship.",
      "PERSONALIZED{{__EOL__}}AROUND YOU",
      "Download Bumble",
      "Frequently Asked Questions",
      "Ready to browse Bumble privately?",
      "When you activate Incognito Mode, you won’t appear to other Bumble members unless you like their profile.",
      "How does Incognito Mode work?",
      "Without Incognito Mode, your profile is visible to other daters on Bumble when you fit their preferences, like age and location. When you activate Incognito Mode, you’ll only be seen by people once you’ve liked them.",
      "Who can see me on Bumble’s Incognito Mode?",
      "Using Incognito Mode is not the same as <a href={{blocking_on_bumble_url}}>blocking someone on Bumble</a>. With this feature, if you like someone (or if you’ve messaged them in the past), they’ll be able to see your profile.",
      "Is Incognito Mode like blocking someone?",
      "Control who sees your profile with Bumble ’s Incognito Mode.",
      "Incognito mode on Bumble",
      "If you want to take a step back from dating, or browse Bumble without other daters seeing your profile, you can switch to Incognito Mode. Turning on this feature means we’ll hide you from everyone—unless you like them first. It allows you to control what you share, and who sees you.",
      "Hide your profile with Incognito mode",
      "You decide who can see your photos and personal info.",
      "Want to include something personal on your profile? You can discreetly share what you want with your current matches.",
      "Browse freely without worrying about running into an ex, your work colleague, or anyone else who's not on your dating radar.",
      "Take complete control",
      "Choose what you share",
      "Avoid awkward encounters",
      "Why you’ll love Incognito mode",
      "Incognito Mode is available for {{service_level_lexeme_name}} and {{service_level_lexeme_name_two}} members. Once you’ve signed up, you can switch it on by going to your profile tab, tapping Settings in the top right corner, and switching to Incognito Mode.",
      "Upgrade to {{service_level_lexeme_name}}",
      "How to use Incognito mode on Bumble",
      "DOWNLOAD Bumble TO TRY INSTANT MATCH TODAY",
      "In the Bumble app, head to the Profile icon at the bottom of your screen. From there, you’ll find the QR code icon at the top right of the screen. Tap on that to access it.",
      "Where can I find my personal QR code?",
      "Nope! You'll need to be connected to the internet, either via mobile data or Wi-Fi, and use the current, live QR code for Instant Match to work.",
      "Can I screenshot my QR code to share?",
      "Nope! If one person scans the code, you’ll be matched automatically.",
      "Do both people need to scan the QR code?",
      "With Instant Match, you can automatically see what you and your new match have in common—giving you a glimpse of your compatibility right from the start.",
      "FIND COMMON GROUND, FASTER",
      "Started the conversation at a Bumble IRL event? Keep it going on the app.",
      "TURN YOUR MEET-CUTE INTO AN {{qr_code}} INSTANT MATCH",
      "Instant Match makes it easy to take your meet-cute to a “let’s keep talking” level without swapping numbers or social profiles. Just have them scan your QR code to match on Bumble.",
      "Try Instant Match",
      "MET SOMEONE AT A Bumble IRL EVENT?",
      "Instead of swapping social profiles or phone numbers, Instant Match allows you to check the vibe in the app first. That way, you can get to know each other better on your own terms, thanks to Bumble's suite of safety features.",
      "A SAFER WAY TO CONNECT",
      "After meeting at a Bumble IRL event, build on the chemistry by continuing the conversation In the app and connecting over shared interests—whether that’s a love for the perfect pour-over or propagating rare succulents.",
      "KEEP THE CONVERSATION GOING",
      "Try Opening Moves on Bumble",
      "Opening Moves is another way to chat with people you like. Once you've chosen a message to send, you can relax, wait for the responses, and reply to the ones that really stand out.",
      "How to use Bumble Opening Moves",
      "For non-binary and same-gender connections, either person can set and respond to an Opening Move.",
      "Can I use Bumble Opening Moves to date people of the same-gender?",
      "When someone answers your Opening Move, you’ll see their profile pop up in your chats. Women have 24 hours to respond before the match expires. From there, you can either reply to their answer or start a new conversation.",
      "How can I see if someone has responded to my Opening Moves?",
      "Opening Moves is our way of taking the pressure off when it comes to making the first move, with an easier, faster way to get to the good conversation.",
      "Opening Moves on Bumble",
      "We’re glad you asked. Opening Moves is a new, easier way to start a conversation. You can choose a suggested Opening Move like, “What's your go-to song if you’re passed the aux at a party?”, or write your own. If you have one set, your matches can reply to your questions when they see you pop up on their match screen.",
      "What’s an Opening Move?",
      "Take the pressure off",
      "Share what makes you, you",
      "Start better conversations",
      "Opening Moves can help…",
      "You can set an Opening Move to be sent out when you match with other daters. Just head to Opening Moves in your profile, choose one of the pre-written questions, or get creative and write your own. You can change your Opening Moves whenever you feel like it.",
      "Try Opening Moves",
      "How to use Bumble Opening Moves",
      "<a href={{see_more_url}}>See more</a>",
      "Want to take a break? Try Snooze Mode",
      "Yes, you can visit your settings or your queue and switch Snooze off whenever you like.",
      "Can I deactivate Snooze Mode early?",
      "Go to settings, tap Snooze, and choose how long you want to be away. Come back whenever you feel ready.",
      "How do I use Snooze Mode on my profile?",
      "Nope—while in Snooze Mode, all swipe-and-chat activity is paused. When you go to see new people, you’ll see a message telling you how long Snooze Mode will run for, with the option to deactivate it.",
      "Can I still swipe and chat in Snooze Mode?",
      "People you've already messaged will see your ‘Away’ status (if you choose to set one). Otherwise, they’ll see your previous messages in their Inbox as normal.",
      "What will current matches see when I’m on Snooze Mode?",
      "You have total control over your Bumble activity, and can step away from the platform whenever you want.",
      "You can keep existing connections up to date using your status update, so it doesn’t look like you’ve <a href={{ghosted_article_url}}>ghosted</a>. Plus, it’s all automated—so you don’t need to send any messages.",
      "Take a break from actively dating without losing your connections or chats.",
      "Date (or disconnect) on your own terms",
      "Keep your connections updated",
      "Pause your activity, but not your connections",
      "Why we love Snooze mode",
      "Open the Bumble app, visit your profile tab, and tap the settings cog in the top right corner. In your Settings, tap Snooze and choose how long you'll be away for. You can hide your profile for 24 hours, 72 hours, a week, or indefinitely.{{__EOL__}}{{__EOL__}}Remember, matches without active chats will expire while you're in Snooze Mode. If you decide to set a status, people you’re currently chatting with will be able to see that you’re away.",
      "How Snooze mode works on Bumble",
      "When life gets busy, Snooze Mode is here to help. Pause your Bumble account, and date on your own terms.",
      "Snooze mode on Bumble",
      "Snooze Mode is an easy way to pause your account when you want to take a breather from dating. You can still chat with your existing connections, it just hides your profile from other daters until you’re ready to start swiping again. You won’t lose your profile information or delete existing connections by activating Snooze Mode.",
      "Snooze your Bumble profile",
      "While you're in Snooze Mode, you can choose to let existing connections know you're taking a breather with a status update. Pick from 'I'm traveling', 'I’m focused on work', 'I’m on a digital detox', or 'I’m prioritizing myself'. Whatever your vibe, we’ve got you.",
      "Add an ‘Away’ status",
      "Activating Snooze Mode pauses your Bumble (or {{page_partner_name_bff}}) account. Your matches won’t know you’re on Snooze Mode unless you tell them. If you decide to wake up from your Snooze Mode sooner than the timeframe you initially chose, just return to Settings and tap “deactivate Snooze Mode.” It’s as easy as that.",
      "Pause your chats",
      "Try SuperSwipe on Bumble",
      "As many as you like! {{service_level_lexeme_name}} users get {{num}} [[superswipe:num]] every week, but you can top up your balance at any time. Free members can buy packs of SuperSwipes.",
      "How many SuperSwipes can I send on Bumble?",
      "After someone uses a SuperSwipe on your profile, you’ll get notified right away. You’ll also see a label above that person’s name on their profile, letting you know they like you.",
      "How do I know if I get a SuperSwipe from someone?",
      "Feeling serious about someone? There's a cool way to break the ice. Just send a SuperSwipe to let them know you're into them and get to the good chats, faster.",
      "SuperSwipe on Bumble",
      "Standing out from the crowd isn’t always easy, but SuperSwipe can help you make a great first impression.{{__EOL__}}{{__EOL__}}When you use a SuperSwipe, a notification will be sent to the person you’ve liked. You’ll then skip to the front of their line with a label on your profile. If they like you back, it’s time to start chatting.",
      "What’s a SuperSwipe?",
      "Be bold and stand out",
      "Start more chats",
      "Make more connections",
      "SuperSwipe can help you…",
      "If you’ve got <a href={{bumble_boost_url}}>Bumble {{service_level_lexeme_name}}</a> or Bumble {{service_level_lexeme_name_one}}, you get {{num}} free [[superswipe:num]] per week. Plus, all members can buy packs of up to {{num_two}} [[superswipe:num_two]] from the profile tab. If you have {{service_level_lexeme_name_three}}, you’ll get {{num_three}} [[superswipe:num_three]] per week{{__EOL__}}{{__EOL__}}Using SuperSwipe is simple—when you see someone you like, just tap the yellow star icon at the bottom of their profile.",
      "How to use SuperSwipe",
      "Apple and the Apple logo are trademarks of Apple Inc. Google Play is a trademark of Google LLC.",
      "Date on the go with Bumble’s Travel Mode",
      "<a href={{bumble_premium_url}}>{{service_level_lexeme_name}} members</a> can use Travel Mode. Switch it on and your queue will show you new faces in your chosen city.",
      "How do I use Travel Mode?",
      "Pretty much—we have a few safety restrictions that can change over time, but Travel Mode is available for most destinations.",
      "Can I use Travel Mode anywhere?",
      "Travel Mode will show your location as the center of your chosen city, so new connections in the area can find you. They’ll see a note on your profile that you’re in Travel Mode, so they know you’re visiting, or planning to visit.",
      "Packing your bags for a holiday or heading to a new city for work? Switch on Bumble’s Travel Mode to take dating on the road with you.",
      "Travel Mode on Bumble",
      "Travel Mode is your ultimate tool for making connections while you’re away. Set your location to anywhere in the world, and your profile will show up as being in the city you choose. It means you can tap into the dating scene wherever your adventures take you.",
      "What’s Travel Mode?",
      "No one knows a place better than the locals. Travel Mode can help you connect with the people who’ll make your trip extra special.",
      "Set your location to the city up to seven days before you go, so you can plan dates and meet matches ahead of time.",
      "Think beyond just beach trips—you might be home for the holidays, traveling for work, or exploring a new city. Find romance wherever you land.",
      "Find the best local date spots",
      "Have an adventure",
      "Make lasting connections",
      "Reasons to love Travel Mode",
      "Travel Mode is available to <a href={{bumble_premium_url}}>{{service_level_lexeme_name}} and {{service_level_lexeme_name_two}} </a> members on Bumble. Tap on the Settings cog in the top right of your profile and scroll until you see Travel Mode. Choose your new location to start seeing people in that area. Your profile will let potential dates know you’re in Travel Mode.",
      "How does Travel Mode work?",
      "Try it now",
      "Try Bumble on the web",
      "Absolutely. The Bumble web app works on PCs, Macs, and tablets on all major web browsers.",
      "Can I use Bumble on my PC or Mac?",
      "Yes, you can. You can send messages or even make a video or audio call on the Bumble web app.",
      "Can I chat with matches on the web app?",
      "To log in, choose 'Sign In'—or click <a href={{sign_in_url}}>here</a>. You can log in using your Apple ID, Facebook, or phone number.",
      "How do I log in to Bumble on the web?",
      "Need a break from your phone screen? Try the Bumble web app—the same Bumble experience you love, on the big(ger) screen.",
      "Use Bumble on the web",
      "Signing into Bumble on the web is simple. Just visit our website, click 'Sign In', and choose your usual log-in method with your Apple ID, Facebook details, or phone number. Voila! You're in, and your chats and connections are right where you left them.",
      "Log In",
      "Log in via your browser",
      "Edit or make changes to your profile, and apply Advanced Filters.",
      "Confirm it’s you to increase the likelihood of you making a great match.",
      "Share cute new snaps or delete older ones.",
      "Update your profile",
      "Let people know you’re really you",
      "Add or remove photos",
      "What you can do on Bumble on the web",
      "You’ll find your chats and list of connections on your left, and potential new matches on your right.{{__EOL__}}{{__EOL__}}Use your left and right arrow keys, or click the symbols on the right-hand side of your screen to check out profiles, and like people as you would on your phone.",
      "How do I use Bumble on the web?",
      "Here, kindness is sexy, you being you is perfect, and women come first. Always.",
      "The Home Of Make The First Move™",
      "Healthy relationships are central to living a positive, productive life. Bumble is designed to help you feel empowered while you make those connections, whether you’re dating, looking for friends, or growing your professional network.",
      "Why Bumble Matters",
      "We champion integrity, kindness, equality, confidence, and respect during all stages of any relationship. We don’t just welcome, we celebrate every orientation—whether you’re here to find love or simply find fun.",
      "What Bumble Values",
      "On Bumble, women set the tone by making the first move or by setting an Opening Move for matches to reply to. Shifting old-fashioned power dynamics and encouraging equality from the start.",
      "How Bumble Works",
      "About Us",
      "Download now",
      "It’s better on the app.",
      "Features designed to make online dating actually, you know, enjoyable",
      "February 12, 2024",
      "How to increase your emotional intimacy in your relationship",
      "Jan 25, 2024",
      "How to use Bumble when you have never used a dating app before",
      "February 5, 2024",
      "Deception Detector™",
      "All the dating news that’s fit to print",
      "If size matters, head to our website. It’s just like our app, but bigger.",
      "Web app",
      "Put dating on hold with <b>Snooze Mode</b>. When you’re ready to date again, you know where we are.",
      "Take a break",
      "Use <b>video calls</b> and <b>audio chats</b> to get as close to IRL without leaving your house.",
      "Sound it out",
      "View more",
      "Choose <b>Opening Moves</b> to invite your match to start the chat, so you don’t have to.",
      "Do less, date more",
      "Find your duty-free snack and match anywhere in the world with <b>Travel Mode</b>.",
      "Take us away",
      "Profile passed the vibe check? Let them know with a <b>SuperSwipe</b>.",
      "Get their attention",
      "Throw some <b>Compliments</b> their way so you stand out from the crowd.",
      "Make them blush",
      "Use <b>Incognito Mode</b> to hide your profile from your boss, relatives, or exes.",
      "Go undercover",
      "Date the way you want to",
      "A Few Months Later, She Met One of Her Bridesmaids There, Too",
      "Hannah Met Her Now-Husband on Bumble.",
      "Before building their tiny house together Carène and You-Liang met on Bumble, where You-Liang was drawn in by Carène’s travel pics.",
      "Carène and You-Liang",
      "Brian and Kaci Were Stationed Overseas Together—But They Met on Bumble",
      "Brian and Kaci",
      "If it worked for them, it could work for you",
      "Make the next move",
      "See what’s new",
      "You deserve better, so we’ve designed great ways for you to date more and stress less.",
      "Bring your bar back up to where it belongs",
      "About Bumble",
      "We’re the only app that makes dating better by putting women’s experiences first. Because when things are better for women, they’re better for everyone.",
      "Make the first move™",
      "Find someone you actually want to date, then go ahead and Make the First Move. Make genuine connections, vibe over what you both love, and most importantly, have fun.",
      "Meet someone with Bumble Date",
      "Find friends with work benefits. Connect with other business professionals to find your next job, a mentor or even a whole new career.",
      "Make career moves with Bumble Bizz",
      "Meet someone like you. Or unlike you. Make new friends and find new things to do together—whether you’re home or away.",
      "Find new friends on Bumble For Friends",
      "We’re not just for dating",
      "Bumble is the dating app where women Make the First Move. It’s the app that changed the way people date, create meaningful relationships, and network. Download now.",
      "Bumble on {{social_network}}",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "Premium+",
      "We’re sorry, but we can’t find the page you were looking for.",
      "Contact us",
      "Sign In",
      "Oops, something went wrong",
      "(opens in new window)",
      "FAQ",
      "Cookie Policy",
      "Premium",
      "Bumble - The Blog",
      "Careers",
      "Chat",
      "Cities",
      "Manage cookies",
      "Copyright © 2006 – present. Bumble. All rights reserved.",
      "Dating Confidence Report",
      "Apple and the Apple logo are trademarks of Apple Inc.{{__EOL__}}Google Play is a trademark of Google LLC.",
      "Download",
      "Facebook",
      "Follow us",
      "Help Center",
      "Instagram",
      "Language",
      "LinkedIn",
      "Bumble is part of Bumble Inc.",
      "Privacy policy",
      "Safety Center",
      "Sitemap",
      "Modern Slavery Act statement",
      "Terms and conditions",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "Compliments": {
            "plural": [
                  "Compliments"
            ],
            "singular": [
                  "Compliment"
            ]
      },
      "day": {
            "plural": [
                  "days"
            ],
            "singular": [
                  "day"
            ]
      },
      "hour": {
            "plural": [
                  "hours"
            ],
            "singular": [
                  "hour"
            ]
      },
      "minute": {
            "plural": [
                  "minutes"
            ],
            "singular": [
                  "minute"
            ]
      },
      "month": {
            "plural": [
                  "months"
            ],
            "singular": [
                  "month"
            ]
      },
      "second": {
            "plural": [
                  "seconds"
            ],
            "singular": [
                  "second"
            ]
      },
      "superswipe": {
            "plural": [
                  "SuperSwipes"
            ],
            "singular": [
                  "SuperSwipe"
            ]
      },
      "year": {
            "plural": [
                  "years"
            ],
            "singular": [
                  "year"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
