/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "돌아가기",
      "App Store에서 다운받기",
      "Google Play에서 다운받기",
      "캐러셀",
      "슬라이드 {{number}}",
      "다음 슬라이드",
      "이전 슬라이드",
      "슬라이드",
      "슬라이드 {{total}} 중 {{number}}",
      "내 개인정보를 판매하거나 공유하지 않음",
      "내 개인정보를 판매하거나 공유하지 않음",
      "Bumble 에서 상대방의 프로필의 어떤 점이 마음에 드는지 알려주기위해 칭찬보내기를 사용해보세요. 짝이 되기 전에 상대방의 주목을 끌 수 있어요. 지금 사용해보세요",
      "칭찬 보내기 | 칭찬 보내기를 사용하는 방법 | Bumble",
      "하루에 한 번씩 업데이트되는 맞춤형 페이지, 회원님과 가장 잘 맞을 것 같은 사람들을 하이라이트합니다.",
      "발견 | 나랑 잘 맞는 사람들 찾기 | Bumble",
      "스스로의 속도에 따라 데이트를 즐기고 투명 회원 모드로 회원님의 Bumble 프로필을 볼 수 있는 사람들을 관리하세요. 저희의 가이드에서 Bumble 투명 회원 모드를 사용하는 방법에 대해서 알아보세요",
      "투명 회원 모드 | 회원님의 프로필을 숨기세요 | Bumble",
      "Bumble 의 IRL 이벤트에서 만난 후, 앱에서 대화를 이어가며 관계를 발전시키세요.",
      "바로 짝되기 | 앱에서 대화를 계속 이어가세요 | Bumble",
      "Bumble 의 Opening Moves 기능으로 대화를 시작하는 새로운 방법을 시도해 보세요. 사람들의 시선을 끌고, 분위기를 정하고, 더 빨리 좋은 대화를 시작하세요",
      "Bumble 에서의 Opening Moves | Bumble",
      "휴식이 필요하거나 스스로를 위한 시간이 필요하세요? Bumble 의 스누즈 모드를 통해 회원님의 Bumble 계정을 일시 정지하는 방법을 알아보세요. 내가 원하는 속도로 데이트할 수 있어요",
      "스누즈 모드 | 회원님의 계정을 일시정지하세요 | Bumble",
      "Bumble 에서 마음에 드는 사람을 보셨다구요? SuperSwipe로 진심을 표현하고 상대방의 눈길을 끌어보세요. 상대방 프로필의 별을 탭하거나, 여기에서 자세한 정보를 확인하세요",
      "Bumble SuperSwipe | SuperSwipe가 무엇인가요? | Bumble",
      "출장 또는 휴가를 위해 여행하십니까? Bumble 의 여행 모드를 통해 새로운 장소에서 재미있는 새로운 연결을 만들 수 있습니다. 여행 모드를 여기에서 지금 바로 확인해보세요",
      "Bumble 의 여행 모드 | 어디서든 데이트를 찾으세요 | Bumble",
      "메시지로 느끼는 분위기가 실제로 만났을 때도 같을지 알고 싶으세요? Bumble 에서 이제 영상 채팅이나 음성 통화를 시도해서 좋은 분위기를 확인해보세요",
      "영상 채팅 | 전화를 시작하세요 | Bumble",
      "Bumble 웹에서 최고의 기능을 경험하세요.",
      "Bumble 웹 | Bumble",
      "이곳에서 계약을 종료하세요",
      "Bumble 홈페이지",
      "메뉴 닫기",
      "메뉴 열기",
      "언어 옵션",
      "꼬리말",
      "Bumble 에서의 접근성",
      "Apple 및 Apple 로고는 Apple Inc.의 등록 상표입니다.",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "인재채용",
      "이벤트",
      "Google Play는 Google LLC의 상표입니다.",
      "가이드라인",
      "투자자",
      "쿠키 관리",
      "쿠키 관리",
      "현대판 노예 방지 성명",
      "수집 시 통지",
      "개인정보 취급방침",
      "시즌 별 데이트 가이드",
      "이용약관",
      "앱",
      "Washington 소비자 건강 데이터 개인 정보 보호 정책",
      "소개",
      "앰배서더",
      "블로그",
      "커뮤니티를 찾고 새로운 친구를 만드세요",
      "전문적으로 네트워크를 구축하고 경력을 쌓으세요",
      "진지한 관계, 캐주얼한 관계 또는 그 사이에 있는 것을 찾으세요",
      "더 알아보기",
      "트래블 모드로{{__EOL__}}전세계와 연결되세요",
      "기능들",
      "Compliments™",
      "Compliments를 보내서 사람들 속에서 돋보이세요.",
      "발견",
      "하루에 한 번씩 업데이트되는 맞춤형 페이지, 회원님과 가장 잘 맞을 것 같은 사람들을 하이라이트합니다.",
      "투명 회원 모드",
      "투명 회원 모드를 사용해서 전 연인, 친척, 직장 상사로부터 프로필을 숨기세요.",
      "바로 짝되기",
      "Bumble IRL 이벤트에서 대화를 시작하셨나요? 앱에서 계속 이어가세요.",
      "Opening Moves",
      "Opening Moves를 선택하면 내가 먼저 대화를 시작할 필요 없이 짝들이 인사를 건네도록 초대할 수 있어요.",
      "스누즈 모드",
      "스누즈 모드로 데이팅을 일시정지하세요. 다시 데이트할 준비가 되면, 언제든지 돌아오세요.",
      "SuperSwipe",
      "상대방의 프로필 분위기가 합격이라구요? SuperSwipe로 마음을 전하세요.",
      "트래블 모드",
      "트래블 모드로 전 세계 어느 곳에서 짝이 될 수 있어요.",
      "영상 채팅",
      "영상 채팅과 음성 통화를 사용해서 실제로 만날 준비가 됐는지 확인해보세요.",
      "웹 앱",
      "Bumble 경험을 브라우저에서 직접 즐기세요.",
      "상점",
      "메인",
      "메인 페이지로 건너뛰기",
      "Bumble 로고",
      "상대방을 좀 더 알아가고 싶으세요? Bumble 에서 영상 통화를 사용해보세요",
      "간단하게 회원님의 채팅에서 비디오 아이콘을 탭해서 영상 통화를 시작하세요. 어떠한 개인 연락처 정보도 공유하지 않아도 됩니다.",
      "Bumble 에서 영상 채팅은 어떻게 사용할 수 있나요?",
      "아니요. Bumble 에서의 모든 통화와 채팅은 회원님의 개인 정보를 안전하게 확실히 보호합니다. 회원님의 짝들은 회원님의 전화번호나 다른 어떠한 연락처 정보도 회원님께서 직접 말하기 전까지는 알아볼 수 없습니다.",
      "내 핸드폰 번호를 나와 짝이 된 사람들이 볼 수 있나요?",
      "물론이죠! 회원님께서 얼굴을 마주보고 짝과 대화하는 것이 아직 불편하다면, 음성 통화를 통해 연결될 수 있습니다. 간단하게 전화 아이콘을 선택하시면 됩니다.",
      "음성 통화를 사용할 수 있나요?",
      "우리 모두는 좋은 메시지를 받을 때 뜨는 알림을 사랑하죠. 하지만 이 대화가 실제로 만났을 때도 즐겁게 이어질지는 아무도 모릅니다. 이때 영상 통화와 음성 통화가 도움이 될 수 있어요.",
      "Bumble 에서의 화상 채팅",
      "<a href={{video_chat_url}}>영상 채팅과 음성 통화</a> 를 사용하면 서로를 좀 더 알아가는 데에 도움이 되며 앱을 떠나서 계속 연락을 주고받을지 결정할 수 있어요. 가장 좋은 점이요? Bumble을 통해 통화를 하면 전화 번호를 교환하지 않아도 계속 연락할 수 있어요. 회원님께서 개인 정보를 공유할 준비가 될 때까지 안전하게 지킬 수 있죠.",
      "영상 채팅으로 쿵짝이 잘 맞는지 확인하세요",
      "온라인에서 알아가는 상대방과 오프라인에서의 실제 상대방에는 차이가 있을 수 있죠, 이 차이를 먼저 파악하고 실제로 만나도 괜찮을지 결정해보세요.",
      "짝들을 더 잘 (그리고 더 빠르게) 알아보세요. 얼굴을 보며 하는 대화나 전화로 하는 대화는 사람들에 대해 많은 것을 알려주죠.",
      "회원님께서 결정하게 됩니다. 저희는 회원님의 연락처 정보를 비밀로 유지할 테니, 회원님께서 언제 연락처를 공유할지 선택할 수 있습니다.",
      "실제로 만날 준비가 되셨는지 확인해 보세요",
      "빠르게 분위기를 체크하세요",
      "개인정보를 알릴 필요 없이 연결되세요",
      "회원님께서 영상 채팅과 음성 통화를 사용해야 하는 이유",
      "대화를 시작할 준비가 되셨나요? Bumble 앱의 회원님의 채팅에서 음성 통화나 영상 통화를 바로 시작할 수 있습니다. 영상 통화를 시작하려면 오른쪽 상단 모서리에 있는 회색 비디오 카메라 아이콘을 탭하세요. 그 아이콘 옆에서 세 개의 점도 볼 수 있는데, 이를 클릭해서 음성 통화 옵션도 볼 수 있습니다.",
      "영상 채팅하는 방법",
      "Compliments를 사용할 준비가{{__EOL__}}되셨나요?",
      "Compliments를 보내시면, 받는 사람은 회원님의 프로필이 회원들 사이에서 나타날 때 이를 볼 수 있습니다.",
      "Bumble에서 누군가에게 Compliment를 보내면 어떻게 되나요?",
      "상대방이 보낸 Compliment가 회원님의 눈길을 끈다면, 바로 짝이 될 수 있어요. 회원님의 채팅에서 Compliment를 볼 수 있으며, <a href={{make_the_first_move_url}}>첫 인사를 건네는 방법</a> 의 일반적인 규칙이 적용됩니다",
      "Compliment에 어떻게 답장을 보낼 수 있나요?",
      "{{num}} 개의 [[Compliments:num]] 를 무료로 보낼 수 있습니다. 모두 소진되면, Bumble 앱에서 최대 30개까지의 패키지를 구매할 수 있습니다. 이 중 한 개는 해당 Compliment를 보내는 데 사용됩니다 (다른 프로필도 마음에 들 때를 위해서 나머지는 남겨두세요). 걱정마세요, 만료되는 기간은 없어요!",
      "Compliments를 몇 개까지 보낼 수 있나요?",
      "친절한 한 마디는 (아님 두 마디) 천 냥 빚을 갚는다고 하죠. Compliment를 보내고 싶지만 손가락이 키보드 위에서 갈 곳을 잃었다면, 다음 예시에서 영감을 얻으세요:",
      "더 많은 예시 보기",
      "\"[아티스트 이름], 이 사람을 좋아하는 사람들은 취향이 뛰어나죠.“",
      "“회원님의 미소는 전염성이 있는 것 같아요, 저도 미소짓게 되요!“",
      "“회원님의 자기소개 때문에 현웃이 터졌어요. 한 개그 하시네요.“",
      "Bumble 에서 더 나은 칭찬을 보내는 방법",
      "상대방의 하루를 특별하게 만들어볼까요? Compliments를 사용해 볼 준비가 되신 것 같네요.",
      "Bumble 에서의 Compliments",
      "Bumble 에서 Compliments를 사용하면 짝이 될지도 모르는 상대방에게 프로필이 마음에 든다고 알려줄 수 있어요 (상대방이 쑥쓰러워 할 수도 있지만요). {{service_level_lexeme_name}} 서비스의 일부로 Compliments가 포함됩니다. 이 기능은 짝이 되기도 전에 메시지를 보내고 받을 수 있는 방법이기도 해요.",
      "Compliments 기능{{__EOL__}}사용 방법",
      "Compliment를 받게 되면 회원님의 보낸 사람의 프로필에 나타나게 됩니다. 또한 회원님의 프로필 중 특정 부분을 좋아하는 사람이 있다는 알림도 받게 됩니다.",
      "회원님의 칭찬{{__EOL__}}확인하기",
      "다른 회원의 프로필 하단에 있는 노란색 하트 아이콘을 탭해서 Compliment를 보낼 수 있습니다. 탭을 한 프로필의 일부 부분이나 탭을 한 사진에 대해서 칭찬하게 되므로 어디를 탭할지 생각해보세요! 클릭하면 메시지를 입력할 수 있는 텍스트 상자가 열립니다. (최대 150자까지 쓸 수 있어요.)",
      "칭찬을 보내세요",
      "Bumble 을 다운{{__EOL__}}받고 지금{{__EOL__}}발견을{{__EOL__}}이용해보세요",
      "매일 오전 9시부터 새로운 사람들이 발견 페이지에 나타납니다. 이 사람들과 공통된 점들로 연결될 수 있습니다.",
      "발견 페이지는 언제 업데이트되나요?",
      "회원님의 발견 페이지에서는 잠재적인 짝들을 유사한 관심사, 동일한 연애 목표, 그리고 공통된 커뮤니티 3 개의 주 카테고리로 분류해서 볼 수 있습니다. 프로필 추천은 기존의 짝들을 기반으로 보여집니다.",
      "내 발견 페이지는 어떻게 구성되나요?",
      "하루에 한 번씩 업데이트 되는 회원님과 가장 잘 맞는 사람들을 하이라이트 해 드리는 맞춤형 페이지예요",
      "{{qr_code}} 발견에서 회원님의 분위기와{{__EOL__}}맞는 사람들을 찾으세요",
      "발견에서 보여드리는 사람들은 유사한 관심사, 연애 목표 및 공동체를 기반으로 저희가 선택한 회원님께서 좋아할 것 같은 사람들입니다.",
      "발견 이용해보기",
      "공통점들을 찾을 수 있는{{__EOL__}}새로운 방법이 필요하세요?",
      "발견 페이지는 매일 업데이트되며, 잘 어울릴 것 같은 사람들을 더 많이 보여줍니다—그래서 짝을 찾는 시간은 줄이고, 잘 맞는 사람들과 보다 깊이 연결되는 데에 더 많은 시간을 보낼 수 있습니다.",
      "매일 새로운 추천들",
      "발견 페이지에 추천된 사람들은 회원님께서 이전에 짝이 된 사람들에 기반을 두므로 회원님께서 좋아하는 것이나 원하는 것들이 같은 사람들을 볼 수 있습니다—비건 랍스터 롤이나 장기 연애, 뭐든지요.",
      "회원님을 위한{{__EOL__}}맞춤화",
      "Bumble 다운로드",
      "자주 하는 질문",
      "Bumble 을 비공개로 둘러볼 준비가 되셨나요?",
      "투명 회원 모드를 활성화하면, 회원님께서 찜한 프로필을 제외하고 다른 Bumble 회원에게는 회원님의 프로필이 나타나지 않습니다.",
      "투명 회원 모드는 어떻게 사용하나요?",
      "투명 회원 모드가 켜져 있지 않으면, Bumble 에서의 다른 회원들의 나이나 위치 같은 선호 사항에 회원님께서 해당되는 경우 회원님의 프로필이 보여집니다. 투명 회원 모드를 활성화하면, 회원님께서 찜한 회원들만 회원님의 프로필을 볼 수 있습니다.",
      "Bumble 의 투명 회원 모드에서 누가 나를 볼 수 있나요?",
      "투명 회원 모드를 사용하는 것과 <a href={{blocking_on_bumble_url}}>Bumble에서 누군가를 차단하는 것</a> 은 다릅니다. 이 기능을 사용하면, 회원님께서 누군가를 찜하면 (또는 과거에 메시지를 보낸 적이 있는 경우) 해당 회원은 회원님의 프로필을 볼 수 있습니다.",
      "투명 회원 모드가 누군가를 차단하는 것과 비슷한 건가요?",
      "Bumble 의 투명 회원 모드로 회원님의 프로필을 볼 수 있는 사람들을 직접 선택하세요.",
      "Bumble 에서의 투명 회원 모드",
      "데이트에서 한 걸음 물러나고 싶거나, Bumble 을 둘러보면서 다른 회원들이 회원님의 프로필을 볼 수 없도록 하고 싶다면 투명 회원 모드로 전환할 수 있습니다. 이 기능을 활성화하면 모든 사람들에게서 회원님의 프로필이 숨겨집니다 - 회원님께서 먼저 찜한 사람들에게만 회원님의 프로필이 나타납니다. 이 기능을 통해 누구에게 보여지고 무엇을 공유할지를 회원님께서 직접 통제할 수 있습니다.",
      "투명 회원 모드로{{__EOL__}}회원님의 프로필을 숨기세요",
      "회원님의 사진과 개인 정보를 볼 수 있는 회원을 직접 선택하세요.",
      "프로필에 개인적인 내용을 포함하고 싶으세요? 현재 짝이 된 사람들과 조용히 원하는 내용을 공유할 수 있어요.",
      "옛 연인, 직장 동료, 또는 회원님의 연애 대상이 아닌 사람들과 마주칠 걱정 없이 자유롭게 둘러보세요.",
      "회원님께서 완벽하게 통제하세요",
      "무엇을 공유할지 선택하세요",
      "어색한 마주침을 피하세요",
      "회원님께서 투명 회원 모드를 사랑하게 될 이유",
      "투명 회원 모드는 {{service_level_lexeme_name}} 및 {{service_level_lexeme_name_two}} 회원들이 사용할 수 있습니다. 가입을 완료한 후, 회원님의 프로필 탭으로 이동하여 상단 오른쪽 모서리에 있는 설정으로 이동한 다음, 투명 회원 모드를 활성화할 수 있습니다.",
      "{{service_level_lexeme_name}} 서비스로 업그레이드하세요",
      "Bumble 에서의 투명 회원 모드 사용 방법",
      "Bumble 을 지금{{__EOL__}}다운받고 오늘{{__EOL__}}바로 짝되기를{{__EOL__}}이용해보세요",
      "Bumble 앱에서 화면 하단에 있는 프로필 아이콘으로 향하세요. 해당 화면 오른쪽 상단에서 QR 코드를 찾으실 수 있어요. 탭해서 사용하세요.",
      "나의 QR 코드는 어디서 찾을 수 있나요?",
      "Nope! You'll need to be connected to the internet, either via mobile data or Wi-Fi, and use the current, live QR code for Instant Match to work.",
      "스크린샷을 찍어서 나의 QR 코드를 공유할 수 있나요?",
      "아니요! 한 사람이 코드를 스캔하면 자동으로 짝이 됩니다.",
      "두 사람 모두 QR 코드를 스캔해야하나요?",
      "바로 짝되기를 통해 자동으로 회원님과 회원님의 새로운 짝의 공통점을 볼 수 있어요—시작부터 바로 서로 잘 맞는지 알 수 있어요.",
      "공통점을{{__EOL__}}찾아보세요,{{__EOL__}}빠르게요",
      "Bumble IRL 이벤트에서 대화를 시작하셨나요? 앱에서 계속 이어가세요.",
      "이미 만난 귀여운 사람과 {{qr_code}} 바로 짝되기로 연결되세요",
      "바로 짝되기는 전화번호나 소셜 프로필을 교환하지 않고 귀여운 상대방에게 \"우리 계속 대화를 이어가요\"라고 말할 수 있는 쉬운 방법입니다. 상대방에게 회원님의 QR 코드를 스캔하도록 해서 Bumble 에서 짝이 될 수 있습니다.",
      "바로 짝되기를 시도해보세요",
      "Bumble IRL 이벤트에서{{__EOL__}}누군가를 만나셨다구요?",
      "소셜 프로필이나 전화번호를 교환하는 대신에, 바로 짝되기를 사용하면 앱에서 분위기를 확인할 수 있어요. 그렇게 하면, Bumble 의 안전한 기능들 덕에 서로를 천천히 알아갈 수 있어요.",
      "더 안전하게{{__EOL__}}연결되는{{__EOL__}}방법",
      "Bumble 의 IRL 이벤트에서 만난 후, 앱에서 대화를 이어가서 관계를 계속 발전시키고 공통의 관심사를 통해 연결되세요—완벽한 술안주에 대한 애정이든, 찾기 힘든 입맛 취향에 대해서 토론하는 대화든, 뭐든지요.",
      "대화를 계속 이어가세요",
      "Bumble 에서 Opening Moves를 사용해보세요",
      "Opening Moves는 마음에 드는 사람들과 대화를 이어가는 방법들 중 하나입니다. 보낼 메시지를 선택하고 나면, 편안히 상대방의 답변을 기다린 뒤, 특별히 회원님의 눈길을 끄는 대답에 답장을 보낼 수 있어요.",
      "Bumble 에서 Opening Moves를 사용하는 방법",
      "논바이너리이거나 동성끼리의 연결에서는, 두 분 모두가 Opening Move를 설정하고 대답할 수 있습니다.",
      "동성의 사람들을 데이트할 때 Bumble 의 Opening Moves를 사용할 수 있나요?",
      "누군가가 회원님의 Opening Move에 대답하면, 회원님의 채팅에서 해당 회원의 프로필을 볼 수 있습니다. 여성 회원은 짝이 만료되기 전에 24시간 내에 답장을 보내야합니다. 여기서 상대방의 답변에 응답하거나 새로운 대화를 시작할 수 있습니다.",
      "누군가가 나의 Opening Moves에 답장을 했는지 어떻게 확인할 수 있나요?",
      "Opening Moves는 첫 인사를 건네는 것에 대한 부담감을 덜어드리고, 좀 더 쉽고 빠르게 더욱 즐거운 대화로 여러분을 안내하기 위한 저희의 방식입니다.",
      "Bumble 에서의 Opening Moves",
      "물어봐주셔서 감사해요. Opening Moves는 대화를 시작하는 새롭고 쉬운 방법입니다. 회원님께서는 “파티에서 디제이를 맡았을 때 회원님이 자주 플레이하는 노래는?“과 같이 저희가 추천하는 Opening Move 중 하나를 선택하거나 회원님만의 질문을 적을 수 있습니다. 설정해두시면, 회원님의 짝들은 짝 목록에서 회원님을 확인하고 질문에 답변을 보낼 수 있습니다.",
      "Opening Move가 뭐예요?",
      "압박을 덜어보세요",
      "회원님을 나답게 만드는 것을 공유하세요",
      "더 나은 대화를 시작하세요",
      "Opening Moves가{{__EOL__}}도와드릴게요…",
      "다른 회원들과 짝이 될 때 Opening Move가 전송되도록 설정할 수 있어요. 간단히 회원님의 프로필에서 Opening Moves로 이동해서 미리 작성된 질문들 중 하나를 선택하거나, 창의적으로 나만의 질문을 작성해보세요. Opening Moves는 언제든지 변경할 수 있습니다.",
      "Opening Moves를 이용해보세요",
      "Bumble Opening Moves 사용 방법",
      "<a href={{see_more_url}}>더 보기</a>",
      "휴식이 필요하신가요? 스누즈 모드를 사용해보세요",
      "네, 언제든지 설정이나 회원들 탭에서 스누즈를 끌 수 있어요.",
      "스누즈 모드를 조금 더 일찍 비활성화할 수 있나요?",
      "설정으로 이동한 다음 스누즈를 탭하고 오프라인 상태로 유지하고 싶은 기간을 선택하세요. 돌아올 준비가 되면 언제든지 돌아오세요.",
      "나의 프로필에서 스누즈 모드를 어떻게 사용하나요?",
      "아니요, 스누즈 모드에서는 모든 스와이프 및 채팅 활동이 일시 중단됩니다. 회원들 탭에 가면 스누즈 모드가 얼마나 오래 유지될지 알려주는 메시지와 함께 비활성화할 수 있는 옵션이 표시됩니다.",
      "스누즈 모드에서도 여전히 스와이프와 대화를 할 수 있나요?",
      "이미 메시지를 주고받은 사람들은 '오프라인' 상태(만약 설정하셨다면)를 볼 수 있습니다. 설정하지 않으셨다면, 상대방은 대화 목록에서 이전 메시지 내용을 평소처럼 보게 됩니다.",
      "스누즈 모드를 활성화하면 현재 짝들이 볼 수 있나요?",
      "여러분의 Bumble 활동은 온전히 여러분께서 스스로 제어할 수 있으며, 언제든지 한 걸음 물러서서 휴식을 가질 수 있습니다.",
      "회원님의 상태를 업데이트해서 기존에 연결된 사람들이 회원님께서 <a href={{ghosted_article_url}}>잠수</a> 를 탄다고 생각하지 않도록 알리고 연결을 유지할 수 있습니다. 또한 자동화되어 있으므로 따로 직접 메시지를 보내지 않아도 됩니다.",
      "적극적인 데이트 활동으로부터 잠시 휴식을 취해도 회원님의 연결이나 대화들이 사라지지 않습니다.",
      "내가 원하는 때에 데이트하고 필요하면 연결을 쉬어가세요",
      "회원님의 인연들에게 회원님의 상태를 알리세요",
      "활동은 잠시 쉬어가지만 연결은 끊지 않아요",
      "우리가 스누즈 모드를 사랑하는 이유",
      "Bumble 앱을 열고, 프로필 탭으로 이동한 후 오른쪽 상단 코너에 있는 설정 톱니바퀴를 탭하세요. 설정에서 스누즈를 탭하고 오프라인을 유지하고 싶은 기간을 선택하세요. 프로필을 24시간, 72시간, 일주일, 또는 회원님께서 직접 해제할 때까지 숨길 수 있습니다.{{__EOL__}}{{__EOL__}}스누즈 모드에서는 채팅이 이어지지 않은 짝들은 사라집니다. 상태를 설정해 두시면, 현재 대화를 주고 받고 있는 사람들은 회원님께서 오프라인임을 볼 수 있습니다.",
      "Bumble 에서 스누즈 모드는 이렇게 실행됩니다",
      "삶이 바쁠 때, 스누즈 모드가 도움이 될 수 있어요. Bumble 계정을 일시 정지하고, 스스로의 속도에 맞춰서 데이트를 하세요.",
      "Bumble 에서의 스누즈 모드",
      "스누즈 모드는 데이트로부터 휴식을 취하고 싶을 때 회원님의 계정을 일시 정지하는 쉬운 방법입니다. 기존의 연결들과는 여전히 채팅할 수 있으며, 회원님께서 다시 스와이프를 시작할 준비가 될 때까지 회원님의 프로필은 다른 사람들로부터 숨겨집니다. 회원님의 프로필 정보를 잃거나 기존 연락을 삭제하지 않고 스누즈 모드를 활성화할 수 있습니다.",
      "회원님의 Bumble 프로필을 잠시 스누즈하세요",
      "스누즈 모드를 사용 중이시라면, 상태 업데이트를 통해서 회원님께서 잠시 쉬어가는 중임을 기존의 인연들에게 알릴 수 있습니다. '여행 중', '일에 집중 중', '디지털 디톡스 중', 혹은 '저만을 위한 시간이 필요해요' 중에서 선택하세요. 여러분이 어떤 상태에 있던지, 저희가 지켜드릴게요.",
      "‘오프라인‘ 상태를 추가하세요",
      "스누즈 모드를 활성화하면 회원님의 Bumble (혹은 {{page_partner_name_bff}}) 계정이 일시 정지됩니다. 회원님의 짝들은 회원님께서 스누즈 모드라는 것을 알 수 없습니다. 처음 선택한 시간 기간보다 일찍 스누즈 모드에서 깨어나려면 설정으로 돌아가서 \"스누즈 모드 비활성화\"를 탭하세요.",
      "채팅을 일시 정지하세요",
      "Bumble 에서 SuperSwipe를 사용해보세요",
      "원하는 만큼이요! {{service_level_lexeme_name}} 구독 회원은 매주 {{num}} 개의 [[superswipe:num]] 를 받지만, 언제든지 충전할 수 있습니다. 무료 회원은 SuperSwipes 패키지를 구매할 수 있습니다.",
      "Bumble에서 SuperSwipes를 몇 개까지 보낼 수 있나요?",
      "누군가 회원님의 프로필에 SuperSwipe을 사용하면 즉시 알림을 받게 됩니다. 또한 해당하는 사람의 프로필 상단에 라벨이 표시되어 그 사람이 회원님을 좋아하는 것을 알 수 있습니다.",
      "누군가가 나에게 SuperSwipe를 보냈는지 어떻게 알 수 있나요?",
      "누군가에게 진지하게 관심이 가시나요? 어색함을 깨는 멋진 방법이 있어요. SuperSwipe를 보내서 그 사람에게 회원님이 관심이 있음을 알리고 즐거운 대화를 시작하세요, 빨리요.",
      "Bumble 에서의 SuperSwipe",
      "많은 사람들 사이에서 돋보이는 것은 언제나 쉽지 않지만, SuperSwipe를 사용하면 훌륭한 첫 인상을 남기는 데에 도움이 될 수 있습니다.{{__EOL__}}{{__EOL__}}SuperSwipe를 사용하시면, 회원님께서 좋아하는 사람에게 알림이 전송되며 해당 회원의 회원들 탭에서 가장 먼저 회원님의 프로필이 라벨과 함께 나타납니다. 해당 회원도 회원님을 찜하면 대화를 시작할 수 있습니다.",
      "SuperSwipe가{{__EOL__}}뭐예요?",
      "대담하게 돋보이세요",
      "더 많은 채팅을 시작하세요",
      "더 많은 연결을 만드세요",
      "SuperSwipe가{{__EOL__}}도와드릴게요…",
      "<a href={{bumble_boost_url}}>Bumble {{service_level_lexeme_name}}</a> 또는 Bumble {{service_level_lexeme_name_one}} 서비스를 구독 중인 경우, 주간 {{num}} 개의 [[superswipe:num]] 가 무료로 제공됩니다. 또한 모든 회원들은 프로필 탭에서 최대 {{num_two}} 개의 [[superswipe:num_two]] 패키지를 구매할 수 있습니다. {{service_level_lexeme_name_three}} 서비스를 구독하시는 경우, 주간 {{num_three}} 개의 [[superswipe:num_three]] 가 제공됩니다.{{__EOL__}}{{__EOL__}}SuperSwipe를 사용하는 방법은 간단합니다. 회원님께서 마음에 드는 사람을 발견하면, 상대방의 프로필 하단에 있는 노란색 별 아이콘을 탭하세요.",
      "SuperSwipe 사용 방법",
      "Apple 및 Apple 로고는 Apple의 등록 상표입니다. Google Play는 Google LLC의 상표입니다.",
      "Bumble 의 트래블 모드로 여행 중에도 데이트를 찾으세요",
      "<a href={{bumble_premium_url}}>{{service_level_lexeme_name}} 회원</a> 들은 트래블 모드를 사용할 수 있습니다. 활성화하시면 회원님께서 선택한 도시의 새로운 사람들을 볼 수 있습니다.",
      "트래블 모드를 어떻게 사용하나요?",
      "거의 그렇습니다. 약간의 안전 관련 제한 사항이 있을 수 있으며 시간이 지남에 따라 변경될 수 있습니다. 그러나 트래블 모드는 거의 모든 곳에서 사용 가능합니다.",
      "트래블 모드는 어디서나 사용할 수 있나요?",
      "트래블 모드는 회원님께서 선택한 도시의 중심으로 회원님의 위치를 표시하여, 해당 지역에서 새로운 연결이 회원님을 찾을 수 있습니다. 사람들은 회원님의 프로필에서 회원님께서 트래블 모드를 사용 중이라는 문구를 볼 수 있으므로 회원님께서 여행 중이거나 여행을 올 계획이 있다는 것을 알 수 있습니다.",
      "여행을 위해 짐을 챙기는 중이거나 새로운 도시로 출장을 가시나요? Bumble 의 트래블 모드를 활성화해서 떠나는 가방에 데이트를 챙겨가세요.",
      "Bumble 에서의 트래블 모드",
      "트래블 모드는 여행 중에도 연결을 만드는 데 도움이 되는 최고의 도구입니다. 회원님의 위치를 전세계 어느 곳이든 설정하면 선택한 도시에 회원님의 프로필이 표시됩니다. 회원님의 모험이 어디로 향하든 그곳에서 데이트 경험에 뛰어들 수 있습니다.",
      "트래블 모드는 무엇인가요?",
      "그 지역에 사는 사람들이 그 도시를 가장 잘 알죠. 트래블 모드를 통해 여행을 특별하게 만들어 드릴 사람들과 연결해 드릴게요.",
      "여행하기 최대 7일 전에 회원님의 위치를 여행 도시로 설정해 주세요. 그러면 방문하기 전에 미리 짝들과 데이트 계획을 만들 수 있습니다.",
      "바다 여행보다 더 많은 것들을 상상해보세요 - 휴가 중일 수도 있고, 출장 중이시거나 또는 새로운 도시를 탐험하시는 와중에도, 어디에서든 로맨스를 찾아보세요.",
      "지역 최고의 데이트 장소를 찾으세요",
      "모험을 하세요",
      "오래 남을 연결들을 만드세요",
      "트래블 모드를 사랑할 이유",
      "트래블 모드는 <a href={{bumble_premium_url}}> {{service_level_lexeme_name}} 및 {{service_level_lexeme_name_two}} </a> 구독 회원들이 Bumble 에서 사용 가능합니다. 프로필의 오른쪽 상단에 있는 설정 톱니바퀴를 탭하고 스크롤해서 트래블 모드를 찾으세요. 새 위치를 선택해서 해당 지역에 사는 사람들을 볼 수 있어요. 상대방들은 회원님의 프로필에서 회원님께서 트래블 모드를 사용하고 있는 것을 볼 수 있습니다.",
      "트래블 모드는{{__EOL__}}어떻게 사용하나요?",
      "지금 사용해보세요",
      "Bumble 을 웹에서 사용해보세요",
      "그럼요. Bumble 웹 어플리케이션은 PC, Mac, 그리고 태블릿을 포함한 모든 주요 웹 브라우저에서 작동합니다.",
      "Bumble 을 PC 또는 Mac에서 사용할 수 있나요?",
      "네, 가능합니다. Bumble 웹 앱에서 메시지를 보내거나 심지어 비디오나 오디오 통화까지 할 수 있습니다.",
      "웹 앱에서 짝과 채팅을 할 수 있나요?",
      "로그인하려면 '로그인하기'를 선택하거나 <a href={{sign_in_url}}>이곳</a> 을 클릭하세요. 회원님의 Apple ID, Facebook 또는 휴대폰 번호를 사용하여 로그인할 수 있습니다.",
      "Bumble 에 웹에서 로그인하려면 어떻게 해야 하나요?",
      "조그만 스마트폰 화면에서 잠시 휴식이 필요하신가요? Bumble 웹 어플리케이션을 사용해 보세요. 회원님께서 사랑하는 Bumble 의 경험을 동일하게 더 큰 화면에서 즐길 수 있어요.",
      "Bumble 을 웹에서 사용하기",
      "웹에서 Bumble 에 로그인하는 것은 간단합니다. 당사 웹사이트를 방문하셔서 '로그인'을 클릭한 다음 Apple ID, Facebook 정보 또는 전화번호와 같은 회원님의 일반적인 로그인 방법을 선택하세요. 짜잔! 로그인이 완료되었으며 회원님의 대화와 연결들은 그대로 유지됩니다.",
      "로그인",
      "브라우저를 통해 로그인하기",
      "회원님의 프로필을 편집하거나 수정하고 고급 필터를 적용하세요.",
      "회원님께서 본인이라는 것을 인증해서 훌륭한 짝을 만들 수 있는 가능성을 높이세요.",
      "새로운 귀여운 스냅샷을 공유하거나 오래된 것들을 삭제하세요.",
      "프로필을 업데이트하세요",
      "회원님이 진짜 회원님이라는 것을 사람들에게 알려주세요",
      "사진 추가 또는 제거",
      "Bumble 웹에서 할 수 있는 일",
      "왼쪽에서는 대화들과 연결 목록, 오른쪽에서는 새로운 매칭 가능한 사람들을 찾을 수 있습니다.{{__EOL__}}{{__EOL__}}왼쪽과 오른쪽 방향키를 사용하거나, 화면 오른쪽에 있는 아이콘을 클릭하여 프로필을 확인하고 핸드폰과 동일하게 사람들을 찜 할 수 있습니다.",
      "Bumble 을 웹에서 어떻게 사용하나요?",
      "이곳에서는 친절함이 곧 섹시함이며, 여러분 스스로의 가장 나 다운 모습이 완벽함이며, 여성이 먼저입니다. 항상요.",
      "첫인사를 건네는 곳의 시작",
      "긍정적이고 생산적인 삶의 핵심은 건강한 관계로부터 시작합니다. Bumble은 통제력을 지닌 상태에서 데이트 상대를 찾고 계시든, 친구를 찾고 계시든, 커리어 기반의 네트워크를 확장하고 싶으시든 상관없이 이러한 관계를 구축하시도록 도와드릴 수 있게 고안되었습니다.",
      "Bumble 이 중요한 이유",
      "저희는 관계의 모든 단계에 진실함, 친절함, 평등, 그리고 존경을 중심으로 행동하도록 격려하고 있습니다. 저희는 사랑을 찾고 있거나 단순히 재미있는 시간을 찾고 있는 모든 사람들을 환영하는 것 뿐만 아니라 모든 성향을 찬양하고자 합니다.",
      "Bumble 의 가치",
      "Bumble 에서는 여성들이 먼저 첫인사를 건네거나 짝들이 답장을 보낼 수 있는 Opening Move를 설정할 수 있습니다. 저희는 구식의 생각을 전환하여 인연의 첫 단계부터 평등이 유지될 수 있도록 격려하고 있습니다.",
      "Bumble 사용 방법",
      "저희를 소개합니다",
      "지금 다운로드하기",
      "앱은 더 멋져요.",
      "온라인 데이팅을 진심으로 즐겁게 만들기 위해 디자인된 기능들",
      "2024년 2월 12일",
      "관계에서 감정적 친밀감을 높이는 방법",
      "2024년 1월 25일",
      "데이팅 앱을 사용해본 적이 없으신 분에게 Bumble 앱을 사용하는 방법",
      "2024년 2월 5일",
      "사기 탐지기™",
      "널리 읽혀야 할 모든 데이팅 뉴스",
      "사이즈가 중요하다면, 저희 웹사이트를 시도해보세요. 앱과 같지만 더 큽니다.",
      "웹 앱",
      "<b>스누즈 모드</b> 로 데이팅을 일시정지하세요. 다시 데이트할 준비가 되면, 언제든지 돌아오세요.",
      "잠깐 휴식하기",
      "<b>영상 통화</b> 와 <b>오디오 채팅</b> 을 사용해서 집콕하면서 상대방을 만나세요.",
      "다양하게 소통하세요",
      "더 보기",
      "<b>Opening Moves</b> 를 선택하면 내가 먼저 대화를 시작할 필요 없이 짝들이 인사를 건네도록 초대할 수 있어요.",
      "더 적은 노력으로 더 많이 데이트하세요",
      "<b>트래블 모드</b> 로 전 세계 어느 곳에서 짝이 될 수 있어요.",
      "가고 싶은 곳으로 떠나세요",
      "상대방의 프로필 분위기가 합격이라구요? <b>SuperSwipe</b> 로 마음을 전하세요.",
      "상대방의 주목을 끄세요",
      "<b>Compliments</b> 를 보내서 사람들 속에서 돋보이세요.",
      "상대방의 기분을 띄워주세요",
      "<b>투명 회원 모드</b> 를 사용해서 전 연인, 친척, 직장 상사로부터 프로필을 숨기세요.",
      "프라이버시를 지키세요",
      "원하는 방식으로 데이트하세요",
      "몇달 후에, 신부 들러리 중 한 명도 그곳에서 만나셨어요.",
      "Hannah님은 지금 남편을 Bumble에서 만나셨어요.",
      "이제 함께 작은 집을 짓고 있는 Carène님과 You-Liang님은 Bumble에서 만났어요. You-Liang님이 Carène님의 여행 사진에 끌렸다고 해요.",
      "Carène님과 You-Liang님",
      "Brian님과 Kaci님은 함께 외국에 발령을 받았어요 - 하지만 두 분은 Bumble에서 만나셨어요",
      "Brian님과 Kaci님",
      "다른 사람들에게 일어난 일은 여러분에게도 일어날 수 있어요",
      "다음 인연을 만드세요",
      "업데이트를 살펴보세요",
      "회원님은 최고로 대접받을 가치가 있어요. 그래서 스트레스 없이 데이트를 즐길 수 있는 멋진 방법들을 고안해봤어요.",
      "이상형의 기준을 타협할 필요가 없어요",
      "Bumble 소개",
      "저희는 여성의 경험을 우선시하여 데이팅 경험을 보다 낫게 만드는 단 하나 뿐인 앱입니다. 여성에게 나은 경험이 모두에게 나은 경험이기 때문입니다.",
      "Make the first move™",
      "정말로 데이트하고 싶은 사람을 찾고, 첫인사를 먼저 건네세요. 진실한 연결을 만들고, 공통 관심사를 나누세요. 그리고 가장 중요한 것은, 즐거운 시간을 보내세요.",
      "Bumble Date로 누군가를 만나세요",
      "커리어 혜택을 가진 친구들을 찾으세요. 다음 직장, 멘토, 혹은 완전히 새로운 커리어를 찾기 위해 다른 비지니스 전문가들과 연결되세요.",
      "Bumble Bizz로 커리어를 성장시키세요",
      "나와 닮은 사람을 만나세요. 아니면 반대인 사람을요. 집이든 여행 중이든 함께 새로운 활동을 할 수 있는 새로운 친구들을 만드세요.",
      "Bumble For Friends에서 새로운 친구들을 찾으세요",
      "데이팅 그 이상을 제공합니다",
      "Bumble은 여성이 첫 인사를 건네는 데이팅 앱입니다. 데이트와 의미있는 관계를 형성하고 네트워크를 만드는 방식을 변화시킨 앱입니다. 지금 다운로드하세요.",
      "{{social_network}} 에서의 Bumble",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "Premium+",
      "죄송합니다. 현재 찾으시는 페이지를 발견할 수 없습니다.",
      "문의하기",
      "로그인하기",
      "앗, 오류가 발생했어요",
      "(opens in new window)",
      "자주 하는 질문",
      "Cookie Policy",
      "Premium",
      "Bumble - The Blog",
      "인재 채용",
      "채팅",
      "시",
      "쿠키 관리",
      "Copyright © 2006 – present. Bumble. All rights reserved.",
      "자신 있는 데이트를 위한 보고서",
      "Apple 및 Apple 로고는 Apple의 등록 상표입니다.{{__EOL__}}Google Play(은)는 Google LLC의 상표입니다.",
      "다운로드하세요",
      "Facebook",
      "팔로우하세요",
      "도움말 센터",
      "Instagram",
      "언어",
      "LinkedIn",
      "Bumble는 Bumble Inc의 일부입니다.",
      "개인정보 취급방침",
      "Safety Centre",
      "사이트맵",
      "현대판 노예제 방지 성명",
      "이용약관",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "Compliments": {
            "plural": [
                  "칭찬보내기"
            ],
            "singular": [
                  "칭찬보내기"
            ]
      },
      "day": {
            "plural": [
                  "일"
            ],
            "singular": [
                  "일"
            ]
      },
      "hour": {
            "plural": [
                  "시간"
            ],
            "singular": [
                  "시간"
            ]
      },
      "minute": {
            "plural": [
                  "분"
            ],
            "singular": [
                  "분"
            ]
      },
      "month": {
            "plural": [
                  "개월"
            ],
            "singular": [
                  "개월"
            ]
      },
      "second": {
            "plural": [
                  "초"
            ],
            "singular": [
                  "초"
            ]
      },
      "superswipe": {
            "plural": [
                  "SuperSwipes"
            ],
            "singular": [
                  "SuperSwipe"
            ]
      },
      "year": {
            "plural": [
                  "년"
            ],
            "singular": [
                  "년"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
