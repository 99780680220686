/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Retour",
      "Télécharger sur l'App Store",
      "Télécharger sur Google Play",
      "Carrousel",
      "Diapositive {{number}}",
      "Diapositive suivante",
      "Diapositive précédente",
      "Diapositive",
      "Diapositive {{number}} sur {{total}}",
      "Ne pas vendre ou partager mes données personnelles",
      "Ne pas vendre ou partager mes données personnelles",
      "Envoie des compliments sur Bumble pour montrer que tu aimes son profil et pour attirer son attention avant de matcher. Essaie dès maintenant",
      "Compliments | Comment envoyer un compliment | Bumble",
      "Une page personnalisée qui met en avant les profils les plus compatibles avec toi, actualisée chaque jour.",
      "À découvrir | Trouve des personnes compatibles | Bumble",
      "Fais des rencontres à ton rythme et garde le contrôle sur qui voit ton profil Bumble avec le mode Incognito. Pour en savoir plus sur le mode Incognito de Bumble, consulte notre guide",
      "Mode Incognito | Cache ton profil | Bumble",
      "Après une rencontre à un événement Bumble IRL, renforce l'alchimie en poursuivant la discussion sur l'appli.",
      "Match instantané | Poursuis la conversation sur l'appli | Bumble",
      "Essaie une nouvelle façon de lancer la conversation avec la fonctionnalité Opening Moves de Bumble. Attire leur attention, donne le ton, et obtiens plus rapidement de bonnes discussions",
      "Opening Moves sur Bumble | Bumble",
      "Besoin d'une petite pause ou de prendre du temps pour toi ? Découvre comment mettre ton compte Bumble sur pause avec le mode Snooze sur Bumble. Cela te permet de faire des rencontres à ton rythme",
      "Mode Snooze | Fais une pause | Bumble",
      "Tu viens de voir quelqu'un que tu apprécies vraiment sur Bumble ? Utilise un SuperSwipe pour lui montrer ton côté sérieux. Appuie sur l'étoile sur son profil pour te lancer, ou apprends‑en davantage ici",
      "SuperSwipe sur Bumble | Qu'est-ce qu'un SuperSwipe ? | Bumble",
      "Tu voyages pour le travail ou pour t'amuser ? Le mode Voyage sur Bumble peut t'aider à faire de nouvelles rencontres où que tu ailles. Découvre le mode Voyage dès maintenant",
      "Mode Voyage sur Bumble | Des rencontres où que tu sois | Bumble",
      "Tu veux savoir si la connexion que tu ressens par messages amènera une véritable alchimie dans la vraie vie ? Essaie de passer un appel audio ou vidéo sur Bumble et vérifie si vous êtes sur la même longueur d'onde",
      "Appel vidéo | Passer un appel | Bumble",
      "Découvre avec enthousiasme Bumble Web, la fonctionnalité ultime.",
      "Bumble Web | Bumble",
      "Résilier les contrats",
      "Page d'accueil de Bumble",
      "Fermer le menu",
      "Ouvrir le menu",
      "Choix de la langue",
      "Pied de page",
      "Accessibilité chez Bumble",
      "Apple et le logo Apple sont des marques déposées d'Apple Inc.",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Emploi",
      "Événements",
      "Google Play est une marque de Google LLC.",
      "Charte",
      "Investisseurs",
      "Gérer les cookies",
      "Gérer les cookies",
      "Loi sur l'esclavage moderne",
      "Avis de collecte",
      "Confidentialité",
      "Guides de rencontres saisonniers",
      "Conditions",
      "L'appli",
      "Politique de confidentialité des données de santé du consommateur de Washington",
      "À propos",
      "Ambassadeurs",
      "Le Blog",
      "Fais-toi de nouveaux amis qui te correspondent",
      "Développe ton réseau professionnel et booste ta carrière",
      "Trouve une relation sérieuse, une aventure sans lendemain ou autre chose",
      "En savoir plus",
      "Parcours le monde avec le mode Voyage",
      "Fonctionnalités",
      "Compliments",
      "Envoie des Compliments pour te démarquer.",
      "À découvrir",
      "Une page personnalisée qui met en avant les profils les plus compatibles avec toi, actualisée chaque jour.",
      "Mode Incognito",
      "Utilise le mode Incognito pour éviter que ton patron, tes proches ou tes ex ne voient ton profil.",
      "Match instantané",
      "Tu as commencé la discussion à un événement Bumble IRL ? Poursuis‑la sur l'application.",
      "Opening Moves",
      "Choisis des Opening Moves pour inviter ton match à lancer la discussion et t'éviter d'avoir à le faire.",
      "Mode Snooze",
      "Prends un moment pour toi avec le mode Snooze. Quand tu voudras revenir, tu sais où nous trouver.",
      "SuperSwipe",
      "Son profil t'intéresse ? Fais-lui savoir en lui envoyant un SuperSwipe.",
      "Mode Voyage",
      "Trouve ton bonheur n'importe où dans le monde grâce au mode Voyage.",
      "Appel vidéo",
      "Passe des appels vidéo et audio pour vérifier si c'est le bon moment pour une rencontre en personne.",
      "Application web",
      "Découvre l'expérience Bumble directement depuis ton navigateur.",
      "Boutique",
      "Principale",
      "Passer directement au contenu principal",
      "Logo de Bumble",
      "Tu veux faire sa connaissance ? Lance un appel vidéo sur Bumble",
      "Il te suffit de toucher l'icône vidéo dans ta discussion pour démarrer un appel vidéo, tout cela sans partager aucune information de contact personnelle.",
      "Comment fonctionne l'appel vidéo sur Bumble ?",
      "Non. Tous les appels et discussions sur Bumble conservent tes informations personnelles en sécurité. Tes matchs ne recevront pas ton numéro ni aucune de tes coordonnées tant que tu ne les auras pas informés.",
      "Mes matchs verront‑ils mon numéro de téléphone ?",
      "Absolument ! Si tu penses que ce n'est pas encore le bon moment pour un face à face, les appels vocaux te permettent de faire connaissance de vive voix. Il te suffit de sélectionner l'icône d'appel à la place.",
      "Puis‑je simplement essayer un appel audio ?",
      "On adore les notifications *message reçu*. Mais nous savons qu'il est difficile de dire si tes discussions seront aussi intéressantes en personne. C'est là qu'interviennent les appels vidéo et audio.",
      "Les appels vidéo sur Bumble",
      "Les <a href={{video_chat_url}}>appels audio et vidéo</a> peuvent t'aider à faire connaissance et à décider si tu souhaites continuer en dehors de l'application. Le plus beau dans tout ça ? Lorsque tu passes un appel via Bumble, tu n'as pas besoin d'échanger de numéros de téléphone. Tes infos personnelles restent donc en sécurité jusqu'à ce que tu penses que c'est le bon moment de les partager.",
      "Vérifie votre alchimie avec un appel vidéo",
      "Réduis la distance entre dating en ligne et hors ligne, et décide si c'est le moment de sauter le pas et de vous rencontrer.",
      "Fais plus ample connaissance (et plus rapidement). Une conversation en face à face ou un appel téléphonique peut en révéler beaucoup sur quelqu'un.",
      "Tu as le contrôle. Nous garderons tes informations privées, tu peux donc décider quand les partager.",
      "Vois si c'est le bon moment pour une rencontre en personne",
      "Vérifie votre alchimie",
      "Fais connaissance sans donner tes infos personnelles",
      "Pourquoi devrais‑tu utiliser les appels et discussions vidéo ?",
      "Tu veux entamer une discussion ? Tu peux choisir de démarrer un appel audio ou vidéo directement depuis la discussion dans l'application Bumble. Pour lancer un appel vidéo, appuie sur l'icône en forme de caméra grise dans le coin supérieur droit. Tu verras également trois points à côté de l'icône : clique dessus et tu verras l'option d'appel audio.",
      "Comment lancer un appel vidéo ?",
      "Tu veux essayer les Compliments ?",
      "Lorsque tu envoies un Compliment, la personne qui le reçoit le verra quand tu apparaîtras dans sa file d'attente.",
      "Que se passe-t-il quand tu complimentes quelqu'un sur Bumble ?",
      "Si son Compliment a attiré ton attention, alors n'hésite pas à matcher. Tu verras le Compliment dans votre discussion, et les règles habituelles pour <a href={{make_the_first_move_url}}>Faire le premier pas</a> s'appliqueront.",
      "Comment répondre à un Compliment ?",
      "Tu peux envoyer {{num}} [[Compliments:num]] gratuitement. Lorsque tu n'en as plus, tu peux en acheter davantage dans l'application Bumble par packs allant jusqu'à 30 Compliments. L'un de ces derniers sera utilisé pour envoyer ce Compliment (conserve le reste pour quand tu verras un autre profil qui te plaît). Ne t'inquiète pas, ils n'expireront pas !",
      "Combien de Compliments puis‑je envoyer ?",
      "Un mot doux (ou deux) peuvent t'amener loin. Si tu veux envoyer un Compliment mais que tu ne sais pas quoi écrire, inspire‑toi de ceux‑ci :",
      "Voir d'autres exemples",
      "« Toute personne qui apprécie [nom d'artiste] a de très bons goûts. »",
      "« Ton sourire est contagieux »",
      "« Ta bio m'a fait rire, bien joué ! »",
      "Comment envoyer de meilleurs Compliments sur Bumble ?",
      "Tu veux illuminer sa journée ? Tu pourrais utiliser un Compliment.",
      "Les Compliments sur Bumble",
      "Utilise des Compliments sur Bumble pour indiquer à un match potentiel que tu aimes son profil (et peut‑être même pour le faire rougir). Tu obtiens des Compliments en tant que membre {{service_level_lexeme_name}}. Cela te permet également d'envoyer et de recevoir des messages avant même de matcher.",
      "Comment fonctionnent les Compliments ?",
      "Lorsque tu reçois un Compliment, il apparait sur le profil de l'expéditeur. Tu reçois également une notification t'indiquant qu'une personne aime une partie de ton profil.",
      "Découvre tes Compliments",
      "Tu peux envoyer un Compliment en appuyant sur l'icône en forme de cœur jaune en bas du profil de quelqu'un. L'endroit où tu appuies est important, car tu complimenteras spécifiquement cette photo ou cette partie de son profil ! Lorsque tu appuies, une boîte de texte s'ouvre pour que tu puisses écrire ton message (celui‑ci peut aller jusqu'à 150 caractères).",
      "Envoie un Compliment",
      "TÉLÉCHARGE Bumble POUR ESSAYER À DÉCOUVRIR",
      "Chaque jour à partir de 9 heures, tu verras de nouvelles personnes sélectionnées pour toi dans À découvrir. Tu peux te connecter avec elles grâce à vos points communs.",
      "Quand ma page À découvrir se met‑elle à jour ?",
      "Ta page À découvrir trie les matchs potentiels en 3 catégories principales : centres d'intérêt similaires, objectifs de dating identiques, et communautés en commun. Les recommandations de profil sont également affichées et sont basées sur tes matchs précédents.",
      "Comment ma page À découvrir est‑elle composée ?",
      "Une page personnalisée qui met en avant les profils les plus compatibles avec toi, actualisée chaque jour.",
      "TROUVE DES PERSONNES QUI TE CORRESPONDENT AVEC {{qr_code}} À DÉCOUVRIR",
      "À découvrir te montre une sélection de personnes qui pourraient te plaire, basées sur les centres d'intérêt similaires, les objectifs de dating et les communautés.",
      "Essayer À découvrir",
      "ENVIE D'UNE NOUVELLE FAÇON DE TROUVER DES POINTS COMMUNS ?",
      "La page À découvrir est renouvelée chaque jour afin de te montrer encore plus de personnes compatibles avec toi. Tu peux ainsi passer moins de temps à attendre un match, et plus de temps à faire connaissance avec des personnes qui te plairont à coup sûr.",
      "CHAQUE JOUR DE NOUVELLES RECOMMANDATIONS",
      "Les recommandations de À découvrir sont basées sur ton profil et sur tes matchs précédents, donc tu verras des personnes qui partagent tes passions et tes envies (qu'il s'agisse d'un burger végan ou d'une relation à long terme).",
      "PERSONNALISÉ{{__EOL__}}RIEN QUE POUR TOI",
      "Télécharger Bumble",
      "Questions fréquentes",
      "Tu veux parcourir Bumble en toute discrétion ?",
      "Lorsque tu actives le mode Incognito, ton profil est masqué aux autres utilisateurs de Bumble à moins que tu ne leur donnes un Like.",
      "Comment fonctionne le mode Incognito ?",
      "Sans le mode Incognito, ton profil est visible pour les autres utilisateurs sur Bumble lorsque tu corresponds à leurs préférences, comme l'âge et l'emplacement. Lorsque tu actives le mode Incognito, tu n'es visible que pour les personnes auxquelles tu as donné un Like.",
      "Qui peut me voir avec le mode Incognito de Bumble ?",
      "Utiliser le mode Incognito ne signifie pas <a href={{blocking_on_bumble_url}}>bloquer quelqu'un sur Bumble</a>. Avec cette fonctionnalité, si tu donnes un Like à une personne (ou si tu lui as envoyé un message par le passé), elle pourra voir ton profil.",
      "Le mode Incognito est‑il similaire à la fonctionnalité Bloquer ?",
      "Prends le contrôle sur qui peut voir ton profil grâce au mode Incognito sur Bumble.",
      "Le mode Incognito sur Bumble",
      "Si tu souhaites faire une pause vis‑à‑vis du dating, ou parcourir Bumble sans que d'autres utilisateurs ne voient ton profil, tu peux activer le mode Incognito. Cela signifie que nous te cacherons à la vue de tous, à moins que tu ne donnes un Like à quelqu'un en premier. Tu peux ainsi contrôler ce que tu partages et qui te voit.",
      "Cache ton profil grâce au mode Incognito",
      "Tu décides qui peut voir tes photos et tes informations personnelles.",
      "Tu veux ajouter quelque chose de personnel sur ton profil ? Tu peux partager discrètement ce que tu souhaites avec tes matchs actuels.",
      "Navigue librement sans te soucier de tomber sur les personnes que tu souhaites éviter.",
      "Prends le contrôle total",
      "Choisis ce que tu partages",
      "Évite les rencontres gênantes",
      "Pourquoi tu vas adorer le mode Incognito",
      "Le mode Incognito est disponible pour les membres {{service_level_lexeme_name}} et {{service_level_lexeme_name_two}}. Une fois ton inscription finie, tu peux l'activer en te rendant sur l'onglet de ton profil, en appuyant sur Paramètres dans le coin supérieur droit, et en passant en mode Incognito.",
      "Passer à {{service_level_lexeme_name}}",
      "Comment utiliser le mode Incognito sur Bumble ?",
      "TÉLÉCHARGE Bumble POUR ESSAYER MATCH INSTANTANÉ",
      "Dans l'appli Bumble, rends‑toi sur l'onglet du profil en bas de ton écran. Tu trouveras ensuite l'icône du code QR en haut à droite de l'écran. Appuie sur cette icône pour y accéder.",
      "Où puis‑je trouver mon code QR personnel ?",
      "Non. Tu dois avoir accès à internet, via tes données mobiles ou en WiFi, et utiliser le QR code à jour pour que Match instantané fonctionne.",
      "Puis‑je faire une capture d'écran de mon code QR pour le partager ?",
      "Non ! Si une personne scanne le code, le match sera automatique.",
      "Est‑ce que les deux personnes doivent scanner le code QR ?",
      "Avec Match instantané, tu peux automatiquement voir ce que toi et ton nouveau match avez en commun. Cela te donne un aperçu du niveau de compatibilité, et ce dès le départ.",
      "TROUVE DES POINTS COMMUNS PLUS RAPIDEMENT",
      "Tu as commencé la discussion à un événement Bumble IRL ? Poursuis‑la sur l'application.",
      "PASSE D'UNE SIMPLE RENCONTRE À UN {{qr_code}} MATCH INSTANTANÉ",
      "Avec Match instantané, il est plus simple de passer d'une simple rencontre à une belle discussion, sans échange de numéros ou de réseaux sociaux. Il te suffit de lui faire scanner ton code QR pour matcher sur Bumble.",
      "Essayer Match instantané",
      "TU AS RENCONTRÉ QUELQU'UN À UN ÉVÉNEMENT Bumble IRL ?",
      "Au lieu de partager tes réseaux sociaux ou ton numéro de téléphone, Match instantané te permet d'abord de vérifier votre alchimie dans l'appli. Ainsi, vous pouvez faire connaissance à votre rythme, grâce aux fonctionnalités de sécurité de Bumble.",
      "CONNECTE‑TOI DE FAÇON PLUS SÛRE",
      "Après une rencontre à un événement Bumble IRL, renforce l'alchimie en poursuivant la discussion sur l'appli et en créant des liens autour de centres d'intérêt en commun (qu'il s'agisse d'un amour inconditionnel du café ou d'une passion pour les succulentes rares).",
      "POURSUIS LA CONVERSATION",
      "Essaie Opening Moves sur Bumble",
      "Opening Moves est une autre façon de discuter avec les personnes qui te plaisent. Une fois que tu as choisi un message à envoyer, tu peux te détendre, attendre les réponses, et répondre à celles qui se démarquent réellement.",
      "Comment utiliser les Opening Moves sur Bumble ?",
      "En ce qui concerne les relations non‑binaires et de même genre, les deux personnes peuvent ajouter un Opening Move et y répondre.",
      "Puis‑je utiliser Opening Moves sur Bumble pour rencontrer des personnes du même genre que moi ?",
      "Lorsqu'une personne répond à ton Opening Move, tu verras son profil apparaître dans tes discussions. Les femmes ont 24 heures pour répondre avant que le match n'expire. Ensuite, tu peux soit leur répondre à nouveau, soit entamer une nouvelle conversation.",
      "Comment puis‑je voir si quelqu'un a répondu à mes Opening Moves ?",
      "Opening Moves, c'est notre manière à nous de relâcher la pression lorsqu'il s'agit de faire le premier pas, avec une méthode plus facile et plus rapide pour parvenir à une discussion intéressante.",
      "Opening Moves sur Bumble",
      "Nous sommes heureux que tu poses la question. Opening Moves, c'est une nouvelle manière de lancer plus facilement une discussion. Tu peux choisir un Opening Move recommandé comme « Quelle est ta chanson préférée si tu es aux commandes à une soirée ? », ou écrire ton propre Opening Move. Si tu en as défini un, tes matchs peuvent y répondre lorsqu'ils te voient apparaître dans leurs matchs.",
      "Qu'est-ce qu'un Opening Move ?",
      "Relâcher la pression",
      "Partager ce qui te rend unique",
      "Lancer des discussions plus intéressantes",
      "Les Opening Moves peuvent t'aider à…",
      "Tu peux définir un Opening Move à envoyer lorsque tu matches avec d'autres utilisateurs. Il te suffit de te rendre dans les Opening Moves sur ton profil et de choisir l'une des questions pré-écrites, ou bien de laisser libre cours à ta créativité et écrire ta propre question. Tu peux modifier tes Opening Moves quand tu le souhaites.",
      "Essayer Opening Moves",
      "Comment utiliser les Opening Moves sur Bumble ?",
      "<a href={{see_more_url}}>Voir plus</a>",
      "Tu veux faire une pause ? Essaie le mode Snooze",
      "Oui, tu peux te rendre dans tes paramètres ou ta file d'attente et désactiver Snooze quand tu veux.",
      "Puis-je désactiver le mode Snooze plus tôt que prévu ?",
      "Va dans tes paramètres, appuie sur Snooze, et choisis la durée pendant laquelle tu souhaites t'absenter. Reviens quand ce sera le bon moment.",
      "Comment utiliser le mode Snooze sur mon profil ?",
      "Non. Tant que tu es en mode Snooze, les fonctionnalités pour swiper et discuter sont sur pause. Si tu vas découvrir de nouveaux profils, tu verras un message indiquant la durée du mode Snooze, avec une option pour le désactiver.",
      "Puis‑je swiper et discuter avec le mode Snooze activé ?",
      "Les personnes avec lesquelles tu as déjà discuté verront ton statut « Absent » (si tu choisis d'en définir un). Sinon, elles verront tes messages précédents comme d'habitude.",
      "Que verront mes matchs actuels lorsque je serai en mode Snooze ?",
      "Tu as le contrôle total sur ton activité sur Bumble, et tu peux t'éloigner de la plateforme quand tu le souhaites.",
      "Tu peux mettre au courant tes connexions existantes en utilisant la mise à jour de ton statut, pour ne pas donner l'impression de les avoir <a href={{ghosted_article_url}}>ghostées</a>. De plus, tout est automatisé : tu n'as donc pas besoin d'envoyer de messages.",
      "Fais une pause vis‑à‑vis du dating actif sans perdre tes connexions ou tes discussions.",
      "Fais des rencontres (ou fais une pause) selon tes préférences",
      "Tiens tes connexions au courant",
      "Mets ton activité sur pause, mais pas tes connexions",
      "Pourquoi aimons‑nous le mode Snooze ?",
      "Ouvre l'application Bumble, rends-toi sur l'onglet de ton profil, et appuie sur la roue dentée en haut à droite. Dans tes Paramètres, appuie sur Snooze et choisis la durée de ton absence. Tu peux masquer ton profil pendant 24 heures, 72 heures, une semaine, ou jusqu'à nouvel ordre.{{__EOL__}}{{__EOL__}}Souviens‑toi, les matchs sans discussion active expireront lorsque tu seras en mode Snooze. Si tu décides de définir un statut, les personnes avec lesquelles tu discutes actuellement pourront voir que tu es absent.",
      "Comment fonctionne le mode Snooze sur Bumble ?",
      "Lorsque l'on est occupé, le mode Snooze est là pour nous aider. Mets ton compte Bumble sur pause, et fais des rencontres selon tes préférences.",
      "Le mode Snooze sur Bumble",
      "Le mode Snooze te permet de mettre facilement ton compte sur pause lorsque tu souhaites prendre de la distance vis‑à‑vis du dating. Tu peux toujours discuter avec tes connexions existantes, il masque simplement ton profil aux autres utilisateurs jusqu'à ce que tu aies envie de recommencer à swiper. Tu ne perdras pas tes informations de profil ni ne supprimeras tes connexions existantes en activant le mode Snooze.",
      "Mets ton profil Bumble sur pause",
      "Tant que tu es en mode Snooze, tu peux choisir d'informer tes connexions existantes que tu fais une pause avec une mise à jour de ton statut. Sélectionne 'Je suis en voyage', 'Je me concentre sur mon travail', 'Je suis en détox numérique', ou 'Je prends du temps pour moi'. Quelle que soit ton humeur, nous sommes là pour toi.",
      "Ajoute un statut « Absent »",
      "Activer le mode Snooze met ton compte Bumble (ou {{page_partner_name_bff}}) sur pause. Tes matchs ne sauront pas que tu es en mode Snooze à moins que tu ne leur dises. Si tu décides de désactiver le mode Snooze plus tôt que la durée que tu avais initialement choisie, il te suffit de retourner dans les paramètres et d'appuyer sur \"Désactiver le mode Snooze\". C'est aussi simple que ça.",
      "Mets tes discussions sur pause",
      "Essaie les SuperSwipes sur Bumble",
      "Autant que tu le souhaites ! Les utilisateurs {{service_level_lexeme_name}} obtiennent {{num}} [[superswipe:num]] chaque semaine, mais tu peux recharger ton solde à tout moment. Les utilisateurs non abonnés peuvent acheter des packs de SuperSwipes.",
      "Combien de SuperSwipes puis‑je envoyer sur Bumble ?",
      "Tout de suite après qu'une personne a utilisé un SuperSwipe sur ton profil, tu reçois une notification. Tu verras également une étiquette au‑dessus du nom de cette personne sur son profil, ce qui indique qu'elle t'a donné un Like.",
      "Comment savoir si je reçois un SuperSwipe de quelqu'un ?",
      "Tu as des sentiments sérieux envers une personne ? Il existe une manière cool de briser la glace. Envoie‑lui un SuperSwipe pour lui faire savoir que tu t'intéresses à elle et passe plus vite à des discussions intéressantes.",
      "Les SuperSwipes sur Bumble",
      "Il n’est pas toujours facile de se distinguer des autres, mais un SuperSwipe peut t'aider à faire une excellente première impression.{{__EOL__}}{{__EOL__}}Lorsque tu utilises un SuperSwipe, une notification sera envoyée à la personne à qui tu as donné un Like. Tu passeras ensuite en tête de sa file d’attente avec une étiquette sur ton profil. Si elle te donne un Like en retour, la discussion peut commencer.",
      "Qu'est-ce qu'un SuperSwipe ?",
      "Te faire remarquer",
      "Lancer davantage de discussions",
      "Créer plus de connexions",
      "Un SuperSwipe peut t'aider à…",
      "Si tu disposes de <a href={{bumble_boost_url}}>Bumble {{service_level_lexeme_name}}</a> ou de Bumble {{service_level_lexeme_name_one}}, tu obtiens {{num}} [[superswipe:num]] gratuits chaque semaine. De plus, tous les membres peuvent acheter des packs allant jusqu'à {{num_two}} [[superswipe:num_two]] à partir de l'onglet Profil. Si tu disposes de {{service_level_lexeme_name_three}}, tu obtiens {{num_three}} [[superswipe:num_three]] chaque semaine.{{__EOL__}}{{__EOL__}}Utiliser un SuperSwipe est très simple : lorsque tu vois un profil qui te plaît, il te suffit d'appuyer sur l'icône jaune en forme d'étoile en bas du profil.",
      "Comment utiliser un SuperSwipe ?",
      "Apple et le logo Apple sont des marques déposées d'Apple Inc. Google Play est une marque de Google LLC.",
      "Trouve un date dès le départ grâce au mode Voyage sur Bumble",
      "Les <a href={{bumble_premium_url}}>membres {{service_level_lexeme_name}}</a> peuvent utiliser le mode Voyage. Active‑le et ta file d'attente affichera de nouveaux visages dans ta ville choisie.",
      "Comment utiliser le mode Voyage ?",
      "Presque. Nous avons quelques restrictions de sécurité qui peuvent changer avec le temps, mais le mode Voyage est disponible pour la plupart des destinations.",
      "Puis‑je utiliser le mode Voyage n'importe où ?",
      "Le mode Voyage affichera ta position au centre de la ville choisie, de sorte que les nouvelles connexions dans la région puissent te trouver. Elles verront une note sur ton profil indiquant que tu es en mode Voyage, afin qu'elles sachent que tu visites ou que tu prévois de visiter.",
      "Tu prépares tes bagages pour les vacances ou tu te rends dans une nouvelle ville pour le travail ? Active le mode Voyage sur Bumble et emporte le dating avec toi.",
      "Le mode Voyage sur Bumble",
      "Le mode Voyage est ton outil ultime pour établir des connexions lorsque tu es en déplacement. Définis ton emplacement n'importe où dans le monde, et ton profil indiquera que tu te trouves dans la ville que tu as choisie. Cela signifie que tu peux vivre ton expérience du dating partout où tes aventures te guident.",
      "Qu'est-ce que le mode Voyage ?",
      "Personne ne connaît mieux un endroit que ses propres habitants. Le mode Voyage peut t'aider à entrer en contact avec des gens qui rendront ton séjour encore plus spécial.",
      "Définis ton emplacement sur la ville jusqu'à sept jours avant le voyage, afin que tu puisses planifier des dates et rencontrer des matchs en avance.",
      "Pense au‑delà des simples séjours à la plage... Tu pourrais être chez toi pendant les vacances, voyager pour le travail ou explorer une nouvelle ville. Trouve l'amour où que tu atterrisses.",
      "Trouve les meilleurs endroits locaux pour un date",
      "Pars à l'aventure",
      "Crée des relations qui durent",
      "Les raisons d'aimer le mode Voyage",
      "Le mode Voyage est disponible pour les membres <a href={{bumble_premium_url}}>{{service_level_lexeme_name}} et {{service_level_lexeme_name_two}}</a> sur Bumble. Appuie sur la roue dentée en haut à droite de ton profil pour ouvrir les Paramètres et fais défiler jusqu'à ce que tu vois le mode Voyage. Choisis ton nouvel emplacement pour commencer à voir des personnes dans cette zone. Ton profil indiquera aux matchs potentiels que tu es en mode Voyage.",
      "Comment fonctionne le mode Voyage ?",
      "Essayer",
      "Essaie la version web de Bumble",
      "Absolument. L'application web Bumble fonctionne sur les PC, les Mac et les tablettes sur tous les principaux navigateurs Web.",
      "Puis‑je utiliser Bumble sur mon PC ou mon Mac ?",
      "Oui, tu peux. Tu peux envoyer des messages ou même passer un appel vidéo ou audio sur l'application web Bumble.",
      "Puis‑je discuter avec des matchs sur l'application Web ?",
      "Pour te connecter, choisis 'Se connecter' ou clique <a href={{sign_in_url}}>ici</a>. Tu peux te connecter en utilisant ton identifiant Apple, Facebook ou ton numéro de téléphone.",
      "Comment puis‑je me connecter à la version web de Bumble ?",
      "Besoin de faire une pause vis‑à‑vis de ton écran de téléphone ? Essaie l'application web Bumble, la même expérience Bumble que tu apprécies, mais sur un plus grand écran.",
      "Utilise la version web de Bumble",
      "Se connecter sur la version web de Bumble est simple. Il suffit de visiter notre site Web, de cliquer sur 'Se connecter' et de choisir ta méthode de connexion habituelle avec ton identifiant Apple, tes informations Facebook ou ton numéro de téléphone. Et voilà ! Tu es connecté et tes discussions et matchs sont exactement là où tu les as laissés.",
      "Connexion",
      "Connecte‑toi via ton navigateur",
      "Modifie ton profil et applique des Filtres Avancés.",
      "Confirme que c'est bien toi pour augmenter tes chances de trouver le Match idéal.",
      "Partage de nouvelles photos mignonnes ou supprime les plus anciennes.",
      "Mets à jour ton profil",
      "Fais savoir aux autres que c'est bien toi",
      "Ajoute ou supprime des photos",
      "Ce que tu peux faire sur la version web de Bumble",
      "Tu trouveras tes discussions et ta liste de matchs sur ta gauche, et des matchs potentiels sur ta droite.{{__EOL__}}{{__EOL__}}Utilise tes flèches gauche et droite, ou clique sur les symboles sur le côté droit de ton écran pour consulter les profils et donner un Like aux personnes comme tu le ferais sur ton téléphone.",
      "Comment utiliser la version web de Bumble ?",
      "Ici, la gentillesse est sexy, chacun est parfait tel qu'il est, et les femmes passent avant tout. Toujours.",
      "Le sanctuaire de Fais le premier pas™",
      "Les relations saines contribuent à une vie productive et positive. Bumble te permet d'en établir de la manière la plus gratifiante qui soit, qu'il s'agisse de rencontres amoureuses, d'amitié ou d'opportunités de carrière.",
      "Pourquoi Bumble fait toute la différence",
      "Nous prônons l'intégrité, la gentillesse, l'égalité, la confiance et le respect à tous les stades d'une relation. Nous célébrons toutes les orientations, que tu sois ici pour trouver l'amour ou simplement pour t'amuser.",
      "Les valeurs de Bumble",
      "Sur Bumble, ce sont les femmes qui donnent le ton en faisant le premier pas ou en définissant un Opening Move auquel leurs matchs peuvent répondre, changeant ainsi les conventions sociales dépassées et promouvant l'égalité dès le début.",
      "Comment Bumble fonctionne",
      "À propos de nous",
      "Télécharger",
      "C'est mieux dans l'appli.",
      "Des options conçues pour rendre les rencontres en ligne vraiment agréables",
      "12 février 2024",
      "Comment accroître l'intimité émotionnelle dans une relation",
      "25 janvier 2024",
      "Comment utiliser Bumble quand on n'a jamais utilisé d'appli de rencontre",
      "5 février 2024",
      "Deception Detector™",
      "Toute l'actualité du monde des rencontres",
      "Si la taille compte, rends‑toi sur notre site Web. C'est exactement comme notre appli, mais en plus grand.",
      "Application web",
      "Prends un moment pour toi avec le <b>mode Snooze</b>. Quand tu voudras revenir, tu sais où nous trouver.",
      "Fais une pause",
      "Utilise les <b>appels vidéo</b> et les <b>appels audio</b> pour te rapprocher de tes matchs sans quitter ton canapé.",
      "Dis-le à voix haute",
      "Afficher plus",
      "Choisis des <b>Opening Moves</b> pour inviter ton match à lancer la discussion et t'éviter d'avoir à le faire.",
      "Moins d'efforts, plus de rencontres",
      "Trouve ton bonheur n'importe où dans le monde grâce au <b>mode Voyage</b>.",
      "Prépare tes valises",
      "Son profil t'intéresse ? Fais-lui savoir en lui envoyant un <b>SuperSwipe</b>.",
      "Attire son attention",
      "Envoie des <b>Compliments</b> pour te démarquer.",
      "Donne le sourire",
      "Utilise le <b>mode Incognito</b> pour éviter que ton patron, tes proches ou tes ex ne voient ton profil.",
      "En mode furtif",
      "Fais des rencontres à ta façon",
      "Quelques mois plus tard, elle y a également rencontré une de ses demoiselles d'honneur.",
      "Hannah a rencontré son mari sur Bumble",
      "Avant de construire leur tiny house, Carène et You-Liang se sont rencontrés sur Bumble, où You-Liang a été attiré par les photos de voyage de Carène.",
      "Carène et You-Liang",
      "Brian et Kaci étaient en poste à l'étranger ensemble, mais ils se sont rencontrés sur Bumble.",
      "Brian et Kaci",
      "Si ça a marché pour eux, ça peut marcher pour toi",
      "Passe à l'étape suivante",
      "Voir les nouveautés",
      "Nous proposons d'excellents moyens pour te permettre de faire des rencontres sans stress.",
      "Mets la barre haut",
      "À propos de Bumble",
      "Nous sommes la seule appli qui améliore les rencontres en donnant la priorité à l'expérience des femmes. Parce que quand ça va mieux pour les femmes, ça va mieux pour tout le monde.",
      "Make the first move™",
      "Trouve quelqu'un qui te plait, puis fais le premier pas. Crée des liens authentiques, discute de vos centres d'intérêt communs, et surtout, amuse-toi bien.",
      "Rencontre quelqu'un avec Bumble Date",
      "Trouve des amis pour t'aider à faire progresser ta carrière. Entre en contact avec d'autres professionnels pour trouver ton prochain emploi, un mentor ou même une nouvelle carrière.",
      "Fais avancer ta carrière avec Bumble Bizz",
      "Rencontre quelqu'un qui te ressemble. Ou pas. Fais-toi des amis et trouvez des activités à faire ensemble, dans ta ville ou à l'étranger.",
      "Fais-toi des amis sur Bumble For Friends",
      "Pas juste pour trouver l'amour",
      "Bumble est l'appli de rencontre où les femmes font le premier pas. C'est l'appli qui a transformé les relations, les amitiés et le networking. Télécharge-la maintenant.",
      "Bumble sur {{social_network}}",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "Premium+",
      "Cette page est introuvable.",
      "Contact",
      "Se connecter",
      "Une erreur est survenue",
      "(ouvre une nouvelle fenêtre)",
      "FAQ",
      "Politique des cookies",
      "Premium",
      "Bumble - Le Blog",
      "Emploi",
      "Chat",
      "Villes",
      "Gérer les cookies",
      "Dépôt légal © 2006 - aujourd'hui. Bumble. Tous droits réservés.",
      "Rapport sur la confiance dans les rencontres",
      "Apple et le logo Apple sont des marques déposées d'Apple Inc.{{__EOL__}}Google Play est une marque déposée de Google LLC.",
      "Télécharger",
      "Facebook",
      "Pour nous suivre :",
      "Centre d'aide",
      "Instagram",
      "Langue",
      "LinkedIn",
      "Bumble fait partie de Bumble Inc.",
      "Politique de Confidentialité",
      "Safety Centre",
      "Plan du site",
      "Loi sur l'esclavage moderne",
      "Conditions Générales d'Utilisation",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "Compliments": {
            "plural": [
                  "compliments"
            ],
            "singular": [
                  "compliment"
            ]
      },
      "day": {
            "plural": [
                  "jours"
            ],
            "singular": [
                  "jour"
            ]
      },
      "hour": {
            "plural": [
                  "heures"
            ],
            "singular": [
                  "heure"
            ]
      },
      "minute": {
            "plural": [
                  "minutes"
            ],
            "singular": [
                  "minute"
            ]
      },
      "month": {
            "plural": [
                  "mois"
            ],
            "singular": [
                  "mois"
            ]
      },
      "second": {
            "plural": [
                  "secondes"
            ],
            "singular": [
                  "seconde"
            ]
      },
      "superswipe": {
            "plural": [
                  "SuperSwipes"
            ],
            "singular": [
                  "SuperSwipe"
            ]
      },
      "year": {
            "plural": [
                  "ans"
            ],
            "singular": [
                  "an"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
