import Localization, { TrustedValue } from 'lib/localization';

export default {
    lxInstantMatchHeroTitle({ qr_code }: { qr_code: string }) {
        return Localization.get('bumblesite@pages.features.instant-match.hero.title', {
            qr_code,
            __EOL__: TrustedValue.for('<br/>'),
        });
    },
    get lxInstantMatchHeroSubtitle() {
        return Localization.get('bumblesite@pages.features.instant-match.hero.subtitle');
    },
    get lxInstantMatchOneBlockTitle() {
        return Localization.get('bumblesite@pages.features.instant-match.one.block.title');
    },
    get lxInstantMatchOneBlockBodyText() {
        return Localization.get('bumblesite@pages.features.instant-match.one.block.body.text');
    },
    get lxInstantMatchOneBlockCta() {
        return Localization.get('bumblesite@pages.features.instant-match.one.block.cta');
    },
    get lxInstantMatchTwoBlockTitle() {
        return Localization.get('bumblesite@pages.features.instant-match.two.block.title', {
            __EOL__: TrustedValue.for('<br/>'),
        });
    },
    get lxInstantMatchTwoBlockBodyText() {
        return Localization.get('bumblesite@pages.features.instant-match.two.block.body.text');
    },
    get lxFeaturesDownloadBumbleCta() {
        return Localization.get('bumblesite@pages.features.download.bumble.cta');
    },
    get lxInstantMatchThreeBlockTitle() {
        return Localization.get('bumblesite@pages.features.instant-match.three.block.title', {
            __EOL__: TrustedValue.for('<br/>'),
        });
    },
    get lxInstantMatchThreeBlockBodyText() {
        return Localization.get('bumblesite@pages.features.instant-match.three.block.body.text');
    },
    get lxInstantMatchFourBlockTitle() {
        return Localization.get('bumblesite@pages.features.instant-match.four.block.title', {
            __EOL__: TrustedValue.for('<br/>'),
        });
    },
    get lxInstantMatchFourBlockBodyText() {
        return Localization.get('bumblesite@pages.features.instant-match.four.block.body.text');
    },
    // get lxInstantMatchFiveBlockTitle() {
    //     return Localization.get('bumblesite@pages.features.instant-match.five.block.title');
    // },
    get lxFeaturesFaqTitle() {
        return Localization.get('bumblesite@pages.features.faq.title');
    },
    get lxInstantMatchFaqQuestionOneTitle() {
        return Localization.get('bumblesite@pages.features.instant-match.faq.question.one.title');
    },
    get lxInstantMatchFaqQuestionOneAnswer() {
        return Localization.get('bumblesite@pages.features.instant-match.faq.question.one.answer');
    },
    get lxInstantMatchFaqQuestionTwoTitle() {
        return Localization.get('bumblesite@pages.features.instant-match.faq.question.two.title');
    },
    get lxInstantMatchFaqQuestionTwoAnswer() {
        return Localization.get('bumblesite@pages.features.instant-match.faq.question.two.answer');
    },
    get lxInstantMatchFaqQuestionThreeTitle() {
        return Localization.get('bumblesite@pages.features.instant-match.faq.question.three.title');
    },
    get lxInstantMatchFaqQuestionThreeAnswer() {
        return Localization.get(
            'bumblesite@pages.features.instant-match.faq.question.three.answer',
        );
    },
    get lxInstantMatchCtaText() {
        return Localization.get('bumblesite@pages.features.instant-match.cta.text', {
            __EOL__: TrustedValue.for('<br/>'),
        });
    },
};
