/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Indietro",
      "Scarica da App Store",
      "Disponibile su Google Play",
      "Sezione",
      "Diapositiva {{number}}",
      "Prossima diapositiva",
      "Diapositiva precedente",
      "Diapositiva",
      "Diapositiva {{number}} di {{total}}",
      "Non vendere né condividere le mie informazioni personali",
      "Non vendere né condividere le mie informazioni personali",
      "Invia complimenti su Bumble per dimostrare il tuo interesse e attira la sua attenzione ancora prima di collegarti. Prova subito",
      "Compliments | Come inviare un complimento | Bumble",
      "Una pagina personalizzata che evidenzia i profili più compatibili con te, aggiornata ogni giorno.",
      "Scopri | Trova persone compatibili | Bumble",
      "Fai nuovi incontri quando vuoi tu e decidi a chi mostrare il tuo profilo Bumble con la modalità Incognito. Scopri di più sull'utilizzo della modalità Incognito Bumble nella nostra guida.",
      "Modalità Incognito | Nascondi il tuo profilo | Bumble",
      "Dopo aver fatto conoscenza a un evento dal vivo di Bumble, vedi se c'è chimica continuando la conversazione sull'app.",
      "Collegamento rapido | Continua la conversazione sull'app | Bumble",
      "Scopri un nuovo modo per avviare la conversazione con la funzione Opening Moves di Bumble. Attira la sua attenzione, lancia un argomento e avvia chat più interessanti, più rapidamente.",
      "Opening Moves di Bumble | Bumble",
      "Vuoi staccare o prenderti un po' di tempo per te? Scopri come mettere in pausa il tuo account Bumble con la modalità Snooze di Bumble. Per fare nuovi incontri solo quando e come vuoi tu.",
      "Modalità Snooze | Metti in pausa il tuo account | Bumble",
      "Un profilo su Bumble ti ha davvero colpito? Usa un SuperSwipe per mostrare che fai sul serio. Tocca la stella sul suo profilo per iniziare, o scopri ulteriori dettagli qui",
      "Bumble SuperSwipe | Cos'è un SuperSwipe? | Bumble",
      "Viaggi per lavoro o sei in vacanza? La modalità Travel di Bumble può aiutarti a fare nuovi incontri in zona e a conoscere persone interessanti ovunque ti trovi. Scopri subito la modalità Travel qui",
      "Modalità Travel di Bumble | Fai nuovi incontri ovunque vai | Bumble",
      "Vuoi sapere se la sintonia che senti per messaggio è presente anche nella vita reale? Prova subito la funzione di chiamata o videochiamata su Bumble e scopri se c'è affinità",
      "Videochiamate | Fai una chiamata | Bumble",
      "Scopri la novità di Bumble Web, la nostra nuova fantastica funzione.",
      "Bumble Web | Bumble",
      "Disdici i contratti qui",
      "Home page di Bumble",
      "Chiudi menu",
      "Apri menu",
      "Selezione lingua",
      "Piè di pagina",
      "Accessibilità a Bumble",
      "Apple e il logo di Apple sono marchi di Apple Inc.",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble",
      "Lavora con noi",
      "Eventi",
      "Google Play è un marchio di Google LLC.",
      "Linee Guida",
      "Investitori",
      "Preferenze cookie",
      "Preferenze cookie",
      "Dichiarazione sulle leggi contro la schiavitù moderna",
      "Informativa sulla raccolta dati",
      "Informativa sulla privacy",
      "Consigli aggiornati per conoscere gente",
      "Termini & Condizioni",
      "L'app",
      "Informativa sulla privacy Washington Consumer Health",
      "Chi siamo",
      "Ambasciatori",
      "Blog",
      "Conosci gente e fai nuove amicizie",
      "Fai networking e coltiva la tua carriera",
      "Trova una relazione o niente di serio, a te la scelta",
      "Scopri di più",
      "Esplora il mondo con la modalità Travel",
      "Funzionalità",
      "Compliments",
      "Invia dei Compliments per farti notare e distinguerti da tutti.",
      "Scopri",
      "Una pagina personalizzata che evidenzia i profili più compatibili con te, aggiornata ogni giorno",
      "Modalità Incognito",
      "Usa la modalità Incognito per nascondere il tuo profilo da colleghi, parenti o anche ex.",
      "Collegamento rapido",
      "Avete iniziato una conversazione a un evento Bumble dal vivo? Continuatela sull'app.",
      "Opening Moves",
      "Scegli le Opening Moves per invitare i collegamenti ad avviare la conversazione, così non dovrai farlo tu.",
      "Modalità Snooze",
      "Prenditi un momento per te con la modalità Snooze. E quando vuoi tornare a conoscere gente, siamo qui ad aspettarti.",
      "SuperSwipe",
      "Il profilo si fa interessante? Faglielo sapere con un SuperSwipe.",
      "Modalità Travel",
      "Metti nuove conoscenze in valigia e trova gente in tutto il mondo con la modalità Travel.",
      "Videochiamata",
      "Usa le funzioni di videochiamata e chiamata vocale per scoprire se è tempo di organizzare un appuntamento di persona.",
      "Web app",
      "Prova l'esperienza di Bumble direttamente dal tuo browser.",
      "Shop",
      "Principale",
      "Vai al contenuto principale",
      "Logo di Bumble",
      "Vuoi approfondire la conoscenza? Prova le videochiamate su Bumble",
      "Per avviare una videochiamata, tocca semplicemente l'icona video nella tua chat. Non dovrai condividere alcuna informazione di contatto personale.",
      "Come funzionano le videochiamate su Bumble?",
      "No. Tutte le chiamate e le chat su Bumble mantengono le tue informazioni personali al sicuro. Nessuno avrà accesso al tuo numero o a qualsiasi altro dettaglio di contatto fino a quando non lo condividerai tu.",
      "Il collegamento potrà vedere il mio numero?",
      "Certamente! Se ancora non te la senti di parlare faccia a faccia, le chiamate vocali ti permettono di conversare solo tramite audio. Seleziona l'icona di chiamata invece di quella video.",
      "Posso provare solo le chiamate audio?",
      "È bello ricevere la notifica \"hai ricevuto un messaggio\", ma non è sempre facile capire se le vostre conversazioni saranno altrettanto interessanti di persona. Ed è qui che entrano in gioco le chiamate e le videochiamate.",
      "Videochiamate su Bumble",
      "Le <a href={{video_chat_url}}>chiamate e videochiamate</a> possono aiutarvi a conoscervi meglio e a decidere se portare la conversazione fuori dall'app. La parte migliore? Per effettuare una chiamata su Bumble, non devi fornire il tuo numero di telefono. Così, le tue informazioni personali rimangono al sicuro fino a quando non decidi tu di condividerle.",
      "Scopri se c'è affinità con una videochiamata",
      "Colma il divario tra il mondo online e offline, e decidi se è giunto il momento di spostare la conoscenza fuori dall'app.",
      "Conosci meglio (e più velocemente) il tuo collegamento. Una conversazione faccia a faccia o una chiamata possono rivelare molto su una persona.",
      "La decisione spetta a te. I tuoi dettagli di contatto rimarranno privati fino a quando non deciderai di condividerli.",
      "Scopri se è tempo di incontrarvi di persona",
      "Prova a vedere se c'è affinità",
      "Collegati senza condividere informazioni personali",
      "Perché dovresti usare chiamate e videochiamate",
      "Non vedi l'ora di approfondire la conoscenza? Puoi scegliere se avviare una chiamata o una videochiamata direttamente dalla tua chat nell'app di Bumble. Per avviare una videochiamata, tocca l'icona della videocamera grigia nell'angolo in alto a destra. Accanto all'icona vedrai tre puntini: toccali per visualizzare anche l'opzione per le chiamate audio.",
      "Come fare una videochiamata",
      "È tempo di provare i Compliments",
      "Quando invii un Compliment, la persona che lo riceve lo vedrà quando apparirai nella sua lista di collegamenti.",
      "Cosa succede quando fai un complimento a qualcuno su Bumble?",
      "Se un complimento ti ha colpito, fai scattare il collegamento. Vedrai il Compliment nella tua chat e saranno applicate le solite regole per <a href={{make_the_first_move_url}}>fare la prima mossa.</a>",
      "Come posso rispondere a un Compliment?",
      "Puoi inviare {{num}} [[Compliments:num]] gratuitamente. Se li esaurisci, puoi acquistarne altri nell'app di Bumble, in pacchetti fino a un massimo di 30. Uno di questi verrà utilizzato per inviare quel complimento (gli altri risparmiali per quando individui altri profili che ti piacciono). Non preoccuparti, non scadranno!",
      "Quanti Compliments posso inviare?",
      "Una parola gentile (o due) può davvero fare la differenza. Se vuoi inviare un Compliment ma non trovi le parole giuste da digitare, dai un'occhiata a questi esempi per trovare l'ispirazione:",
      "Vedi altri esempi",
      "“Se ti piace [nome artista], hai degli ottimi gusti.”",
      "\"Il tuo sorriso è contagioso!\"",
      "\"La tua bio mi ha fatto ridere! Hai un gran senso dell'umorismo.\"",
      "Come inviare complimenti migliori su Bumble",
      "Vuoi rendere speciale la sua giornata? È il momento giusto per inviare un complimento.",
      "Compliments su Bumble",
      "Usa i complimenti su Bumble per far sapere a un potenziale collegamento che il suo profilo ti ha colpito (e magari farlo arrossire). I Compliments sono inclusi nell'abbonamento {{service_level_lexeme_name}} e sono anche un ottimo modo per inviare e ricevere messaggi ancor prima che scatti il collegamento.",
      "Come funzionano i Compliments",
      "Quando ricevi un Compliment, apparirà sul profilo dell'utente che l'ha inviato. Riceverai anche una notifica che ti avviserà che qualcuno ha apprezzato una sezione del tuo profilo.",
      "Vedi i tuoi complimenti",
      "Puoi inviare un Compliment toccando il cuore giallo in basso nel profilo di qualcuno. Il punto in cui tocchi è importante, perché farai un complimento a quella specifica foto o parte del suo profilo! Quando clicchi, si aprirà una casella di testo per inserire il tuo messaggio (fino a un massimo di 150 caratteri).",
      "Fai un complimento",
      "SCARICA Bumble PER PROVARE SUBITO SCOPRI",
      "Ogni giorno, dalle 9, vedrai nuovi profili consigliati per te nella sezione Scopri e potrai collegarti con loro in base a ciò che vi accomuna.",
      "Quando si aggiorna la sezione Scopri?",
      "La tua pagina Scopri divide i potenziali collegamenti in 3 categorie: interessi simili, obiettivi condivisi e comunità condivise. Sulla pagina vedrai anche i profili consigliati per te, in base ai tuoi collegamenti precedenti.",
      "Come viene personalizzata la mia pagina Scopri?",
      "Una pagina personalizzata che evidenzia i profili più compatibili con te, aggiornata ogni giorno",
      "TROVA PERSONE IN SINTONIA CON TE SU {{qr_code}} SCOPRI",
      "La pagina Scopri ti mostra una selezione di persone che pensiamo potrebbero piacerti, in base a interessi simili, obiettivi condivisi e alle comunità di cui fate parte.",
      "Prova Scopri",
      "CERCHI UN NUOVO MODO PER TROVARE AFFINITÀ?",
      "La pagina Scopri viene aggiornata ogni giorno per mostrarti ancora più persone compatibili con te. Così, potrai passare meno tempo alla ricerca di collegamenti e concentrarti di più nell'approfondire la conoscenza con chi sai già essere in sintonia con te.",
      "CONSIGLI SEMPRE AGGIORNATI",
      "I suggerimenti su Scopri si basano sul tuo profilo e sui tuoi collegamenti precedenti. In questo modo, vedrai persone che condividono le tue passioni e cercano ciò che cerchi anche tu, che sia un delizioso burger vegano o una relazione stabile.",
      "SU MISURA{{__EOL__}}PER TE",
      "Scarica Bumble",
      "Domande frequenti",
      "È tempo di esplorare Bumble in incognito",
      "Quando attivi la modalità Incognito, il tuo profilo non sarà visibile agli altri utenti Bumble, a meno che tu non metta \"mi piace\" al loro profilo.",
      "Come funziona la modalità Incognito?",
      "Senza la modalità Incognito, il tuo profilo è visibile agli altri utenti di Bumble quando corrisponde alle loro preferenze, come età e posizione. Attivando la modalità Incognito, sarà visibile solo alle persone a cui hai messo \"mi piace\".",
      "Chi può vedermi in modalità Incognito su Bumble?",
      "Utilizzare la modalità Incognito non corrisponde a <a href={{blocking_on_bumble_url}}>bloccare qualcuno su Bumble</a>. Con questa funzione, se metti \"mi piace\" (o se hai inviato un messaggio in passato), quella persona potrà vedere il tuo profilo.",
      "La modalità Incognito è come bloccare qualcuno?",
      "Controlla chi può vedere il tuo profilo con la modalità Incognito di Bumble.",
      "Modalità Incognito su Bumble",
      "Se desideri fare un passo indietro dal dating o esplorare Bumble senza che altri utenti vedano il tuo profilo, puoi attivare la modalità Incognito. Attivando questa funzione, il tuo profilo verrà nascosto a tutti, a meno che tu non metta \"mi piace\" a qualcuno. In questo modo puoi controllare cosa condividi e chi può vederti.",
      "Nascondi il tuo profilo in modalità Incognito",
      "Decidi tu chi può vedere le tue foto e le tue informazioni personali.",
      "Vuoi aggiungere qualcosa di personale al tuo profilo? Puoi condividere discretamente ciò che vuoi solo con i tuoi collegamenti attuali.",
      "Naviga liberamente senza preoccuparti di incontrare un ex, un collega o qualsiasi altra persona che non faccia parte della tua cerchia di collegamenti.",
      "Tieni tutto sotto controllo",
      "Scegli cosa condividere",
      "Evita incontri imbarazzanti",
      "Perché adorerai la modalità Incognito",
      "La modalità Incognito è disponibile per gli utenti {{service_level_lexeme_name}} e {{service_level_lexeme_name_two}}. Una volta effettuata l'iscrizione, puoi attivarla dalla pagina del tuo profilo aprendo le impostazioni nell'angolo in alto a destra e selezionando la modalità Incognito.",
      "Passa a {{service_level_lexeme_name}}",
      "Come utilizzare la modalità Incognito su Bumble",
      "SCARICA Bumble PER PROVARE SUBITO IL COLLEGAMENTO RAPIDO",
      "Nell'app di Bumble, accedi alla sezione Profilo nella parte inferiore dello schermo. Lì troverai l'icona del codice QR nell'angolo in alto a destra dello schermo. Toccala per aprirla.",
      "Dove posso trovare il mio codice QR personale?",
      "No! Dovrai connetterti a Internet, tramite dati mobili o Wi-Fi, e utilizzare il codice QR in tempo reale perché il Collegamento rapido funzioni.",
      "Posso fare uno screenshot del mio codice QR per condividerlo?",
      "No! Se una persona scansiona il codice, il collegamento scatta automaticamente.",
      "Il codice QR dev'essere scansionato da entrambe le persone?",
      "Con la funzione Collegamento rapido puoi vedere automaticamente quali passioni e interessi condividete, per capire subito se potreste essere in sintonia.",
      "SCOPRI COSA VI ACCOMUNA, FIN DALL'INIZIO",
      "Avete iniziato una conversazione a un evento Bumble dal vivo? Continuatela sull'app.",
      "TRASFORMA IL TUO INCONTRO IN UN {{qr_code}} COLLEGAMENTO RAPIDO",
      "La funzione Collegamento rapido ti aiuta a trasformare il primo incontro in un \"voglio approfondire la conoscenza\" senza dover scambiare numero di telefono o profili social. Ti basterà far scansionare il tuo codice QR per collegarvi su Bumble.",
      "Prova Collegamento rapido",
      "HAI INCONTRATO QUALCUNO A UN EVENTO Bumble DAL VIVO?",
      "Invece di condividere il tuo numero o i tuoi profili social, la funzione Collegamento rapido ti permette di scoprire se c'è chimica già dall'app. In questo modo, potrai avviare la conoscenza se e come vuoi, grazie alle misure di sicurezza di Bumble.",
      "UN MODO PIÙ SICURO PER CONNETTERSI",
      "Dopo aver fatto conoscenza a un evento dal vivo di Bumble, continua ad approfondire la vostra affinità chiacchierando sull'app e scoprendo cosa vi accomuna, che sia la passione per il caffè americano o l'amore per le piante grasse.",
      "CONTINUA LA CONVERSAZIONE",
      "Prova Opening Moves su Bumble",
      "Opening Moves è un nuovo modo per avviare la conversazione con le persone che ti interessano. Una volta scelto il messaggio da inviare non ti resta che rilassarti, aspettare le risposte e replicare a quelle che ti colpiscono di più.",
      "Come posso usare le Opening Moves di Bumble?",
      "Nei collegamenti tra persone non binarie o dello stesso sesso, ognuna delle parti può impostare e rispondere a un'Opening Move.",
      "Posso usare le Opening Moves di Bumble per incontrare persone dello stesso sesso?",
      "Quando qualcuno risponde alla tua Opening Move, il suo profilo apparirà nelle tue chat. Le donne hanno 24 ore per rispondere prima che il collegamento scada. Da quel momento in poi, puoi commentare la sua risposta o avviare una nuova conversazione.",
      "Come posso vedere se qualcuno ha risposto alla mia Opening Move?",
      "Opening Move è il nostro modo per alleggerire la tensione quando si tratta di fare la prima mossa, offrendo una soluzione semplice e veloce per avviare conversazioni interessanti.",
      "Opening Moves su Bumble",
      "Grazie per avercelo chiesto. Opening Moves è un modo nuovo e più semplice per avviare una conversazione. Puoi scegliere una Mossa iniziale tra quelle suggerite, come \"Qual è la canzone che non può mai mancare a una festa?\", o scriverne una di tuo pugno. Se ne hai impostata una, i tuoi collegamenti potranno rispondere alle tue domande quando ti vedranno apparire sulla loro schermata dei collegamenti.",
      "Cos'è un'Opening Move?",
      "Rompere il ghiaccio",
      "Mostrare la tua personalità",
      "Avviare conversazioni più interessanti",
      "Opening Moves può aiutarti a...",
      "Puoi impostare una Mossa iniziale da inviare quando scatta un collegamento. Ti basterà andare nella sezione Opening Moves del tuo profilo, scegliere una delle domande suggerite oppure liberare la tua creatività scrivendone una tutta tua. Puoi modificare le tue Opening Moves ogni volta che vuoi.",
      "Prova Opening Moves",
      "Come usare le Opening Moves di Bumble",
      "<a href={{see_more_url}}>Vedi altri</a>",
      "Vuoi fare una pausa? Prova la modalità Snooze",
      "Sì, puoi disattivare la modalità Snooze quando vuoi dalle impostazioni o dalla tua lista di collegamenti.",
      "Posso disattivare la modalità Snooze in anticipo?",
      "Vai alle impostazioni, tocca Snooze e scegli per quanto tempo vuoi rimanere assente. Torna quando te la senti.",
      "Come posso impostare la modalità Snooze sul mio profilo?",
      "No. Mentre la modalità Snooze è attiva, tutte le interazioni con gli altri profili sono sospese. Se provi a visualizzare nuove persone, vedrai un messaggio che riporta la durata della modalità Snooze, con un'opzione per disattivarla.",
      "Posso scorrere i profili e chattare in modalità Snooze?",
      "Le persone con cui hai già scambiato messaggi vedranno il tuo stato \"assente\" (se scegli di impostarlo). In caso contrario, vedranno semplicemente i tuoi messaggi precedenti tra le loro chat.",
      "Cosa vedranno i miei collegamenti se imposto la modalità Snooze?",
      "Hai il pieno controllo delle tue attività su Bumble e puoi allontanarti dalla piattaforma in qualsiasi momento.",
      "Puoi tenere i tuoi contatti aggiornati modificando il tuo stato, così sapranno che non li stai <a href={{ghosted_article_url}}>ignorando</a>. È tutto automatizzato, quindi non dovrai inviare alcun messaggio.",
      "Prenditi una pausa dal dating senza perdere i tuoi collegamenti o le tue chat.",
      "Fai nuovi incontri (o disconnettiti) quando vuoi tu",
      "Tieni i tuoi contatti aggiornati",
      "Sospendi le tue attività, ma non i tuoi contatti",
      "Perché amiamo la modalità Snooze",
      "Apri l'app di Bumble, vai sul tuo profilo e tocca l'ingranaggio delle impostazioni nell'angolo in alto a destra. Nelle impostazioni, tocca Snooze e scegli per quanto tempo vuoi rimanere assente. Puoi nascondere il tuo profilo per 24 ore, 72 ore, una settimana o in modo indefinito.{{__EOL__}}{{__EOL__}}Ricorda, mentre sei in modalità Snooze i collegamenti senza chat attive scadranno. Se decidi di impostare uno stato, le persone con cui stai attualmente chattando potranno vedere che sei assente.",
      "Come funziona la modalità Snooze su Bumble",
      "Quando la vita diventa frenetica, la modalità Snooze è qui per aiutarti. Metti in pausa il tuo account Bumble e fai nuovi incontri solo quando vuoi tu.",
      "Modalità Snooze su Bumble",
      "La modalità Snooze è un modo semplice per sospendere temporaneamente il tuo account quando vuoi prenderti una pausa dal dating. Potrai comunque chattare con i tuoi contatti esistenti, ma il tuo profilo verrà nascosto agli altri potenziali collegamenti fino a quando non vorrai ricominciare a scorrere i profili. Attivando la modalità Snooze, non perderai le informazioni sul tuo profilo né i tuoi attuali collegamenti.",
      "Sospendi il tuo profilo Bumble",
      "Mentre sei in modalità Snooze, puoi far sapere ai tuoi collegamenti che hai deciso di prenderti una pausa aggiornando il tuo stato. Puoi scegliere tra \"Sono in viaggio\", \"Ho la testa sul lavoro\", \"Voglio staccare la spina\" e \"Mi sto prendendo cura di me\". Goditi la tua pausa, al resto ci pensiamo noi.",
      "Aggiungi lo stato \"Assente\"",
      "Attivando la modalità Snooze metterai in pausa il tuo account Bumble (o {{page_partner_name_bff}}). I tuoi collegamenti non sapranno che sei in questa modalità a meno che tu non decida di condividerlo. Se decidi di uscire dalla modalità Snooze prima del periodo da te selezionato, ti basterà tornare alle impostazioni e toccare \"Disattiva modalità Snooze\". Facile come bere un bicchier d'acqua!",
      "Sospendi le tue chat",
      "Prova SuperSwipe su Bumble",
      "Quanti ne vuoi! Gli utenti {{service_level_lexeme_name}} hanno a disposizione {{num}} [[superswipe:num]] ogni settimana, ma puoi ricaricare il tuo account in qualsiasi momento. Gli utenti non abbonati possono acquistare pacchetti di SuperSwipe.",
      "Quanti SuperSwipe posso inviare su Bumble?",
      "Quando qualcuno usa un SuperSwipe sul tuo profilo, riceverai subito una notifica. Inoltre, vedrai un'etichetta sopra il nome di quella persona sul suo profilo, che ti farà sapere che le piaci.",
      "Come faccio a sapere se ho ricevuto un SuperSwipe da qualcuno?",
      "Il suo profilo ti ha davvero colpito? C'è un modo perfetto per rompere il ghiaccio. Invia un SuperSwipe per mostrare il tuo interesse e inizia ad approfondire la conoscenza, più velocemente.",
      "SuperSwipe su Bumble",
      "Farsi notare non è sempre facile, ma un SuperSwipe può aiutarti a fare un'ottima prima impressione.{{__EOL__}}{{__EOL__}}Quando usi un SuperSwipe, la persona che ti interessa riceverà una notifica e apparirai in prima fila nella sua lista, con un'etichetta sul tuo profilo. Se ricambia il tuo \"mi piace\", potrete subito iniziare a chattare.",
      "Cos'è un SuperSwipe?",
      "Lanciarti e farti notare",
      "Avviare più conversazioni",
      "Trovare più collegamenti",
      "SuperSwipe può aiutarti a...",
      "Se hai <a href={{bumble_boost_url}}>Bumble {{service_level_lexeme_name}}</a> o Bumble {{service_level_lexeme_name_one}} hai a disposizione {{num}} [[superswipe:num]] gratuiti alla settimana. Ma tutti gli utenti possono acquistare pacchetti di massimo {{num_two}} [[superswipe:num_two]] dal proprio profilo. Se hai {{service_level_lexeme_name_three}}, invece, avrai {{num_three}} [[superswipe:num_three]] alla settimana.{{__EOL__}}{{__EOL__}}Usare SuperSwipe è semplice: quando vedi qualcuno che ti piace, ti basterà toccare la stellina gialla in basso nel suo profilo.",
      "Come usare i SuperSwipe",
      "Apple e il logo di Apple sono marchi di Apple Inc. Google Play è un marchio di Google LLC.",
      "Fai nuovi incontri ovunque ti trovi con la modalità Travel di Bumble",
      "La modalità Travel è disponibile per gli <a href={{bumble_premium_url}}>utenti {{service_level_lexeme_name}}</a>. Attivala per visualizzare nuovi profili interessanti nella città che hai scelto.",
      "Come posso utilizzare la modalità Travel?",
      "Praticamente sì. Sono presenti alcune limitazioni di sicurezza che possono variare, ma la modalità Travel è disponibile nella maggior parte delle destinazioni.",
      "Posso usare la modalità Travel ovunque?",
      "Con la modalità Travel la tua posizione verrà impostata nel centro della città che hai scelto, così i nuovi contatti in zona potranno trovarti. Vedranno una nota sul tuo profilo che indica che sei in modalità Travel, quindi sapranno che sei in viaggio o che hai in programma di visitare la città.",
      "Stai preparando le valigie per una vacanza o hai in programma una trasferta di lavoro? Attiva la modalità Travel di Bumble per portare gli appuntamenti in viaggio con te.",
      "Modalità Travel su Bumble",
      "La modalità Travel è lo strumento perfetto per fare nuove conoscenze anche quando sei in viaggio. Imposta la tua posizione in qualsiasi parte del mondo e il tuo profilo verrà mostrato alle persone che si trovano in quella città. In questo modo, puoi creare nuovi legami speciali ovunque le tue avventure ti portino.",
      "Cos'è la modalità Travel?",
      "Nessuno conosce un luogo meglio della gente del posto. La modalità Travel può aiutarti a incontrare persone fantastiche per rendere il tuo viaggio ancora più speciale.",
      "Imposta la tua posizione nella città fino a sette giorni prima di partire, così potrai trovare collegamenti e pianificare gli incontri in anticipo.",
      "Non pensare solo alla classica gita al mare: che tu sia in ferie, in viaggio per lavoro o in una nuova città, non perdere l'occasione di incontrare persone speciali ovunque tu vada.",
      "Trova i migliori posti della zona",
      "Vivi una nuova avventura",
      "Crea legami duraturi",
      "Motivi per amare la modalità Travel",
      "La modalità Travel è disponibile per gli utenti <a href={{bumble_premium_url}}>{{service_level_lexeme_name}} e {{service_level_lexeme_name_two}} </a> di Bumble. Tocca l'ingranaggio delle impostazioni nell'angolo in alto a destra del tuo profilo e scorri fino a trovare la modalità Travel. Seleziona la tua nuova posizione per iniziare a vedere persone in quella zona. Il tuo profilo informerà i potenziali collegamenti che sei in modalità Travel.",
      "Come funziona la modalità Travel?",
      "Prova ora",
      "Prova Bumble sul web",
      "Certo! La web app di Bumble è accessibile su PC, Mac e tablet e su tutti i principali browser.",
      "Posso utilizzare Bumble sul mio PC o Mac?",
      "Sì. Nella web app di Bumble puoi inviare messaggi ed effettuare chiamate o videochiamate.",
      "Posso chattare con i miei collegamenti nella web app?",
      "Per accedere, seleziona \"Accedi\" o clicca <a href={{sign_in_url}}>qui</a>. Puoi collegarti utilizzando il tuo Apple ID, Facebook o il tuo numero di telefono.",
      "Come posso accedere a Bumble sul web?",
      "Hai bisogno di una pausa dallo schermo del tuo telefono? Prova la web app di Bumble. La stessa esperienza Bumble di sempre, ma su uno schermo più grande.",
      "Usa Bumble sul web",
      "Accedere a Bumble sul web è semplicissimo. Ti basterà visitare il nostro sito, cliccare su \"Accedi\", scegliere la tua modalità di accesso preferita tra Apple ID, credenziali Facebook o numero di telefono, e il gioco è fatto! Una volta effettuato l'accesso, troverai le tue chat e i tuoi collegamenti lì ad aspettarti.",
      "Accedi",
      "Accedi dal tuo browser",
      "Modifica o dai una rinfrescata al tuo profilo e applica i Filtri avanzati.",
      "Conferma la tua identità per aumentare le possibilità di trovare il collegamento perfetto.",
      "Condividi nuove foto carine o elimina quelle vecchie.",
      "Aggiorna il tuo profilo",
      "Mostra che sei chi dici di essere",
      "Aggiungi o rimuovi foto",
      "Cosa puoi fare su Bumble versione web",
      "Troverai le tue chat e l'elenco dei contatti sul lato sinistro e i nuovi potenziali collegamenti sul lato destro.{{__EOL__}}{{__EOL__}}Usa le frecce sinistra e destra o clicca i simboli sul lato destro dello schermo per visualizzare i profili e mettere \"mi piace\", proprio come dal tuo cellulare.",
      "Come posso usare Bumble sul web?",
      "Qui la gentilezza è sexy, essere sé stessi significa essere perfetti e le donne vengono prima di tutti. Sempre.",
      "È qui che si fa la prima mossa",
      "Delle relazioni sane sono cruciali per vivere una vita positiva e produttiva. Bumble è pensato per aiutarti a instaurare queste connessioni in piena sicurezza di te, che tu sia alla ricerca della persona speciale, di un'amicizia genuina o di espandere la tua rete professionale.",
      "Perché Bumble fa la differenza",
      "Promuoviamo integrità, gentilezza, uguaglianza, sicurezza e rispetto durante tutte le fasi di una relazione. E non ci limitiamo ad accogliere tutti gli orientamenti, ma li celebriamo sempre, sia per chi vuole trovare l'amore che per chi vuole semplicemente divertirsi.",
      "I valori di Bumble",
      "Su Bumble sono le donne a iniziare, facendo la prima mossa o scegliendo un'Opening Move a cui i collegamenti possono rispondere. Abbiamo deciso di dare un bella shakerata al mondo del dating e mettere l'uguaglianza in prima linea.",
      "Come funziona Bumble",
      "Chi siamo",
      "Scarica subito",
      "È meglio sull'app.",
      "Funzioni pensate per fare incontri online in modo piacevole, sul serio",
      "12 febbraio 2024",
      "Come avere più intimità emotiva in una relazione",
      "25 gennaio 2024",
      "Come usare Bumble se è la tua prima app di incontri",
      "5 febbraio 2024",
      "Deception Detector™",
      "Tutte le novità nel mondo del dating",
      "Se per te le dimensioni contano, visita il nostro sito. È uguale alla nostra app, ma più in grande.",
      "Web app",
      "Prenditi un momento per te con la <b>modalità Snooze</b>. E quando vuoi tornare a conoscere gente, siamo qui ad aspettarti.",
      "Fai una pausa",
      "Fai <b>chiamate</b> e <b>videochiamate</b> per avere un assaggio dell'incontro reale, dal comfort di casa.",
      "A quattr'occhi (e orecchie)",
      "Vedi altri",
      "Scegli le <b>Opening Moves</b> per invitare i collegamenti ad avviare la chat, così non dovrai farlo tu.",
      "Meno da fare, più incontri",
      "Metti nuove conoscenze in valigia e trova gente in tutto il mondo con la <b>modalità Travel</b>.",
      "Dove si va?",
      "Il profilo si fa interessante? Faglielo sapere con un <b>SuperSwipe</b>.",
      "Cattura l'attenzione",
      "Invia dei <b>Compliments</b> per farti notare e distinguerti da tutti.",
      "Fai arrossire chi ti piace",
      "Usa la <b>modalità Incognito</b> per nascondere il tuo profilo da colleghi, parenti o anche ex.",
      "Vai sotto copertura",
      "Fai incontri a modo tuo",
      "Qualche mese dopo, ha anche conosciuto la ragazza che le ha fatto da damigella.",
      "Hannah ha conosciuto su Bumble l'uomo che ha poi sposato.",
      "Prima di costruire insieme la loro piccola e deliziosa casa, Carène e You-Liang si sono incontrati su Bumble. Sono state le foto dei viaggi di Carène a far scattare l'interesse.",
      "Carène e You-Liang",
      "Brian e Kaci lavoravano nella stessa città all'estero, ma si sono conosciuti su Bumble",
      "Brian e Kaci",
      "Se ha funzionato per loro, può funzionare per te",
      "Fai la prossima mossa",
      "Scopri le novità",
      "Ti meriti il meglio, per questo abbiamo pensato a ottimi modi per conoscere più gente ma con meno stress.",
      "Perché accontentarti quando puoi avere di più?",
      "Info su Bumble",
      "Siamo l'unica app che rende gli incontri online migliori mettendo al primo posto l'esperienza della donna. Perché se le cose vanno meglio per le donne, allora vanno meglio per tutti.",
      "Make the first move™",
      "Trova qualcuno che ti interessa davvero e fai la prima mossa. Crea collegamenti sinceri basati su interessi in comune e, soprattutto, divertiti.",
      "Conosci una persona speciale con Bumble Date",
      "Nuove conoscenze professionali per cercare il tuo prossimo lavoro, trovare qualcuno che possa farti da guida o anche cambiare completamente carriera.",
      "Coltiva la tua carriera con Bumble Bizz",
      "Trova qualcuno come te o l'esatto contrario. Crea nuove amicizie e scopri attività da fare insieme, sia a casa che in viaggio.",
      "Trova nuovi amici su Bumble For Friends",
      "Non solo incontri romantici",
      "Bumble è l'app di dating dove è la donna a fare la prima mossa. È sinonimo di cambiamento negli incontri online, sia in amore, in amicizia che nel networking. Scaricala subito.",
      "Bumble su {{social_network}}",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "Premium+",
      "Siamo spiacenti, ma la pagina che cerchi non è stata trovata.",
      "Contattaci",
      "Accedi",
      "Ops… qualcosa è andato storto",
      "(opens in new window)",
      "Domande frequenti",
      "Cookie Policy",
      "Premium",
      "Bumble - The Blog",
      "Lavora con noi",
      "Chat",
      "Città",
      "Preferenze cookie",
      "Copyright © 2006 – presente. Bumble. Tutti i diritti riservati.",
      "Appuntamenti in tutta tranquillità: report",
      "Apple e il logo di Apple sono marchi di Apple Inc.{{__EOL__}}Google Play è un marchio di Google LLC.",
      "Scarica",
      "Facebook",
      "Seguici",
      "AIuto",
      "Instagram",
      "Lingua",
      "LinkedIn",
      "Bumble fa parte di Bumble Inc.",
      "Informativa sulla privacy",
      "Safety Centre",
      "Mappa del sito",
      "Dichiarazione sulle leggi contro la schiavitù moderna",
      "Condizioni d'utilizzo",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "Compliments": {
            "plural": [
                  "complimenti"
            ],
            "singular": [
                  "complimento"
            ]
      },
      "day": {
            "plural": [
                  "giorni"
            ],
            "singular": [
                  "giorno"
            ]
      },
      "hour": {
            "plural": [
                  "ore"
            ],
            "singular": [
                  "ora"
            ]
      },
      "minute": {
            "plural": [
                  "minuti"
            ],
            "singular": [
                  "minuto"
            ]
      },
      "month": {
            "plural": [
                  "mesi"
            ],
            "singular": [
                  "mese"
            ]
      },
      "second": {
            "plural": [
                  "secondi"
            ],
            "singular": [
                  "secondo"
            ]
      },
      "superswipe": {
            "plural": [
                  "SuperSwipe"
            ],
            "singular": [
                  "SuperSwipe"
            ]
      },
      "year": {
            "plural": [
                  "anni"
            ],
            "singular": [
                  "anno"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
