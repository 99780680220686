/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Wróć",
      "Pobierz w App Store",
      "Pobierz w Google Play",
      "Karuzela",
      "Slajd {{number}}",
      "Następny slajd",
      "Poprzedni slajd",
      "Slajd",
      "Slajd {{number}} z {{total}}",
      "Nie sprzedawaj ani nie udostępniaj moich danych osobowych",
      "Nie sprzedawaj ani nie udostępniaj moich danych osobowych",
      "Wysyłaj Compliments w Bumble, aby pokazać, że podoba Ci się czyjś profil i zwrócić na siebie uwagę przed dopasowaniem. Wypróbuj teraz",
      "Compliments | Jak wysłać Compliment | Bumble",
      "Spersonalizowana strona, która wyróżnia najbardziej kompatybilne z Tobą osoby. Codziennie ktoś nowy.",
      "Znajdź | Znajdź kompatybilne osoby | Bumble",
      "Randkuj w swoim tempie i miej kontrolę nad tym, kto widzi Twój profil Bumble, korzystając z Trybu Incognito. Dowiedz się więcej o korzystaniu z tego trybu w naszego przewodnika w Bumble",
      "Tryb Incognito | Ukryj swój profil | Bumble",
      "Po spotkaniu na żywo podczas eventu Bumble idź za ciosem, kontynuując rozmowę w aplikacji.",
      "Szybkie dopasowanie | Kontynuuj rozmowę w aplikacji | Bumble",
      "Wypróbuj nowej metody rozpoczęcia rozmowy z funkcją Opening Moves w Bumble. Zwróć na siebie uwagę, dobierz nastrój i szybciej zacznij ciekawe rozmowy",
      "Opening Moves Bumble | Bumble",
      "Potrzebujesz krótkiej przerwy lub chwili dla siebie? Dowiedz się, jak wstrzymać aktywność konta Bumble za pomocą Trybu uśpienia Bumble. Pozwala on Ci randkować według swoich zasad.",
      "Tryb uśpienia | Wstrzymaj aktywność konta | Bumble",
      "Właśnie w Bumble wpadł Ci w oko ktoś naprawdę super? Wykorzystaj SuperSwipe, by okazać swoje zainteresowanie. Kliknij gwiazdkę na profilu tej osoby, aby rozpocząć, lub zobacz więcej szczegółów tutaj",
      "SuperSwipe Bumble | Co to jest SuperSwipe? | Bumble",
      "Wyjazd służbowy lub dla rozrywki? Tryb podróży w Bumble może pomóc Ci nawiązać nowe, fajne kontakty w nowym miejscu i randkować gdziekolwiek jesteś. Poznaj Tryb podróży już teraz",
      "Tryb podróży Bumble | Randkuj wszędzie, gdziekolwiek jesteś | Bumble",
      "Chcesz wiedzieć, czy chemia, którą łączy was w wiadomościach, będzie taka sama w realu? Wypróbuj rozmowy video lub połączenia głosowe w Bumble i sprawdź, czy to jest ten vibe\n\n\nNow please",
      "Czat video | Zadzwoń | Bumble",
      "Poznaj ekscytujące funkcje Bumble Web.",
      "Bumble Web | Bumble",
      "Rozwiąż umowy tutaj",
      "Strona główna Bumble",
      "Zamknij menu",
      "Otwórz menu",
      "Wybór języków",
      "Stopka",
      "Dostępność w Bumble",
      "Apple oraz logo Apple są znakami towarowymi Apple Inc.",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Praca",
      "Wydarzenia",
      "Google Play to znak towarowy Google LLC.",
      "Zasady użytkowania",
      "Inwestorzy",
      "Zarządzaj plikami cookie",
      "Zarządzaj plikami cookie",
      "Oświadczenie na mocy brytyjskiej Ustawy o współczesnym niewolnictwie",
      "Powiadomienie w przypadku gromadzenia danych",
      "Polityka Prywatności",
      "Sezonowe porady randkowe",
      "Regulamin",
      "Aplikacja",
      "Polityka prywatności danych Washington Consumer Health",
      "O nas",
      "Ambasadorzy marki",
      "Blog",
      "Nawiązuj nowe znajomości i znajdź swoją społeczność.",
      "Stwórz siatkę kontaktów zawodowych i rozwijaj swoją karierę.",
      "Znajdź kogoś do związku, czegoś nieformalnego lub czegoś pomiędzy.",
      "Dowiedz się więcej",
      "Podbij świat z Trybem podróży",
      "Funkcje",
      "Compliments",
      "Nie szczędź komplementów, aby wyróżnić się z tłumu.",
      "Znajdź",
      "Spersonalizowana strona, która wyróżnia najbardziej kompatybilne z Tobą osoby. Codziennie ktoś nowy.",
      "Tryb Incognito",
      "Użyj Trybu Incognito, aby ukryć swój profil przed szefową, krewnymi lub byłymi.",
      "Szybkie dopasowanie",
      "Udało Ci się kogoś poznać podczas eventu na żywo Bumble? Kontynuujcie znajomość w apce.",
      "Opening Moves",
      "Wybierz Opening Moves, aby to Twoje dopasowanie zaczęło rozmowę zamiast Ciebie.",
      "Tryb uśpienia",
      "Zrób sobie przerwę od randek z Trybem uśpienia. Kiedy znowu poczujesz gotowość do randek, wiesz, gdzie nas znaleźć.",
      "SuperSwipe",
      "Odpowiada Ci ten profil? Użyj SuperSwipe i daj tej osobie znać.",
      "Tryb podróży",
      "Przejdź w Tryb podróży i znajdź nie tylko przekąski w sklepie bezcłowym, ale i dopasowania – w dowolnym miejscu na świecie.",
      "Czat video",
      "Skorzystaj z czatów video i rozmów audio, aby sprawdzić, czy to już czas na spotkanie twarzą w twarz.",
      "Apka internetowa",
      "Skorzystaj z Bumble bezpośrednio z przeglądarki.",
      "Sklep",
      "Główna",
      "Przejdź do głównej treści",
      "Logo Bumble",
      "Chcesz kogoś poznać? Skorzystaj z czatu video w Bumble.",
      "Po prostu kliknij ikonę video w swoim czacie, aby rozpocząć połączenie video – bez potrzeby udostępniania żadnych osobistych danych kontaktowych.",
      "Jak działa czat video w Bumble?",
      "Nie. Wszystkie rozmowy i czaty w Bumble zapewniają bezpieczeństwo i poufność Twoich danych osobowych. Twój numer telefonu ani żadne dane kontaktowe nie będą ujawnione, dopóki Ty ich nie podasz.",
      "Czy moje dopasowanie zobaczy mój numer telefonu?",
      "Jasne! Jeśli nie jesteś gotowy(-wa) na rozmowę twarzą w twarz, skorzystaj z połączenia głosowego. Wystarczy wybrać ikonę rozmowy audio.",
      "Czy mogę spróbować połączenia audio?",
      "Każdy z nas lubi powiadomienia o dostarczonych wiadomościach. Ale wiemy też, że często trudno ocenić, czy rozmowy na czacie okażą się równie dobre na żywo. Tu z pomocą przychodzą rozmowy audio i video.",
      "Czat video w Bumble",
      "<a href={{video_chat_url}}>Rozmowy video i głosowe</a> mogą pomóc wam lepiej się poznać i zdecydować, czy chcecie przenieść znajomość poza aplikację. A co najlepsze, kiedy wykonujecie połączenie przez Bumble, nie musicie wymieniać numerów telefonów – wasze dane osobowe są bezpieczne aż do momentu, w którym zdecydujecie się je udostępnić.",
      "Sprawdźcie, czy jest między wami chemia dzięki rozmowie video",
      "Połącz randkowanie online i offline i zdecyduj, czy nadszedł czas na spotkanie twarzą w twarz.",
      "Poznajcie się lepiej (i szybciej). Rozmowa twarzą w twarz lub przez telefon może wiele o kimś powiedzieć.",
      "Masz wszystko pod kontrolą. Twoje dane kontaktowe nie zostaną przez nas ujawnione. To od Ciebie zależy, kiedy je udostępnisz.",
      "Czas spotkać się osobiście?",
      "Szybki sprawdzian czyjegoś vibe'u",
      "Kontakt bez ujawniania danych osobowych",
      "Dlaczego warto korzystać z czatów audio i video",
      "Chcesz zacząć rozmowę? Możesz wybrać między rozmową audio i video bezpośrednio w Twoim czacie w Bumble. Aby rozpocząć rozmowę video, kliknij ikonę kamery video w prawym górnym rogu. Obok ikonki znajdują się trzy kropki. Kliknij je, aby zobaczyć opcję rozmowy audio.",
      "Jak rozmawiać przez czat video",
      "Chcesz wypróbować Compliments?",
      "Kiedy wyślesz Compliment, osoba, która go otrzyma, zobaczy go, gdy pojawisz się w jej kolejce.",
      "Co się dzieje, kiedy wysłasz komuś w Bumble Compliment?",
      "Jeśli czyjś Compliment zwrócił Twoją uwagę, śmiało stwórzcie dopasowanie. Compliment wyświetli się w Twoim czacie i obowiązywać będą takie zasady, jak w przypadku <a href={{make_the_first_move_url}}> Zrobienia pierwszego kroku</a>.",
      "Jak odpowiedzieć na Compliment?",
      "Możesz wysłać {{num}} [[Compliments:num]] za darmo. Kiedy się skończą, możesz kupić więcej w aplikacji Bumble w pakietach do 30. Jeden z nich zostanie użyty do wysłania Compliment (zachowaj resztę na przyszłość, gdy zobaczysz inny profil, który Ci się podoba). Nie martw się, Compliments nie mają terminu ważności!",
      "Ile Compliments mogę wysłać?",
      "Miłe słowo (lub dwa) to zawsze dobry pomysł. Jeśli chcesz wysłać komplement, ale nie masz pojęcia co napisać, wypróbuj te inspiracje:",
      "Zobacz więcej przykładów",
      "„Każdy, kto lubi [nazwa artysty/artystki], ma dobry gust.”",
      "„Twój uśmiesz jest zaraźliwy!”",
      "„Twoje bio mnie rozbawiło. Dobre zagranie.”",
      "Jak wysyłać fajniejsze komplementy w Bumble",
      "Chcesz sprawić komuś przyjemność? Czas użyć Compliments!",
      "Compliments w Bumble",
      "Użyj Compliments w Bumble i daj potencjalnemu dopasowaniu znać, że Ci się podoba (wywołując przy tym rumieńce). Compliments otrzymujesz w ramach swojej członkostwa w {{service_level_lexeme_name}}. To także sposób na wysyłanie i odbieranie wiadomości jeszcze przed dopasowaniem.",
      "Jak działa funkcja Compliments",
      "Kiedy otrzymasz Compliment, pojawi się to na profilu nadawcy. Otrzymasz również powiadomienie, że komuś podoba się coś w Twoim profilu.",
      "Zobacz otrzymane komplementy",
      "Możesz wysłać Compliment, klikając ikonę żółtego serca na dole czyjegoś profilu. Miejsce, w którym klikniesz, jest bardzo ważne, ponieważ będziesz komplementować konkretne zdjęcie lub jakąś część profilu tej osoby! Kiedy klikniesz, otworzy się okno tekstowe, w którym możesz napisać swoją wiadomość (wiadomość może zawierać maks. 150 znaków).",
      "Wyślij komplement",
      "POBIERZ Bumble I WYPRÓBUJ FUNKCJĘ ZNAJDŹ",
      "Codzienne o 9 rano na Twojej stronie Znajdź pojawią się nowe wybrane dla Ciebie osoby. Możesz nawiązać z nimi znajomość na podstawie tego, co was łączy.",
      "Kiedy aktualizuje się strona Znajdź?",
      "Twoja strona Znajdź dzieli potencjalne dopasowania na 3 główne kategorie: podobne zainteresowania, te same cele randkowe oraz wspólne społeczności. Znajdziesz tu także rekomendacje profili na podstawie wcześniejszych dopasowań.",
      "Jak wygląda selekcja osób na stronie Znajdź?",
      "Spersonalizowana strona, która wyróżnia najbardziej kompatybilne z Tobą osoby. Codziennie ktoś nowy.",
      "POZNAJ LUDZI, KTÓRZY MAJĄ TWÓJ VIBE, DZIĘKI {{qr_code}} FUNKCJI ZNAJDŹ",
      "Funkcja Znajdź pokazuje Ci selekcję osób, które naszym zdaniem Ci się sposobają – na podstawie waszych wspólnych zainteresowań, celów randkowych i społeczności.",
      "Wypróbuj Znajdź",
      "SZUKASZ NOWEGO SPOSOBU, BY ZNALEŹĆ NIĆ POROZUMIENIA?",
      "Strona Znajdź jest aktualizowana codziennie, aby pokazać Ci jeszcze więcej kompatybilnych z Tobą ludzi. Dzięki temu poświęcasz mniej czasu na poszukiwanie dopasowań, a więcej na głębszą wymianę myśli z tymi, z którymi wiesz, że będziecie się dobrze rozumieć.",
      "CODZIENNIE ŚWIEŻE REKOMENDACJE",
      "Rekomendacje w zakładce Znajdź oparte są na Twoim profilu i  wcześniejszych dopasowaniach. Dlatego znajdziesz tam osoby, które lubią to, co Ty i chcą tego samego, co Ty – niezależnie od tego, czy chodzi o wegańskie rollsy z homara, czy o długotrwałe związki.",
      "DOPASOWANE{{__EOL__}}\nDO CIEBIE",
      "Pobierz Bumble",
      "Najczęściej zadawane pytania",
      "Chcesz przeglądać Bumble w bardziej osobisty sposób?",
      "Jeśli włączysz Tryb Incognito, Twój profil nie będzie widoczny dla innych członków Bumble, chyba że polubisz ich profil.",
      "Jak działa Tryb Incognito?",
      "Bez Trybu Incognito Twój profil jest widoczny dla innych użytkowników Bumble, kiedy pasujesz do ich preferencji, takich jak wiek czy lokalizacja. Kiedy włączysz Tryb Incognito, Twój profil będzie widoczny tylko dla polubionych przez Ciebie osób.",
      "Kto może zobaczyć mój profil w Trybie Incognito Bumble?",
      "Używanie Trybu Incognito to nie to samo, co <a href={{blocking_on_bumble_url}}>blokowanie kogoś w Bumble</a>. Dzięki tej funkcji Twój profil będzie się wyświetlał osobom, które Ci się spodobają lub które dostały od Ciebie wiadomość w przeszłości.",
      "Czy Tryb Incognito działa jak blokowanie kogoś?",
      "Kontroluj, kto widzi Twój profil, dzięki Trybowi Incognito Bumble.",
      "Tryb Incognito w Bumble",
      "Jeśli chcesz odpocząć od randkowania lub przeglądać Bumble tak, aby inni użytkownicy nie widzieli Twojego profilu, włącz Tryb Incognito. Aktywowanie tej funkcji oznacza, że Twój profil nie będzie dla nikogo widoczny – z wyjątkiem sytuacji, kiedy Ty najpierw kogoś polubisz. Dzięki temu możesz kontrolować, co udostępniasz i kto widzi Twój profil.",
      "Ukryj swój profil z Trybem Incognito",
      "Ty decydujesz, kto może zobaczyć Twoje zdjęcia i dane osobowe.",
      "Chcesz dodać coś osobistego na swoim profilu? Możesz dyskretnie udostępnić swoim aktualnym dopasowaniom to, co chcesz.",
      "Przeglądaj swobodnie, nie martwiąc się, że natkniesz się na ex, znajomą z pracy lub kogokolwiek innego, kto nie znajduje się w kręgu Twoich zainteresowań.",
      "Przejmij pełną kontrolę",
      "Wybierz, co chcesz udostępnić",
      "Unikaj niezręcznych interakcji",
      "Za co pokochasz Tryb Incognito",
      "Tryb Incognito jest dostępny dla członków {{service_level_lexeme_name}} i {{service_level_lexeme_name_two}}. Po zarejestrowaniu się możesz go włączyć, idąc do zakładki profilu, klikając Ustawienia w prawym górnym rogu i przełączając się na Tryb Incognito.",
      "Zaktualizuj do {{service_level_lexeme_name}}",
      "Jak używać Trybu Incognito w Bumble",
      "POBIERZ Bumble I JUŻ DZIŚ WYPRÓBUJ SZYBKIE DOPASOWANIE",
      "W aplikacji Bumble przejdź do ikony Profilu na dole ekranu. W prawym górnym rogu Profilu znajdziesz ikonę kodu QR. Kliknij tę ikonę, aby uzyskać dostęp.",
      "Gdzie mogę znaleźć swój osobisty kod QR?",
      "Nie! Aby uruchomić Szybkie dopasowanie, musisz mieć połączenie z Internetem (albo przez dane mobilne, albo Wi-Fi) i użyć aktualnego kodu QR.",
      "Czy mogę zrobić screenshot mojego kodu QR, aby go udostępnić?",
      "Nie! Jeśli jedna osoba zeskanuje kod, dopasowanie następuje automatycznie.",
      "Czy obie osoby muszą zeskanować kod QR?",
      "Z funkcją Szybkiego dopasowania możesz automatycznie zobaczyć, co Ty i Twoje nowe dopasowanie macie wspólnego. Dzięki temu od początku wiesz, czy do siebie pasujecie.",
      "DOWIEDZCIE SIĘ, CO WAS ŁĄCZY – SZYBCIEJ",
      "Udało Ci się kogoś poznać podczas eventu na żywo Bumble? Kontynuujcie znajomość w apce.",
      "ZAMIEŃ MIŁE PIERWSZE SPOTKANIE W {{qr_code}} SZYBKIE DOPASOWANIE",
      "Szybkie dopasowanie ułatwia przejście od pierwszego spotkania do kontynuacji rozmowy – bez potrzeby wymiany numerów czy profili społecznościowych. Wystarczy, że ten ktoś zeskanuje Twój kod QR, aby dopasować się w Bumble.",
      "Wypróbuj Szybkie dopasowanie",
      "UDAŁO CI SIĘ KOGOŚ POZNAĆ PODCZAS EVENTU NA ŻYWO Bumble?",
      "Zamiast wymieniać profile społecznościowe czy numery telefonów, Szybkie dopasowanie pozwala Ci sprawdzić czyjś vibe najpierw w aplikacji. Dzięki temu możecie lepiej się poznać na swoich warunkach i przy wsparciu funkcji bezpieczeństwa Bumble.",
      "BEZPIECZNIEJSZE ZAWIERANIE ZNAJOMOŚCI",
      "Po spotkaniu na żywo podczas eventu Bumble idźcie za ciosem, kontynuując rozmowę w aplikacji i poznając się dzięki wspólnym zainteresowaniom — czy to zamiłowania do idealnie zaparzanej kawy, czy do sadzenia rzadkich okazów sukulentów.",
      "KONTYNUUJCIE ROZMOWĘ",
      "Wypróbuj Opening Moves w Bumble",
      "Opening Moves to nieco inny sposób na czatowanie z ludźmi, którzy Ci się podobają. Kiedy już wybierzesz i wyślesz wiadomość, możesz się zrelaksować, czekać na reakcje zwrotne i odpowiadać na te, które przykują Twoją uwagę.",
      "Jak używać Opening Moves Bumble?",
      "W przypadku połączeń osób niebinarnych i tej samej płci, każda ze stron może zarówno ustawić sobie Opening Move, jak i odpowiedzieć na Opening Move drugiej osoby.",
      "Czy mogę używać Opening Moves Bumble, aby randkować z osobami tej samej płci?",
      "Kiedy ktoś odpowie na Twój Opening Move, profil tej osoby pojawi się w Twoich czatach. Kobiety mają 24 godziny na odpowiedź zanim dopasowanie wygaśnie. Z tego miejsca możesz albo zareagować na otrzymaną odpowiedź, albo zacząć nową rozmowę.",
      "Jak mogę zobaczyć, czy ktoś odpowiedział na moje Opening Moves?",
      "Opening Moves przychodzą z pomocą, gdy chcesz zrobić pierwszy krok. Pomagają wrzucić na luz oraz łatwiej i szybciej zacząć ciekawe rozmowy.",
      "Opening Moves w Bumble",
      "Już odpowiadamy! Opening Moves to nowy, łatwiejszy sposób na rozpoczęcie rozmowy. Możesz wybrać sugerowany Opening Move, jak na przykład: „Masz szansę puścić swój ulubiony utwór na imprezie. Jaka by to była piosenka?”. Możesz też napisać własne pytanie. Jeśli je ustawisz, Twoje dopasowania będą mogły na nie odpowiadać, kiedy tylko pojawisz się na ich liście dopasowań.",
      "Co to jest Opening Move?",
      "Zdjąć z siebie presję",
      "Dzielić się z innymi tym, co sprawia, że jesteś sobą",
      "Prowadzić ciekawsze rozmowy",
      "Opening Moves mogą pomóc Ci…",
      "Możesz ustawić Opening Move, który zostanie wysłany po dopasowaniu z innymi randkowiczami. Przejdź do Opening Moves w swoim profilu, wybierz jedno z wcześniej przygotowanych pytań lub wykaż się kreatywnością i napisz coś swojego. Możesz zmieniać swoje Opening Moves, kiedy tylko chcesz.",
      "Wypróbuj Opening Moves",
      "Jak używać Opening Moves Bumble?",
      "<a href={{see_more_url}}>Zobacz więcej</a>",
      "Chcesz zrobić sobie przerwę? Wypróbuj Tryb uśpienia",
      "Tak, możesz zajrzeć do swoich ustawień lub kolejki i wyłączyć Uśpienie w dowolnym momencie.",
      "Czy mogę wcześniej wyłączyć Tryb uśpienia?",
      "Przejdź do ustawień, kliknij Uśpienie, a następnie wybierz ile czasu chcesz być poza zasięgiem. Wróć, gdy znowu poczujesz na to ochotę.",
      "Jak mogę korzystać z Trybu uśpienia na swoim profilu?",
      "Nie — w Trybie uśpienia wszelka aktywność dotycząca przeglądania profili czy rozmów jest wstrzymana. Jeśli będziesz chciał(a) zobaczyć nowe osoby, wyświetli Ci się komunikat informujący o tym, jak długo będzie jeszcze trwać Tryb uśpienia oraz możliwość wyłączenia go.",
      "Czy mogę wciąż przeglądać profile i rozmawiać w Trybie uśpienia?",
      "Osoby, które już otrzymały Twoje wiadomości, zobaczą Twój status „Z dala od komputera” (jeśli taki ustawisz). W przeciwnym razie będą jak dotychczas widzieć Twoje wcześniejsze wiadomości w swojej skrzynce odbiorczej.",
      "Co będą widzieć moje aktualne dopasowania, gdy będę w Trybie uśpienia?",
      "Masz pełną kontrolę nad swoją aktywnością w Bumble i możesz odejść z platformy, kiedy tylko chcesz.",
      "Możesz na bieżąco informować swoje aktualne kontakty, korzystając z aktualizacji swojego statusu, aby nikt nie pomyślał, że kogoś <a href={{ghosted_article_url}}>ghostujesz</a>. Ponadto wszystko jest zautomatyzowane, więc nie musisz wysyłać żadnych wiadomości.",
      "Zrób sobie przerwę od aktywnego randkowania bez utraty kontaktów lub czatów.",
      "Randkuj (lub odłącz się) na swoich zasadach",
      "Informuj swoje kontakty na bieżąco",
      "Przerwij swoją aktywność, ale nie zrywaj kontaktów",
      "Dlaczego tak lubimy Tryb uśpienia",
      "Otwórz aplikację Bumble, przejdź do zakładki profilu i kliknij koło zębate w górnym prawym rogu. W Ustawieniach kliknij Uśpienie i wybierz, ile czasu ma Cię nie być. Możesz ukryć swój profil na 24 lub 72 godziny, tydzień lub nieograniczony czas.{{__EOL__}}{{__EOL__}}Pamiętaj, że dopasowania bez aktywnych czatów wygasną w Trybie uśpienia. Jeśli zdecydujesz się ustawić status, osoby, z którymi aktualnie rozmawiasz, będą mogły zobaczyć, że jesteś poza zasięgiem.",
      "Jak działa Tryb uśpienia w Bumble",
      "Kiedy życie nabiera zawrotnego tempa, z pomocą przychodzi Tryb uśpienia. Wstrzymaj aktywność swojego konta Bumble i randkuj po swojemu.",
      "Tryb uśpienia w Bumble",
      "Tryb uśpienia to prosty sposób na zatrzymanie swojego konta, gdy chcesz zrobić sobie przerwę od randkowania. Możesz nadal rozmawiać ze swoimi istniejącymi kontaktami, tylko Twoje konto zostanie ukryte przed innymi randkującymi, aż będziesz mieć znowu ochotę wznowić poszukiwania. Aktywowanie Trybu uśpienia nie powoduje utraty informacji o profilu ani usunięcia istniejących połączeń.",
      "Ukryj swój profil Bumble",
      "Kiedy jesteś w Trybie uśpienia, możesz zdecydować, czy chcesz poinformować swoje aktualne kontakty, że masz przerwę, ustawiając aktualny status. Wybierz spośród „W podróży”, „Fokus na pracę”, „Cyfrowy detoks” lub „Czas dla siebie”. Każdy znajdzie tu coś dla siebie.",
      "Dodaj status „Z dala od komputera”",
      "Aktywowanie Trybu uśpienia wstrzymuje aktywność Twojego konta Bumble (lub {{page_partner_name_bff}}). Twoje dopasowania nie będą wiedzieć, że jesteś w Trybie uśpienia, chyba że im powiesz. Jeśli postanowisz wyłączyć Tryb uśpienia przed upływem wybranego wcześniej czasu, przejdź do Ustawień i kliknij „wyłącz Tryb uśpienia”. To bardzo proste.",
      "Wstrzymaj swoje czaty",
      "Wypróbuj SuperSwipe w Bumble",
      "Ile chcesz! Użytkownicy {{service_level_lexeme_name}} otrzymują {{num}} [[superswipe:num]] tygodniowo, ale możesz w każdej chwili zwiększyć swój stan konta. Osoby korzystające z bezpłatnego konta mogą kupować pakiety SuperSwipe.",
      "Ile SuperSwipe mogę wysłać w Bumble?",
      "Kiedy ktoś użyje SuperSwipe na Twoim profilu, otrzymasz natychmiastowe powiadomienie. Nad imieniem tej osoby na jej profilu zobaczysz również etykietę informującą, że jej się podobasz.",
      "Skąd będę wiedzieć, że ktoś dał mi SuperSwipe?",
      "Myślisz o kimś poważnie? Mamy dla Ciebie świetny sposób na przełamanie lodów. Wystarczy wysłać SuperSwipe, aby poinformować kogoś o Twoim zainteresowaniu i szybciej przejść do ciekawych rozmów.",
      "SuperSwipe w Bumble",
      "Wyróżnianie się w tłumie nie zawsze jest łatwe, ale SuperSwipe może Ci pomóc zrobić świetne pierwsze wrażenie.{{__EOL__}}{{__EOL__}}Kiedy użyjesz SuperSwipe, osoba, która Ci się podoba otrzyma powiadomienie. Następnie Twój profil znajdzie się na początku kolejki tej osoby, a na Twoim profilu pojawi się etykieta. Jeśli ta osoba też Cię polubi, możecie zacząć rozmowę.",
      "Co to jest SuperSwipe?",
      "Nabrać odwagi i wyróżniać się w tłumie",
      "Zacząć więcej rozmów",
      "Znaleźć więcej kontaktów",
      "SuperSwipe może pomóc Ci…",
      "Jeśli masz <a href={{bumble_boost_url}}>Bumble{{service_level_lexeme_name}}</a> lub Bumble{{service_level_lexeme_name_one}}, otrzymujesz tygodniowo {{num}} [[superswipe:num]] za darmo. Ponadto wszyscy członkowie mogą kupować pakiety maksymalnie {{num_two}} [[superswipe:num_two]] w zakładce profilu. Jeśli masz {{service_level_lexeme_name_three}}, otrzymujesz {{num_three}} [[superswipe:num_three]] tygodniowo.{{__EOL__}}{{__EOL__}}Używanie SuperSwipe jest proste – kiedy zobaczysz kogoś, kto Ci się podoba, kliknij ikonę żółtej gwiazdki na dole profilu tej osoby.",
      "Jak używać SuperSwipe",
      "Apple i logo Apple są znakami towarowymi Apple Inc. Google Play to znak towarowy Google LLC.",
      "Randkuj poza domem dzięki Trybowi podróży Bumble",
      "Z Trybu podróży mogą korzystać członkowie <a href={{bumble_premium_url}}>{{service_level_lexeme_name}}</a>. Włącz ten tryb, a na Twojej liście będą wyświetlać się nowe twarze w wybranej miejscowości.",
      "Jak korzystać z Trybu podróży?",
      "Mamy kilka ograniczeń dotyczących bezpieczeństwa, które mogą się zmieniać z upływem czasu, ale Tryb podróży jest w zasadzie dostępny w większości miejsc docelowych.",
      "Czy mogę korzystać z Trybu podróży wszędzie?",
      "Tryb podróży wyświetli Twoją lokalizację jako centrum wybranej przez Ciebie miejscowości, dzięki czemu kontakty w okolicy będą mogły Cię znaleźć. Na Twoim profilu widoczna będzie informacja, że jesteś w Trybie podróży, aby inni wiedzieli, że właśnie odwiedzasz to miejsce lub planujesz to zrobić.",
      "Pakujesz walizki na wakacje lub udajesz się do nowego miasta w celach służbowych? Włącz Tryb podróży Bumble i zabierz randkowanie ze sobą.",
      "Tryb podróży w Bumble",
      "Tryb podróży to podstawowe narzędzie do zawierania znajomości podczas wyjazdów. Ustaw swoje położenie w dowolnym miejscu na świecie, a Twój profil wyświetli miejscowość, którą wybierzesz. Oznacza to, że możesz nadal randkować wszędzie tam, gdzie poprowadzą Cię Twoje przygody.",
      "Co to jest Tryb podróży?",
      "Nikt nie zna miejsca lepiej niż miejscowi. Tryb podróży pomoże Ci nawiązać kontakt z ludźmi, którzy uczynią Twoją podróż wyjątkową.",
      "Ustaw swoją lokalizację w wybranej miejscowości co najmniej siedem dni przed wyjazdem, aby móc planować randki i poznawać potencjalne dopasowania z wyprzedzeniem.",
      "Podróże to nie tylko wyprawy na plażę — możesz być w domu na święta, na wyjeździe służbowym lub wyjechać pozwiedzać nowe miasto. Znajdź romans wszędzie tam, gdzie zawitasz.",
      "Znajdź najlepsze miejsca na lokalne randki",
      "Przeżyj przygodę",
      "Nawiązuj trwałe znajomości",
      "Powody, dla których pokochasz Tryb podróży",
      "Tryb podróży dostępny jest dla członków <a href={{bumble_premium_url}}>{{service_level_lexeme_name}} i {{service_level_lexeme_name_two}}</a> Bumble. Kliknij ikonę Ustawień w prawym górnym rogu swojego profilu i przewiń, aż znajdziesz Tryb podróży. Wybierz nowe miejsce, aby rozpocząć przeglądanie osób w tym regionie. Twój profil poinformuje potencjalne randki, że znajdujesz się w Trybie podróży.",
      "Jak działa Tryb podróży?",
      "Wypróbuj teraz",
      "Wypróbuj Bumble w przeglądarce",
      "Oczywiście. Aplikacja Bumble na stronie internetowej działa na komputerach osobistych (PC), Mac i tabletach we wszystkich głównych przeglądarkach.",
      "Czy mogę używać Bumble na moim komputerze PC lub Mac?",
      "Tak. Możesz wysyłać wiadomości, a nawet prowadzić rozmowy audio i video w aplikacji Bumble w przeglądarce internetowej.",
      "Czy mogę rozmawiać z dopasowaniami w aplikacji w przeglądarce internetowej?",
      "Aby się zalogować, wybierz „Zaloguj się” lub kliknij <a href={{sign_in_url}}>tutaj</a>. Możesz się zalogować przy użyciu swojego ID Apple, Facebooka lub numeru telefonu.",
      "Jak się zalogować do Bumble na stronie internetowej?",
      "Potrzebujesz przerwy od telefonu? Wypróbuj aplikację Bumble w przeglądarce internetowej – to samo doświadczenie Bumble, ale na większym ekranie.",
      "Skorzystaj z Bumble w przeglądarce",
      "Logowanie do Bumble na stronie internetowej jest proste. Wejdź na naszą stronę, kliknij „Zaloguj się” i wybierz swój typowy sposób logowania przy użyciu swojego Apple ID, danych logowania Facebooka lub numeru telefonu. Voila! Logowanie gotowe, a Twoje czaty i połączenia są dokładnie tam, gdzie były dotychczas.",
      "Zaloguj",
      "Zaloguj się przez swoją przeglądarkę",
      "Edytuj lub zmień swój profil i zastosuj Zaawansowane filtry.",
      "Potwierdź, że to Ty, aby zwiększyć szanse na znalezienie idealnego dopasowania.",
      "Podziel się nowymi fajnymi ujęciami lub usuń te nieco starsze.",
      "Zaktualizuj swój profil",
      "Pokaż innym, że to naprawdę Ty",
      "Dodaj lub usuń zdjęcia",
      "Co możesz robić w Bumble w przeglądarce",
      "Swoje czaty i listę połączeń znajdziesz po lewej stronie, a potencjalne nowe dopasowania po prawej.{{__EOL__}}{{__EOL__}}Użyj klawiszy strzałek w lewo i w prawo lub kliknij symbole po prawej stronie ekranu, aby przeglądać profile i polubić osoby, tak jak to robisz na swoim telefonie.",
      "Jak korzystać z Bumble w przeglądarce?",
      "Tutaj uprzejmość jest sexy, bycie sobą jest idealne, a kobiety mają pierwszeństwo.",
      "Nasze motto? – Zrób pierwszy krok",
      "Zdrowe relacje to podstawa pozytywnego i produktywnego życia. Bumble zostało stworzone po to, aby wspierać Cię podczas zawierania znajomości. Niezależnie od tego, czy szukasz randek, znajomych, czy chcesz poszerzać kręgi zawodowe.",
      "Dlaczego Bumble ma znaczenie",
      "Na pierwszym miejscu stawiamy życzliwość, równość, pewność siebie i szacunek na wszystkich etapach relacji. Jesteśmy nie tylko otwarci na wszystkie orientacje, ale wręcz je celebrujemy – niezależnie od tego, czy jesteś tu po to, aby znaleźć miłość, czy po prostu dobrze się bawić.",
      "Co ceni Bumble",
      "W Bumble to kobiety nadają ton – albo Robiąc pierwszy krok, albo ustawiając Opening Moves, na które mogą odpowiedzieć dopasowania. Zmienia to staromodną dynamikę i już od samego początku wspiera zasady równości.",
      "Jak działa Bumble",
      "O nas",
      "Pobierz teraz",
      "Lepiej w aplikacji.",
      "Funkcje stworzone po to, by sprawić, aby Twoje życie randkowe było po prostu przyjemne",
      "12 lutego 2024",
      "Jak zwiększyć intymność emocjonalną w swom związku",
      "25 stycznia 2024",
      "Jak używać Bumble, jeśli się nie korzystało wcześniej z żadnej apki randkowej",
      "5 lutego 2024",
      "Deception Detector™️",
      "Wszystkie randkowe newsy, które nadają się do druku",
      "Jeśli rozmiar ma znaczenie, zajrzyj na naszą stronę internetową. Jest dokładnie taka sama, jak nasza apka, ale większa.",
      "Apka internetowa",
      "Zrób sobie przerwę od randek z <b>Trybem uśpienia</b>. Kiedy znowu poczujesz gotowość do randek, wiesz, gdzie nas znaleźć.",
      "Zrób sobie przerwę",
      "Skorzystaj z <b>rozmów video</b> i <b>czatów głosowych</b>, aby być możliwie jak najbliżej doświadczenia na żywo bez wychodzenia z domu.",
      "Powiedz to głośno",
      "Zobacz więcej",
      "Wybierz <b>Opening Moves</b>, aby to Twoje dopasowanie zaczęło rozmowę zamiast Ciebie.",
      "Rób mniej, randkuj więcej",
      "Znajdź swoją przekąskę w sklepie bezcłowym i dopasowania w dowolnym miejscu na świecie z <b>Trybem podróży</b>.",
      "Weź nas na wynos",
      "Odpowiada Ci ten profil? Użyj <b>SuperSwipe</b> i daj tej osobie znać.",
      "Zwróć na siebie uwagę",
      "Nie szczędź kilku <b>Compliments</b>, aby wyróżnić się z tłumu.",
      "Niech się zarumieni",
      "Użyj <b>Trybu Incognito</b>, aby ukryć swój profil przed szefową, krewnymi lub byłymi.",
      "Działaj w ukryciu",
      "Randkuj tak, jak chcesz",
      "Parę miesięcy później poznała tam też jedną ze swoich druhen",
      "Hanna poznała swojego obecnego męża w Bumble.",
      "Zanim zbudowali swój wspólny mały dom Karolina i Wojtek poznali się w Bumble, do którego przyciągnęły Wojtka zamieszczane przez Karo zdjęcia z podróży.",
      "Karolina i Wojtek",
      "Krystian i Natalia stacjonowali razem za granicą—ale poznali się w Bumble",
      "Krystian i Natalia",
      "Jeśli im się udało, uda się i Tobie",
      "Wykonaj następny ruch",
      "Zobacz, co u nas nowego",
      "Zasługujesz na coś lepszego, dlatego stworzyliśmy dla Ciebie świetne sposoby na więcej randek i mniej stresu.",
      "Postaw poprzeczkę tam, gdzie powinna być",
      "O Bumble",
      "Jesteśmy jedyną apką, która na pierwszym miejscu stawia kobiety. Bo kiedy świat sprzyja kobietom, to sprzyja wszystkim.",
      "Make the first move™",
      "Znajdź kogoś, z kim naprawdę chcesz się umówić, a potem Zrób pierwszy krok. Zawieraj szczere znajomości, łącz się na podstawie wspólnych pasji, a przede wszystkim dobrze się baw.",
      "Poznaj kogoś z Bumble Date",
      "Zawieraj znajomości korzystne zawodowo. Połącz się z innymi specjalistami, aby znaleźć swoją następną pracę, mentora lub mentorkę, a może nawet rozpocząć zupełnie nową karierę.",
      "Rozwijaj się zawodowo z Bumble Bizz",
      "Poznaj kogoś podobnego do Ciebie. Albo zupełnie innego. Znajdź nowych znajomych i nowe wspólne przedsięwzięcia — niezależnie od tego, czy jesteś w domu, czy w podróży.",
      "Znajdź nowych znajomych w Bumble For Friends",
      "Nie jesteśmy tylko od randek",
      "Bumble to aplikacja randkowa, w której to kobiety robią pierwszy krok. To apka, która zmieniła sposób randkowania, tworzy przestrzeń na wartościowe relacje i sieć kontaktów. Pobierz teraz.",
      "Bumble na {{social_network}}",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "Premium+",
      "Przykro nam, ale nie nie możemy odnaleźć strony, której szukasz.",
      "Kontakt",
      "Zaloguj się",
      "Ojej, coś poszło nie tak",
      "(opens in new window)",
      "FAQ",
      "Cookie Policy",
      "Premium",
      "Bumble - Blog",
      "Praca",
      "Czat",
      "Miasta",
      "Zarządzaj cookies",
      "Prawa autorskie © 2006 – obecnie. Bumble. Wszelkie prawa zastrzeżone.",
      "Raport z Randkowania z pewnością siebie",
      "Apple i logo Apple są znakami towarowymi firmy Apple Inc.{{__EOL__}}Google Play jest znakiem towarowym firmy Google LLC.",
      "Pobierz",
      "Facebook",
      "Obserwuj nas",
      "Centrum pomocy",
      "Instagram",
      "Język",
      "LinkedIn",
      "Bumble to część Bumble Inc.",
      "Polityka prywatności",
      "Safety Centre",
      "Mapa witryny",
      "Oświadczenie w spr. Ustawy o Współczesnym Niewolnictwie",
      "Regulamin",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "Compliments": {
            "few": [
                  "komplementy",
                  "komplementów",
                  "komplementom",
                  "komplementy",
                  "komplementach",
                  "komplementami",
                  "komplementy"
            ],
            "many": [
                  "komplementów",
                  "komplementów",
                  "komplementom",
                  "komplementów",
                  "komplementach",
                  "komplementami",
                  "komplementów"
            ],
            "plural": [
                  "komplementu",
                  "komplementu",
                  "komplementu",
                  "komplementu",
                  "komplementu",
                  "komplementu",
                  "komplementu"
            ],
            "singular": [
                  "komplement",
                  "komplementu",
                  "komplementowi",
                  "komplement",
                  "komplemencie",
                  "komplementem",
                  "komplement"
            ]
      },
      "day": {
            "few": [
                  "dni",
                  "dni",
                  "dniom",
                  "dni",
                  "dniach",
                  "dniami",
                  "dni"
            ],
            "many": [
                  "dni",
                  "dni",
                  "dniom",
                  "dni",
                  "dniach",
                  "dniami",
                  "dni"
            ],
            "plural": [
                  "dnia",
                  "dnia",
                  "dnia",
                  "dnia",
                  "dnia",
                  "dnia",
                  "dnia"
            ],
            "singular": [
                  "dzień",
                  "dnia",
                  "dniowi",
                  "dzień",
                  "dniu",
                  "dniem",
                  "dniu"
            ]
      },
      "hour": {
            "few": [
                  "godziny",
                  "godzin",
                  "godzinom",
                  "godziny",
                  "godzinach",
                  "godzinami",
                  "godziny"
            ],
            "many": [
                  "godzin",
                  "godzin",
                  "godzinom",
                  "godzin",
                  "godzinach",
                  "godzinami",
                  "godzin"
            ],
            "plural": [
                  "godzin",
                  "godzin",
                  "godzin",
                  "godzin",
                  "godzin",
                  "godzin",
                  "godzin"
            ],
            "singular": [
                  "godzina",
                  "godziny",
                  "godzinie",
                  "godzinę",
                  "godzinie",
                  "godziną",
                  "godzina"
            ]
      },
      "minute": {
            "few": [
                  "minuty",
                  "minut",
                  "minutom",
                  "minuty",
                  "minutach",
                  "minutami",
                  "minuty"
            ],
            "many": [
                  "minut",
                  "minut",
                  "minutom",
                  "minut",
                  "minutach",
                  "minutami",
                  "minut"
            ],
            "plural": [
                  "minuty",
                  "minuty",
                  "minuty",
                  "minuty",
                  "minuty",
                  "minuty",
                  "minuty"
            ],
            "singular": [
                  "minuta",
                  "minuty",
                  "minucie",
                  "minutę",
                  "minucie",
                  "minutą",
                  "minuto"
            ]
      },
      "month": {
            "few": [
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies."
            ],
            "many": [
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies."
            ],
            "plural": [
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies."
            ],
            "singular": [
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies."
            ]
      },
      "second": {
            "few": [
                  "sekundy",
                  "sekund",
                  "sekundom",
                  "sekundy",
                  "sekundach",
                  "sekundami",
                  "sekundy"
            ],
            "many": [
                  "sekund",
                  "sekund",
                  "sekundom",
                  "sekund",
                  "sekundach",
                  "sekundami",
                  "sekund"
            ],
            "plural": [
                  "sekund",
                  "sekund",
                  "sekund",
                  "sekund",
                  "sekund",
                  "sekund",
                  "sekund"
            ],
            "singular": [
                  "sekunda",
                  "sekundy",
                  "sekundzie",
                  "sekundę",
                  "sekundzie",
                  "sekundą",
                  "sekundo"
            ]
      },
      "superswipe": {
            "few": [
                  "SuperSwipe",
                  "SuperSwipe",
                  "SuperSwipe",
                  "SuperSwipe",
                  "SuperSwipe",
                  "SuperSwipe",
                  "SuperSwipe"
            ],
            "many": [
                  "SuperSwipe",
                  "SuperSwipe",
                  "SuperSwipe",
                  "SuperSwipe",
                  "SuperSwipe",
                  "SuperSwipe",
                  "SuperSwipe"
            ],
            "plural": [
                  "SuperSwipe",
                  "SuperSwipe",
                  "SuperSwipe",
                  "SuperSwipe",
                  "SuperSwipe",
                  "SuperSwipe",
                  "SuperSwipe"
            ],
            "singular": [
                  "SuperSwipe",
                  "SuperSwipe",
                  "SuperSwipe",
                  "SuperSwipe",
                  "SuperSwipe",
                  "SuperSwipe",
                  "SuperSwipe"
            ]
      },
      "year": {
            "few": [
                  "lata",
                  "lat",
                  "latom",
                  "lata",
                  "latach",
                  "latami",
                  "lata"
            ],
            "many": [
                  "lat",
                  "lat",
                  "latom",
                  "lat",
                  "latach",
                  "latami",
                  "lat"
            ],
            "plural": [
                  "lat",
                  "lat",
                  "lat",
                  "lat",
                  "lat",
                  "lat",
                  "lat"
            ],
            "singular": [
                  "rok",
                  "roku",
                  "rokowi",
                  "rok",
                  "roku",
                  "rokiem",
                  "rok"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
